import { Avatar, Col, ConfigProvider, Divider, Modal, Row, Upload } from 'antd';
import { PrimaryButton, Text } from '../../../components';
import { useMutation, useReactiveVar } from '@apollo/client';
import TextArea from 'antd/es/input/TextArea';
import { useModalWidth } from '../../../hooks';
import { UploadProps } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import {
  CREATE_EVENT_STORY,
  CREATE_GROUP_STORY,
  CREATE_STORY,
  LoggedInUser,
  Mutation,
  MutationCreateEventStoryArgs,
  MutationCreateGroupStoryArgs,
  MutationCreateStoryArgs,
  MutationUpdateStoryArgs,
  MutationUploadFileArgs,
  Story,
  StoryType,
  UPDATE_STORY,
  UPLOAD_FILE,
  uploadClient,
} from '../../../graphql';
import {
  commonUploadProps,
  getInitials,
  getVideoUrl,
  previewImage,
} from '../../../utils';

type Props = {
  story?: Story;
  type: StoryType;
  groupId?: string;
  eventId?: string;
  refetch: () => void;
  handleClose: () => void;
};
type MediaType = 'image' | 'video';

const StoryFormModal = ({
  story,
  type,
  groupId,
  eventId,
  refetch,
  handleClose,
}: Props) => {
  const user = useReactiveVar(LoggedInUser);
  const { width } = useModalWidth();
  const [content, setContent] = useState<string>('');
  const [filePreview, setFilePreview] = useState<any>();
  const [file, setFile] = useState<UploadFile>();
  const [mediaType, setMediaType] = useState<MediaType>('image');
  const [createStory, { loading: creating }] = useMutation<
    Mutation,
    MutationCreateStoryArgs
  >(CREATE_STORY);
  const [createGroupStory, { loading: creatingGroupStory }] = useMutation<
    Mutation,
    MutationCreateGroupStoryArgs
  >(CREATE_GROUP_STORY);
  const [createEventStory, { loading: creatingEventStory }] = useMutation<
    Mutation,
    MutationCreateEventStoryArgs
  >(CREATE_EVENT_STORY);
  const [updateStory, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateStoryArgs
  >(UPDATE_STORY);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });

  useEffect(() => {
    if (!!story) {
      if (!!story.image) {
        setMediaType('image');
        setFilePreview(story.image);
      } else {
        setMediaType('video');
        setFilePreview(story.video);
      }
      setContent(story.content);
    }
  }, [story]);

  const uploadProps: UploadProps = {
    ...commonUploadProps,
    accept: 'image/*,video/*',
    // @ts-ignore
    action: (file) => {
      if (file.type.includes('video')) setMediaType('video');
      else setMediaType('image');
      setFile(file);
      previewImage(file, setFilePreview);
    },
  };

  const handleSave = (values?: { image?: string; video?: string }) => {
    if (!story) {
      if (type === StoryType.Feed) {
        createStory({
          variables: {
            story: {
              ...values,
              content,
              type,
            },
          },
        }).then(() => {
          refetch();
          handleClose();
        });
      } else if (type === StoryType.Group) {
        createGroupStory({
          variables: {
            groupId: groupId as string,
            story: {
              ...values,
              content,
              type,
            },
          },
        }).then(() => {
          refetch();
          handleClose();
        });
      } else {
        createEventStory({
          variables: {
            eventId: eventId as string,
            story: {
              ...values,
              content,
              type,
            },
          },
        }).then(() => {
          refetch();
          handleClose();
        });
      }
    } else {
      updateStory({
        variables: {
          storyId: story.id,
          update: {
            ...values,
            content,
          },
        },
      }).then(() => {
        refetch();
        handleClose();
      });
    }
  };

  const onFinish = () => {
    if (!!file) {
      uploadFile({ variables: { file } }).then((res) => {
        const fileString = res.data?.uploadFile;
        handleSave({ [mediaType]: fileString });
      });
    } else {
      handleSave();
    }
  };

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      width={width}
      onCancel={handleClose}
    >
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Row align={'middle'} gutter={10}>
            <Col>
              <Avatar size={40} src={user?.profile?.picture}>
                {getInitials(user?.firstName, user?.lastName)}
              </Avatar>
            </Col>
            <Col>
              <Text fontWeight={600} color={'black10'}>
                {`${user?.firstName} ${user?.lastName}`}
              </Text>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  colorBgContainer: 'white',
                  colorBorder: 'white',
                  padding: 0,
                  activeBorderColor: 'white',
                  hoverBorderColor: 'white',
                  activeBg: 'white',
                  hoverBg: 'white',
                },
              },
            }}
          >
            <TextArea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              style={{ padding: 0, borderRadius: 0 }}
              placeholder={"What's your story?"}
              autoSize={{ minRows: 2, maxRows: 10 }}
            />
          </ConfigProvider>
        </Col>
        <Divider style={{ margin: '0' }} />
        <Col span={24} className={'clickable'}>
          <Upload {...uploadProps}>
            <Row
              className="upload-photovideo"
              align={'middle'}
              gutter={10}
              style={{
                backgroundColor: '#F3F5F680',
                borderRadius: '12px',
                padding: '16px',
                flexDirection: 'column',
                border: '1px #B1DBE3 dashed',
              }}
            >
              <Col>
                <img
                  src={require('../../../assets/images/addMediaIcon.png')}
                  alt={''}
                  style={{ height: 24 }}
                />
              </Col>
              <Col>
                <Text color={'black5'}>
                  {!file ? 'Add' : 'Change'} Photo/Video
                </Text>
                {/* <Text fontSize={12}>
                <br />Max. file size image: 2 MB <br />
                  Max. file size video: 5 MB
                </Text> */}
              </Col>
              <Text fontSize={12} style={{ marginTop: '10px', opacity: '0.7' }}>
                Max. file upload size: image: 2 MB, video: 5 MB
              </Text>
            </Row>
            {filePreview &&
              (mediaType === 'image' ? (
                <img
                  id="blah"
                  src={filePreview}
                  alt=""
                  style={{
                    marginTop: 10,
                    width: '100%',
                    aspectRatio: 5 / 3,
                    objectFit: 'cover',
                    borderRadius: 16,
                  }}
                />
              ) : (
                <video
                  src={getVideoUrl(filePreview)}
                  preload={'metadata'}
                  style={{
                    marginTop: 10,
                    width: '100%',
                    aspectRatio: 5 / 3,
                    objectFit: 'cover',
                    borderRadius: 16,
                  }}
                  controls
                />
              ))}
          </Upload>
        </Col>
        <Col span={24} style={{ marginTop: 6 }}>
          <PrimaryButton
            onClick={onFinish}
            loading={
              uploading ||
              creating ||
              creatingGroupStory ||
              creatingEventStory ||
              updating
            }
            block
            // disabled={(!file && !filePreview) || !content}
            disabled={!content}
          >
            Publish story
          </PrimaryButton>
        </Col>
      </Row>
    </Modal>
  );
};

export default StoryFormModal;
