import { Avatar, Card, Col, Divider, Row } from 'antd';
import { Text } from '../../../components';
import { useMutation } from '@apollo/client';
import {
  LIKE_STORY,
  LoggedInUser,
  Mutation,
  MutationLikeStoryArgs,
  Story,
} from '../../../graphql';
import { formatDistanceToNowStrict } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  FeedItemOptions,
  ShareStoryModal,
  StoryComments,
  StoryLikesModal,
} from '.';
import { useNavigate } from 'react-router-dom';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { getVideoUrl } from '../../../utils';

const FeedItem = ({
  story,
  refetch,
}: {
  story: Story;
  refetch: () => void;
}) => {
  const { md } = useBreakpoint();
  const navigate = useNavigate();
  const user = LoggedInUser();
  const [likeStory] = useMutation<Mutation, MutationLikeStoryArgs>(LIKE_STORY);
  const [share, setShare] = useState<boolean>(false);
  const [showLikes, setShowLikes] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(
    story.likes?.some((like) => like.user.id === user?.id),
  );

  useEffect(() => {
    setLiked(story.likes?.some((like) => like.user.id === user?.id));
  }, [user, story]);

  const handleLike = () => {
    if (!!user?.id) {
      const prevLiked = liked;
      setLiked(!prevLiked);

      likeStory({
        variables: {
          storyId: story.id,
          like: !liked,
        },
      })
        .then(() => {
          refetch();
        })
        .catch(() => {
          setLiked(prevLiked);
        });
    }
  };

  const navigateToUser = () => navigate(`/user/${story.user.id}`);
  const navigateToStory = () => navigate(`/stories/${story.id}`);

  return (
    <Card
      className={'card-no-padding feed-item'}
      style={{
        width: '100%',
        padding: md ? '24px 24px 10px 24px' : '16px 16px 4px 16px',
      }}
    >
      <Row align={'middle'} justify={'space-between'}>
        <Col className={'clickable user-info'} onClick={navigateToUser}>
          <Row align={'middle'} gutter={10} style={{ gap: '8px', margin: 0 }}>
            <Col style={{ padding: 0 }}>
              <Avatar size={40} src={story?.user?.profile?.picture}>
                {story.user.firstName[0] + story.user.lastName[0]}
              </Avatar>
            </Col>
            <Col style={{ padding: 0 }}>
              <Row align={'middle'} gutter={6} justify={'space-between'}>
                <Col>
                  <Text
                    className="user-name"
                    fontWeight={600}
                    color={'black10'}
                  >
                    {`${story.user.firstName} ${story.user.lastName}`}
                  </Text>
                </Col>
                <Col>
                  <img
                    src={require('../../../assets/images/ellipse.png')}
                    alt={''}
                  />
                </Col>
                <Col>
                  <Text color={'black5'} className="post-created-at">
                    {formatDistanceToNowStrict(new Date(story.createdAt), {
                      addSuffix: true,
                    })}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {story.user.id === user?.id && (
          <Col>
            <FeedItemOptions story={story} refetch={refetch} />
          </Col>
        )}
      </Row>
      <Row gutter={[0, 8]} style={{ marginTop: 10 }}>
        <Col
          span={24}
          className={'NOTclickable'}
          // onClick={navigateToStory}
        >
          <Text fontSize={14} style={{ whiteSpace: 'break-spaces' }}>
            {story.content}
          </Text>
        </Col>
        <Col
          className={'NOTclickable'}
          // onClick={navigateToStory}
          span={!!story.image || !!story.video ? 24 : 0}
          style={{ textAlign: 'center' }}
        >
          {!!story.image && (
            <img
              src={story.image}
              alt={''}
              style={{
                width: '100%',
                aspectRatio: 5 / 3,
                borderRadius: 16,
                objectFit: 'cover',
              }}
            />
          )}
          {!!story.video && (
            <video
              src={getVideoUrl(story.video)}
              preload={'metadata'}
              style={{
                width: '100%',
                aspectRatio: 5 / 3,
                objectFit: 'cover',
                borderRadius: 16,
              }}
              controls
            />
          )}
        </Col>
        <Divider style={{ margin: '5px 0' }} />
        <Col span={24}>
          <Row align={'middle'} justify={'space-between'}>
            <Col>
              <Row align={'middle'} gutter={20}>
                <Col>
                  <Row align={'middle'} gutter={6}>
                    <Col
                      className={!!user?.id ? 'clickable' : ''}
                      onClick={handleLike}
                    >
                      <img
                        src={
                          liked
                            ? require('../../../assets/images/likedIcon.png')
                            : require('../../../assets/images/likeIcon.png')
                        }
                        alt={''}
                        style={{ height: 24 }}
                      />
                    </Col>
                    <Col
                      className={'clickable'}
                      onClick={() => setShowLikes(true)}
                    >
                      <Text color={'black5'}>
                        {`${story.likes?.length ?? '0'} Like${
                          story.likes?.length === 1 ? '' : 's'
                        }`}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row align={'middle'} gutter={6}>
                    <Col>
                      <img
                        src={require('../../../assets/images/commentIcon.png')}
                        alt={''}
                        style={{ height: 24 }}
                      />
                    </Col>
                    <Col>
                      <Text color={'black5'}>
                        {`${story.comments?.length ?? '0'} Comment${
                          story.comments?.length === 1 ? '' : 's'
                        }`}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className={'clickable'} onClick={() => setShare(true)}>
              <Row align={'middle'} gutter={6}>
                <Col>
                  <img
                    src={require('../../../assets/images/shareIcon.png')}
                    alt={''}
                    style={{ height: 24 }}
                  />
                </Col>
                <Col>
                  <Text color={'black5'}>Share</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: '5px 0' }} />
        <StoryComments story={story} refetch={refetch} />
      </Row>
      {showLikes && (
        <StoryLikesModal
          story={story}
          handleClose={() => setShowLikes(false)}
        />
      )}
      {share && (
        <ShareStoryModal
          storyId={story.id}
          handleClose={() => setShare(false)}
        />
      )}
    </Card>
  );
};

export default FeedItem;
