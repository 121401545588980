import React from 'react';
import { Button, ButtonProps, ConfigProvider } from 'antd';
import { colors } from '../utils';

export const PrimaryButton = ({
  height = 40,
  borderRadius = 8,
  fontSize = 16,
  ...props
}: ButtonProps & {
  fontSize?: number;
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={props.disabled ? 'primary-button-disabled' : 'primary-button'}
      style={props.fullWidth || props.block ? { width: '100%' } : {}}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: colors.primary,
              borderRadius,
              primaryColor: 'white',
              colorPrimaryHover: 'transparent',
              fontSize,
              fontFamily: 'Poppins',
              controlHeight: height,
              colorTextLightSolid: 'white',
              colorBgContainerDisabled: colors.primary5,
              colorTextDisabled: colors.black1,
              colorPrimaryActive: 'transparent',
            },
          },
        }}
      >
        <Button
          type={'primary'}
          {...props}
          style={{
            display: 'flex',
            fontWeight: 500,
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            ...(props.fullWidth && { width: '100%' }),
            ...(props.disabled && { border: 'none' }),
            ...props.style,
          }}
        >
          {props.children}
        </Button>
      </ConfigProvider>
    </div>
  );
};

export const OutlinedButton = ({
  height = 40,
  borderRadius = 8,
  fontSize = 16,
  ...props
}: ButtonProps & {
  fontSize?: number;
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={
        props.disabled ? 'outlined-button-disabled' : 'outlined-button'
      }
      style={props.fullWidth || props.block ? { width: '100%' } : {}}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: 'white',
              borderRadius,
              primaryColor: colors.black10,
              colorPrimaryHover: 'white',
              fontSize,
              fontFamily: 'Poppins',
              controlHeight: height,
              colorTextLightSolid: colors.black10,
              colorIcon: colors.primary,
              colorIconHover: colors.primary5,
              colorBgContainerDisabled: 'white',
              colorTextDisabled: colors.black5,
              colorPrimaryActive: 'white',
            },
          },
        }}
      >
        <Button
          type={'primary'}
          {...props}
          style={{
            display: 'flex',
            fontWeight: 400,
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${colors.borderColor}`,
            ...(props.fullWidth && { width: '100%' }),
            ...(props.disabled && {
              border: `1px solid ${colors.borderColor}`,
            }),
            ...props.style,
          }}
        >
          {props.children}
        </Button>
      </ConfigProvider>
    </div>
  );
};

export const PrimaryOutlinedButton = ({
  height = 40,
  borderRadius = 8,
  fontSize = 16,
  ...props
}: ButtonProps & {
  fontSize?: number;
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={
        props.disabled ? 'primary-outlined-button-disabled' : 'outlined-button'
      }
      style={props.fullWidth || props.block ? { width: '100%' } : {}}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: 'white',
              borderRadius,
              primaryColor: colors.blue6,
              colorPrimaryHover: 'white',
              fontSize,
              fontFamily: 'Poppins',
              controlHeight: height,
              colorTextLightSolid: colors.blue6,
              colorIcon: colors.primary,
              colorIconHover: colors.primary5,
              colorBgContainerDisabled: 'white',
              colorTextDisabled: colors.blue4,
              colorPrimaryActive: 'white',
            },
          },
        }}
      >
        <Button
          type={'primary'}
          {...props}
          style={{
            display: 'flex',
            fontWeight: 400,
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${colors.blue6}`,
            ...(props.fullWidth && { width: '100%' }),
            ...(props.disabled && {
              border: `1px solid ${colors.borderColor}`,
            }),
            ...props.style,
          }}
        >
          {props.children}
        </Button>
      </ConfigProvider>
    </div>
  );
};

export const SecondaryOutlinedButton = ({
  height = 40,
  borderRadius = 47,
  ...props
}: ButtonProps & {
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={
        props.disabled
          ? 'secondary-outlined-button-disabled'
          : 'secondary-outlined-button'
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: 'transparent',
              borderRadius,
              primaryColor: colors.black6,
              colorPrimaryHover: 'transparent',
              fontSize: 14,
              fontFamily: 'Poppins',
              controlHeight: height,
              colorTextLightSolid: colors.black6,
              colorIcon: colors.primary,
              colorIconHover: colors.black6,
              colorBgContainerDisabled: 'transparent',
              colorTextDisabled: colors.black5,
              colorPrimaryActive: 'transparent',
            },
          },
        }}
      >
        <Button
          type={'primary'}
          {...props}
          style={{
            display: 'flex',
            fontWeight: 500,
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${colors.black6}`,
            ...(props.fullWidth && { width: '100%' }),
            ...(props.disabled && { border: `1px solid ${colors.black5}` }),
            ...props.style,
          }}
        >
          {props.children}
        </Button>
      </ConfigProvider>
    </div>
  );
};

export const SecondaryButton = ({
  height = 40,
  borderRadius = 8,
  fontSize = 16,
  ...props
}: ButtonProps & {
  fontSize?: number;
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={
        props.disabled ? 'secondary-button-disabled' : 'secondary-button'
      }
      style={props.fullWidth || props.block ? { width: '100%' } : {}}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: colors.blue1,
              borderRadius,
              primaryColor: colors.blue6,
              colorPrimaryHover: 'transparent',
              fontSize,
              fontFamily: 'Poppins',
              controlHeight: height,
              colorTextLightSolid: 'white',
              colorBgContainerDisabled: colors.blue1,
              colorTextDisabled: colors.black8,
              colorPrimaryActive: 'transparent',
            },
          },
        }}
      >
        <Button
          type={'primary'}
          {...props}
          style={{
            display: 'flex',
            fontWeight: 400,
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            ...(props.fullWidth && { width: '100%' }),
            ...(props.disabled && { border: 'none' }),
            ...props.style,
          }}
        >
          {props.children}
        </Button>
      </ConfigProvider>
    </div>
  );
};

export const AuthButton = ({
  height = 44,
  borderRadius = 8,
  ...props
}: ButtonProps & {
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: 'transparent',
            borderRadius,
            primaryColor: 'white',
            colorPrimaryHover: 'transparent',
            fontSize: 16,
            fontFamily: 'Poppins',
            controlHeight: height,
            colorTextLightSolid: colors.primary5,
            colorIcon: colors.black4,
            colorIconHover: colors.primary5,
          },
        },
      }}
    >
      <Button
        type={'primary'}
        {...props}
        style={{
          fontWeight: 500,
          boxShadow: 'none',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${colors.black4}`,
          ...(props.fullWidth && { width: '100%' }),
          ...props.style,
        }}
      >
        {props.children}
      </Button>
    </ConfigProvider>
  );
};

export const ErrorButton = ({
  height = 40,
  borderRadius = 8,
  fontSize = 16,
  ...props
}: ButtonProps & {
  fontSize?: number;
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  return (
    <div className={props.disabled ? 'error-button-disabled' : 'error-button'}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: colors.error,
              borderRadius,
              primaryColor: 'white',
              colorPrimaryHover: 'transparent',
              fontSize,
              fontFamily: 'Poppins',
              controlHeight: height,
              colorTextLightSolid: 'white',
              colorBgContainerDisabled: `${colors.error}DD`,
              colorTextDisabled: colors.black10,
              colorPrimaryActive: 'transparent',
            },
          },
        }}
      >
        <Button
          type={'primary'}
          {...props}
          style={{
            display: 'flex',
            fontWeight: 500,
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            ...(props.fullWidth && { width: '100%' }),
            ...(props.disabled && { border: 'none' }),
            ...props.style,
          }}
        >
          {props.children}
        </Button>
      </ConfigProvider>
    </div>
  );
};

export const ProgressButton = ({
  height = 40,
  borderRadius = 8,
  fontSize = 16,
  ...props
}: ButtonProps & {
  fontSize?: number;
  height?: number;
  borderRadius?: number;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={
        props.disabled ? 'progress-button-disabled' : 'progress-button'
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: colors.orange6,
              borderRadius,
              primaryColor: 'white',
              colorPrimaryHover: 'transparent',
              fontSize,
              fontFamily: 'Poppins',
              controlHeight: height,
              colorTextLightSolid: 'white',
              colorBgContainerDisabled: `${colors.orange6}DD`,
              colorTextDisabled: colors.black5,
              colorPrimaryActive: 'transparent',
            },
          },
        }}
      >
        <Button
          type={'primary'}
          {...props}
          style={{
            display: 'flex',
            fontWeight: 500,
            boxShadow: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            ...(props.fullWidth && { width: '100%' }),
            ...(props.disabled && { border: 'none' }),
            ...props.style,
          }}
        >
          {props.children}
        </Button>
      </ConfigProvider>
    </div>
  );
};
