import React from 'react';
import { Col, Row } from 'antd';
import { colors } from '../utils';

const TabContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Row
      style={{
        backgroundColor: colors.black2,
        padding: 16,
        borderRadius: '0 8px 8px 8px',
        height: '100%',
        // @ts-ignore
        textAlign: '-webkit-center',
      }}
    >
      <Col span={24}>{children}</Col>
    </Row>
  );
};

export default TabContainer;
