import { Dispatch, SetStateAction } from 'react';
import { Col, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import { LeftIcon, RightIcon } from '../Icons';

export default function Pagination({
  page,
  setPage,
  pageSize,
  totalCount,
}: {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageSize: number;
  totalCount: number;
}) {
  const backDisabled = page === 1;
  const nextDisabled = Math.ceil(totalCount / pageSize) === page;

  return (
    <Col span={totalCount > 0 ? 24 : 0}>
      <Row gutter={8} justify={'end'}>
        <Col>
          <Space
            onClick={() => {
              if (!backDisabled) setPage((prev) => prev - 1);
            }}
            className={`pagination-circle ${backDisabled ? 'is_disabled' : ''}`}
          >
            <Icon
              component={() => (
                <LeftIcon fill={backDisabled ? '#0D0D0D' : '#fff'} />
              )}
              className={`pagination-arrow ${
                backDisabled ? 'is_disabled' : ''
              }`}
            />
          </Space>
        </Col>
        <Col>
          <Space
            onClick={() => {
              if (!nextDisabled) setPage((prev) => prev + 1);
            }}
            className={`pagination-circle ${nextDisabled ? 'is_disabled' : ''}`}
          >
            <Icon
              component={() => (
                <RightIcon fill={nextDisabled ? '#0D0D0D' : '#fff'} />
              )}
              className={`pagination-arrow ${
                backDisabled ? 'is_disabled' : ''
              }`}
            />
          </Space>
        </Col>
      </Row>
    </Col>
  );
}
