import { Card, Col, message, Row } from 'antd';
import { Text } from '../Text';
import { OutlinedButton, SecondaryButton } from '../Button';
import {
  Group,
  JOIN_GROUP,
  LoggedInUser,
  Mutation,
  MutationJoinGroupArgs,
} from '../../graphql';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { EditOutlined } from '@ant-design/icons';
import { GroupFormModal } from '../../pages';
import { useState } from 'react';

type Props = { group: Group; refetch: () => void };

export default function GroupItem({ group, refetch }: Props) {
  const navigate = useNavigate();
  const user = LoggedInUser();
  const [edit, setEdit] = useState(false);
  const [joinGroup, { loading }] = useMutation<Mutation, MutationJoinGroupArgs>(
    JOIN_GROUP,
  );

  const handleJoin = () => {
    joinGroup({ variables: { groupId: group?.id as string } }).then(() => {
      message.success('Joined!');
      refetch?.();
    });
  };

  return (
    <Card className={'card-no-padding group-card'} style={{ height: '100%' }}>
      <Row>
        <Col span={24}>
          <img
            src={group?.banner}
            alt={''}
            style={{
              width: '100%',
              aspectRatio: 2,
              objectFit: 'cover',
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              cursor: 'pointer',
            }}
            onClick={() => navigate(`/groups/${group?.id}`)}
          />
        </Col>
        <Col span={24} style={{ padding: '12px 16px', display: 'grid' }}>
          <Text variant={'lgStrong'}>{group?.name}</Text>
          <Text variant={'smMedium'} color={'black4'} style={{ marginTop: 4 }}>
            {group?.membersCount} members
          </Text>
          {group.isMember ? (
            <Row
              style={{
                flexWrap: 'nowrap',
                marginTop: 16,
                gap: 10,
                width: '100%',
              }}
            >
              <SecondaryButton
                height={32}
                fontSize={14}
                fullWidth
                onClick={() => navigate(`/groups/${group?.id}`)}
              >
                Visit group
              </SecondaryButton>
              {group.owner?.id === user?.id && (
                <OutlinedButton
                  height={30}
                  fontSize={14}
                  icon={<EditOutlined style={{ fontSize: 16 }} />}
                  onClick={() => setEdit(true)}
                />
              )}
            </Row>
          ) : (
            <OutlinedButton
              height={32}
              fontSize={14}
              block
              style={{ marginTop: 16 }}
              loading={loading}
              onClick={handleJoin}
            >
              Join group
            </OutlinedButton>
          )}
        </Col>
      </Row>
      {edit && (
        <GroupFormModal
          group={group}
          onSuccess={refetch}
          handleClose={() => setEdit(false)}
        />
      )}
    </Card>
  );
}
