import { Col, message, Modal, Row } from 'antd';
import { ErrorButton, OutlinedButton, Text } from '../../../components';
import { useMutation } from '@apollo/client';
import {
  DELETE_EVENT,
  Mutation,
  MutationDeleteEventArgs,
} from '../../../graphql';
import { useNavigate } from 'react-router-dom';

type Props = { eventId: string; handleClose: () => void };

export default function DeleteEventModal({ eventId, handleClose }: Props) {
  const navigate = useNavigate();
  const [deleteEvent, { loading: deleting }] = useMutation<
    Mutation,
    MutationDeleteEventArgs
  >(DELETE_EVENT);

  const handleDelete = () => {
    deleteEvent({
      variables: {
        eventId,
      },
    }).then(() => {
      message.success('Event deleted successfully!');
      navigate('/events');
      handleClose();
    });
  };

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      onCancel={handleClose}
      style={{ textAlign: 'center' }}
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Text fontSize={24} fontWeight={600} color={'black10'}>
            Delete event
          </Text>
        </Col>
        <Col span={24}>
          <Text color={'black5'}>
            If you delete an event, all members will be detached and all posts
            in this event will be removed.
          </Text>
        </Col>
        <Col span={24} style={{ marginTop: 8 }}>
          <Text color={'black5'}>
            Are you sure you want to delete this event? You can’t undo this
            action.
          </Text>
        </Col>
        <Col span={24} style={{ marginTop: 6 }}>
          <Row gutter={16}>
            <Col span={12}>
              <OutlinedButton block onClick={handleClose}>
                Cancel
              </OutlinedButton>
            </Col>
            <Col span={12}>
              <ErrorButton block loading={deleting} onClick={handleDelete}>
                Delete
              </ErrorButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
