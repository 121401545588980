import { useState } from 'react';
import { Dropdown, MenuProps, Row, Spin } from 'antd';
import {
  Group,
  LEAVE_GROUP,
  Mutation,
  MutationLeaveGroupArgs,
} from '../../../graphql';
import { OutlinedButton, Text } from '../../../components';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useMutation } from '@apollo/client';

export default function GroupMemberOptions({
  group,
  refetch,
}: {
  group: Group;
  refetch: () => void;
}) {
  const { sm } = useBreakpoint();
  const [open, setOpen] = useState<boolean>(false);
  const [leaveGroup, { loading: leaving }] = useMutation<
    Mutation,
    MutationLeaveGroupArgs
  >(LEAVE_GROUP);

  const handleLeave = () => {
    leaveGroup({
      variables: { groupId: group.id },
    }).then(() => {
      refetch();
    });
  };

  const items: MenuProps['items'] = [
    {
      key: 'leave',
      label: (
        <Row align={'middle'} style={{ gap: 8 }}>
          {leaving && <Spin size={'small'} />}
          <Text>Leave group</Text>
        </Row>
      ),
      onClick: handleLeave,
    },
  ];

  return (
    <>
      <Dropdown open={open} menu={{ items }} placement={'bottomRight'}>
        <OutlinedButton
          onClick={() => setOpen(!open)}
          block={!sm}
          style={{ gap: 8 }}
        >
          Group member
          <img
            src={require('../../../assets/images/dropdownIcon.png')}
            style={{ height: 14 }}
          />
        </OutlinedButton>
      </Dropdown>
    </>
  );
}
