import { Col, Form, FormInstance, Input, Row, Space } from 'antd';
import { PrimaryButton, Text } from '../../../components';
import { useReactiveVar } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { ProfileUser } from '../../../graphql';
import { advocateQuestionFields, advocateQuestions } from '../../../utils';
import { startCase } from 'lodash';
import { AdvocateFields as FieldType } from './EditProfileModal';

export default function EditAdvocate({ form }: { form: FormInstance }) {
  const user = useReactiveVar(ProfileUser);
  const activities: string[] = Form.useWatch('activities', form);
  const [other, setOther] = useState<string>('');
  const [otherActivities, setOtherActivities] = useState<string[]>([]);

  useEffect(() => {
    if (user?.profile?.advocate?.activities) {
      if (
        user?.profile?.advocate?.activities.some(
          (activity) => !constActivities.includes(activity),
        )
      ) {
        user?.profile?.advocate?.activities.forEach((activity) => {
          if (!constActivities.includes(activity)) {
            setOtherActivities((prev) => [...prev, activity]);
          }
        });
      }
    }
  }, [user]);

  const handleAdd = () => {
    const activityToAdd = startCase(other);

    form.setFieldValue('activities', [...activities, activityToAdd]);
    setOtherActivities((prev) => [...prev, activityToAdd]);
    setOther('');
  };

  const handleSelect = (activity: string) => {
    if (activities?.includes(activity)) {
      form.setFieldValue(
        'activities',
        activities?.filter((item) => item !== activity),
      );
    } else {
      form.setFieldValue('activities', [...activities, activity]);
    }
  };

  useEffect(() => {
    form.setFieldValue('activities', activities);
  }, [activities]);

  const constActivities = useMemo(
    () => [
      'Surfing',
      'Free-diving',
      'Scuba Diving',
      'Sea Swimming',
      'Kayak, Canoe',
      'Lifesaving',
      'Education',
      'Beach Cleanup',
      'Kite-surfing',
    ],
    [],
  );

  console.log('acti', activities);
  return (
    <Row
      gutter={[10, 20]}
      style={{ height: 450, overflowY: 'scroll' }}
      className={'scroll-container'}
    >
      <Col span={24} style={{ display: 'grid', gap: 4 }}>
        <Text variant={'lgStrong'}>Local Beach</Text>
        <Form.Item<FieldType>
          label={
            <Text variant={'smNormal'}>
              {advocateQuestions.localBeach}{' '}
              <Text color={'black4'} variant={'smNormal'}>
                (optional)
              </Text>
            </Text>
          }
          name={'localBeach'}
        >
          <Input placeholder="Enter a beach location/name" />
        </Form.Item>
      </Col>
      <Col span={24} style={{ display: 'grid', gap: 4 }}>
        <Text variant={'lgStrong'}>Ocean Activities</Text>
        <Text variant={'smNormal'}>
          What are you doing in or around the ocean? (Select all that apply){' '}
          <Text color={'black4'} variant={'smNormal'}>
            (optional)
          </Text>
        </Text>
        <Form.Item<FieldType> name={'activities'}>
          <Row gutter={[6, 6]}>
            {[...constActivities, ...otherActivities].map((activity) => (
              <Col span={8} key={activity}>
                <Space
                  onClick={() => handleSelect(activity)}
                  className={`activity-item${
                    activities?.includes(activity) ? '-selected' : ''
                  }`}
                >
                  <Text className={'text'}>{activity}</Text>
                </Space>
              </Col>
            ))}
            <Col span={24}>
              <Row style={{ flexWrap: 'nowrap', gap: 6 }}>
                <Input
                  placeholder={'Other'}
                  value={other}
                  onChange={(e) => setOther(e.target.value)}
                />
                <PrimaryButton disabled={!other.length} onClick={handleAdd}>
                  Add
                </PrimaryButton>
              </Row>
            </Col>
          </Row>
        </Form.Item>
      </Col>
      {advocateQuestionFields.map(({ title, subtitle, field }) => (
        <Col span={24} style={{ display: 'grid', gap: 4 }} key={field}>
          <Text variant={'lgStrong'}>{title}</Text>
          <Text variant={'smNormal'}>
            {subtitle}{' '}
            <Text color={'black4'} variant={'smNormal'}>
              (optional)
            </Text>
          </Text>
          <Form.Item<FieldType> name={field}>
            <Input.TextArea placeholder="Enter your answer" rows={5} />
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
}
