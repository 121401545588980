import { Avatar, Col, Row, Tooltip } from 'antd';
import { colors, getInitials } from '../utils';
import { Text } from './Text';
import '../assets/scss/Header.scss';
import { useDispatch } from 'react-redux';
import { setSidebarOpen } from '../redux/reducers';
import { useReactiveVar } from '@apollo/client';
import { LoggedInUser } from '../graphql';
import { useNavigate } from 'react-router-dom';
import { OutlinedButton, PrimaryButton } from './Button';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const svg_icon1 = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 2C4.24 2 2 4.23 2 6.98V12.96V13.96C2 16.71 4.24 18.94 7 18.94H8.5C8.77 18.94 9.13 19.12 9.3 19.34L10.8 21.33C11.46 22.21 12.54 22.21 13.2 21.33L14.7 19.34C14.89 19.09 15.19 18.94 15.5 18.94H17C19.76 18.94 22 16.71 22 13.96V6.98C22 4.23 19.76 2 17 2H7ZM7 13.75C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75H7ZM7 8.75C6.59 8.75 6.25 8.41 6.25 8C6.25 7.59 6.59 7.25 7 7.25H17C17.41 7.25 17.75 7.59 17.75 8C17.75 8.41 17.41 8.75 17 8.75H7Z"
      fill="#85858C"
    />
  </svg>
);
const svg_icon2 = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.0592 12.1808C18.8092 11.7708 18.5892 10.9808 18.5892 10.5008V8.63078C18.5892 5.00078 15.6392 2.05078 12.0192 2.05078C8.38923 2.06078 5.43923 5.00078 5.43923 8.63078V10.4908C5.43923 10.9708 5.21923 11.7608 4.97923 12.1708L3.84923 14.0508C3.41923 14.7808 3.31923 15.6108 3.58923 16.3308C3.85923 17.0608 4.46923 17.6408 5.26923 17.9008C6.34923 18.2608 7.43923 18.5208 8.54923 18.7108C8.65923 18.7308 8.76923 18.7408 8.87923 18.7608C9.01923 18.7808 9.16923 18.8008 9.31923 18.8208C9.57923 18.8608 9.83923 18.8908 10.1092 18.9108C10.7392 18.9708 11.3792 19.0008 12.0192 19.0008C12.6492 19.0008 13.2792 18.9708 13.8992 18.9108C14.1292 18.8908 14.3592 18.8708 14.5792 18.8408C14.7592 18.8208 14.9392 18.8008 15.1192 18.7708C15.2292 18.7608 15.3392 18.7408 15.4492 18.7208C16.5692 18.5408 17.6792 18.2608 18.7592 17.9008C19.5292 17.6408 20.1192 17.0608 20.3992 16.3208C20.6792 15.5708 20.5992 14.7508 20.1892 14.0608L19.0592 12.1808ZM11.9892 10.7608C11.5692 10.7608 11.2292 10.4208 11.2292 10.0008V6.90078C11.2292 6.48078 11.5692 6.14078 11.9892 6.14078C12.4092 6.14078 12.7492 6.48078 12.7492 6.90078V10.0008C12.7492 10.4208 12.4092 10.7608 11.9892 10.7608Z"
      fill="#85858C"
    />
    <path
      d="M11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22Z"
      fill="#85858C"
    />
  </svg>
);
const svg_icon3 = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.81878 4.77148H3.54628C2.98628 4.77148 2.70628 5.44815 3.10294 5.84482L6.12461 8.86648C6.60878 9.35065 7.39628 9.35065 7.88044 8.86648L9.02961 7.71732L10.9021 5.84482C11.2929 5.44815 11.0129 4.77148 10.4529 4.77148H6.81878Z"
      fill="#9D9DA6"
    />
  </svg>
);
const menu_icon = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0H32C38.6274 0 44 5.37258 44 12V32C44 38.6274 38.6274 44 32 44H12C5.37258 44 0 38.6274 0 32V12Z"
      fill="#F2F2F5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 17.75C12.59 17.75 12.25 17.41 12.25 17C12.25 16.59 12.59 16.25 13 16.25H31C31.41 16.25 31.75 16.59 31.75 17C31.75 17.41 31.41 17.75 31 17.75H13ZM13 22.75C12.59 22.75 12.25 22.41 12.25 22C12.25 21.59 12.59 21.25 13 21.25H31C31.41 21.25 31.75 21.59 31.75 22C31.75 22.41 31.41 22.75 31 22.75H13ZM12.25 27C12.25 27.41 12.59 27.75 13 27.75H31C31.41 27.75 31.75 27.41 31.75 27C31.75 26.59 31.41 26.25 31 26.25H13C12.59 26.25 12.25 26.59 12.25 27Z"
      fill="#85858C"
    />
  </svg>
);

const Header = () => {
  const dispatch = useDispatch();
  const user = useReactiveVar(LoggedInUser);
  const { sm } = useBreakpoint();

  const navigate = useNavigate();
  const navigateToProfile = () => navigate(`/profile`);

  return (
    <div className="header-container">
      <div
        className="header-wrap"
        style={{
          backgroundColor: colors.colorWhite,
          height: '100%',
          borderRadius: '12px',
          padding: '16px',
          border: `1px solid ${colors.black1}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Col
          className="header-item1"
          style={{ width: '100%', maxWidth: '350px' }}
        >
          <Tooltip title={'Coming soon!'}></Tooltip>
          <div className="mobile-wrap">
            <div
              className="menu_icon"
              onClick={() => dispatch(setSidebarOpen(true))}
            >
              {menu_icon}
            </div>
          </div>
        </Col>

        {user?.id ? (
          <>
            <Col className="header-item2">
              <div className="link-item-wrap">
                <Tooltip title={'Coming soon!'} color="rgb(60, 166, 185)">
                  <div className="link-item">
                    {svg_icon1}
                    <Text color={'black5'} fontSize={14} fontWeight={400}>
                      Messages
                    </Text>
                  </div>
                </Tooltip>
                {/* <Tooltip title={'Coming soon!'} color="rgb(60, 166, 185)">
                  <div className="link-item">
                    {svg_icon2}
                    <Text color={'black5'} fontSize={14} fontWeight={400}>
                      Notifications
                    </Text>
                  </div>
                </Tooltip> */}
              </div>
              <div
                onClick={navigateToProfile}
                className="user-info-wrap"
                style={{ borderColor: colors.black1 }}
              >
                <Avatar
                  size={24}
                  className="user-pic"
                  src={user?.profile?.picture}
                >
                  {getInitials(user?.firstName, user?.lastName)}
                </Avatar>
                <Text color={'black10'} fontSize={14} fontWeight={400}>
                  {user?.firstName + ' ' + user?.lastName}
                </Text>
              </div>
            </Col>
          </>
        ) : (
          <Row justify={'end'} style={{ width: '100%', gap: sm ? 20 : 8 }}>
            <Col>
              <OutlinedButton
                height={sm ? 40 : 28}
                fontSize={sm ? 16 : 12}
                onClick={() => navigate('/login')}
              >
                Sign in
              </OutlinedButton>
            </Col>
            <Col>
              <PrimaryButton
                height={sm ? 40 : 28}
                fontSize={sm ? 16 : 12}
                onClick={() => navigate('/register')}
              >
                Register
              </PrimaryButton>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Header;
