import { Card, Col, Row, Image } from 'antd';
import { ProfileUser } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';

export default function Photos({ bordered = false }: { bordered?: boolean }) {
  const user = useReactiveVar(ProfileUser);

  return (
    <Card bordered={bordered} style={bordered ? {} : { boxShadow: 'none' }}>
      <Row gutter={[16, 16]}>
        {user?.profile?.storyteller?.images?.map((image) => (
          <Col xs={24} sm={12} key={image}>
            <Card bodyStyle={{ padding: 0 }}>
              <Image
                src={image}
                wrapperStyle={{ width: '100%', aspectRatio: 1 }}
                style={{
                  width: '100%',
                  aspectRatio: 1,
                  objectFit: 'cover',
                  borderRadius: 12,
                }}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
}
