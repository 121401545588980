import {
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Upload,
} from 'antd';
import {
  EmptyUploadContainer,
  LocationSearch,
  PrimaryButton,
  Text,
} from '../../../components';
import { useMutation } from '@apollo/client';
import { useModalWidth } from '../../../hooks';
import { RcFile, UploadProps } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import {
  CREATE_EVENT,
  CreateEventInput,
  Event,
  Location,
  Mutation,
  MutationCreateEventArgs,
  MutationUpdateEventArgs,
  MutationUploadFileArgs,
  UPDATE_EVENT,
  UPLOAD_FILE,
  uploadClient,
} from '../../../graphql';
import { colors, commonUploadProps, previewImage } from '../../../utils';
import dayjs from 'dayjs';

type Props = {
  event?: Event;
  onSuccess: () => void;
  handleClose: () => void;
};

type FieldType = {
  title: string;
  description: string;
  bannerImage: RcFile;
  start: string;
  end?: string;
  location: string;
};

export default function EventFormModal({
  event,
  onSuccess,
  handleClose,
}: Props) {
  const { width } = useModalWidth();
  const [form] = Form.useForm();
  const [preview, setPreview] = useState<any>();
  const [file, setFile] = useState<UploadFile>();
  const [addEnd, setAddEnd] = useState<boolean>(false);
  const [location, setLocation] = useState<Location>({} as Location);
  const [createEvent, { loading: creating }] = useMutation<
    Mutation,
    MutationCreateEventArgs
  >(CREATE_EVENT);
  const [updateEvent, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateEventArgs
  >(UPDATE_EVENT);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });

  useEffect(() => {
    if (!!event) {
      if (!!event.banner) {
        setPreview(event.banner);
      }
      if (!!event.end) {
        setAddEnd(true);
      }
      if (!!event.location) {
        setLocation(event.location);
      }
    }
  }, [event]);

  useEffect(() => {
    if (location?.placeId) {
      form.setFieldValue('location', location.placeId);
    } else form.setFieldValue('location', undefined);
  }, [location]);

  const handleSave = ({
    location: noop,
    ...values
  }: {
    title: string;
    description: string;
    start: string;
    end?: string;
    location: string;
    banner?: string;
  }) => {
    if (!event) {
      createEvent({
        variables: {
          event: { ...values, location } as unknown as CreateEventInput,
        },
      }).then(() => {
        onSuccess();
        handleClose();
      });
    } else {
      updateEvent({
        variables: {
          eventId: event.id,
          update: {
            ...values,
            ...(location.placeId !== event.location.placeId && {
              location,
            }),
          },
        },
      }).then(() => {
        onSuccess();
        handleClose();
      });
    }
  };

  const onFinish = ({ bannerImage, ...values }: FieldType) => {
    if (!!file) {
      uploadFile({ variables: { file } }).then((res) => {
        const fileString = res.data?.uploadFile;
        handleSave({ ...values, banner: fileString });
      });
    } else {
      handleSave(values);
    }
  };

  const uploadProps: UploadProps = {
    ...commonUploadProps,
    // @ts-ignore
    action: (file) => {
      setFile(file);
      previewImage(file, setPreview);
    },
  };

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      width={width}
      onCancel={handleClose}
    >
      <ConfigProvider
        theme={{
          components: {
            Input: { borderRadius: 8 },
            Form: { itemMarginBottom: 0 },
          },
        }}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Text variant={'heading3'} color={'black10'}>
              {`${!!event ? 'Edit' : 'Create'} event`}
            </Text>
          </Col>
          <Col span={24}>
            <Form
              form={form}
              layout={'vertical'}
              requiredMark={false}
              name="basic"
              style={{ width: '100%' }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                ...event,
                start: event?.start ? dayjs(Number(event.start)) : '',
                end: event?.end ? dayjs(Number(event.end)) : '',
              }}
            >
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <Form.Item<FieldType>
                    name={'title'}
                    label={'Event title'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input placeholder={'Enter a event title'} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item<FieldType>
                    name={'location'}
                    label={'Event location'}
                    rules={[
                      { required: true, message: 'Please enter a location' },
                    ]}
                  >
                    <LocationSearch
                      location={location?.locationString}
                      setLocation={setLocation}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} style={{ display: 'grid', gap: 6 }}>
                  <Form.Item<FieldType>
                    name={'start'}
                    label={'Start date and time'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      showTime={{ format: 'hh:mm a' }}
                      placeholder={'Select start date and time'}
                      format={'DD-MM-YYYY hh:mm a'}
                    />
                  </Form.Item>
                  {!addEnd && (
                    <Text
                      color={'blue6'}
                      variant={'smMedium'}
                      className={'clickable'}
                      onClick={() => setAddEnd(true)}
                    >
                      + Add end date and time
                    </Text>
                  )}
                </Col>
                {addEnd && (
                  <Col span={24} style={{ display: 'grid', gap: 6 }}>
                    <Form.Item<FieldType>
                      name={'end'}
                      label={'End date and time'}
                      rules={[{ required: false, message: '' }]}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        showTime={{ format: 'hh:mm a' }}
                        placeholder={'Select end date and time'}
                        format={'DD-MM-YYYY hh:mm a'}
                      />
                    </Form.Item>
                    <Text
                      color={'blue6'}
                      variant={'smMedium'}
                      className={'clickable'}
                      onClick={() => setAddEnd(false)}
                    >
                      - Remove end date and time
                    </Text>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item<FieldType>
                    name={'description'}
                    label={'Event description'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input.TextArea
                      rows={5}
                      placeholder={'Enter a description'}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item<FieldType>
                    label="Event banner image. Image size: 1500px x 500px recommended."
                    name={'bannerImage'}
                    rules={[
                      {
                        required: !event,
                        message: 'Please add a banner image',
                      },
                    ]}
                  >
                    <Upload.Dragger
                      {...uploadProps}
                      style={{ backgroundColor: colors.black1 }}
                    >
                      {preview ? (
                        <img
                          src={preview}
                          alt={'banner'}
                          style={{ height: 100, objectFit: 'contain' }}
                        />
                      ) : (
                        <EmptyUploadContainer />
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginTop: 4 }}>
                  <PrimaryButton
                    htmlType={'submit'}
                    loading={uploading || creating || updating}
                    block
                  >
                    {!!event ? 'Save changes' : 'Create'}
                  </PrimaryButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </ConfigProvider>
    </Modal>
  );
}
