import { Col, Pagination, Row, Spin, Tabs } from 'antd';
import {
  EventItem,
  LoginToContinueModal,
  PrimaryButton,
  Text,
} from '../../../components';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {
  GET_EVENTS,
  GET_USERS_EVENTS,
  Event,
  Query,
  QueryGetEventsArgs,
  QueryGetUserEventsArgs,
  EventStatus,
  LoggedInUser,
} from '../../../graphql';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { EventFormModal } from '.';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

export default function Events() {
  const { sm, xxl } = useBreakpoint();
  const user = useReactiveVar(LoggedInUser);
  const [userEvents, setUserEvents] = useState<Event[]>();
  const [userEventsCount, setUserEventsCount] = useState<number>(0);
  const [exploreEventsCount, setExploreEventsCount] = useState<number>(0);
  const [exploreEvents, setExploreEvents] = useState<Event[]>();
  const [pastEventsCount, setPastEventsCount] = useState<number>(0);
  const [pastEvents, setPastEvents] = useState<Event[]>();
  const [create, setCreate] = useState<boolean>(false);
  const [userPage, setUserPage] = useState<number>(1);
  const [explorePage, setExplorePage] = useState<number>(1);
  const [pastPage, setPastPage] = useState<number>(1);
  const getPageSize = () => {
    if (xxl) return 12;
    return 6;
  };
  const [getUserEvents, { data, loading, refetch }] = useLazyQuery<
    Query,
    QueryGetUserEventsArgs
  >(GET_USERS_EVENTS, {
    variables: {
      page: userPage,
      take: getPageSize(),
    },
    fetchPolicy: 'network-only',
  });
  const {
    data: exploreData,
    loading: exploreLoading,
    refetch: refetchExplore,
  } = useQuery<Query, QueryGetEventsArgs>(GET_EVENTS, {
    variables: {
      ...(user?.id && { userId: user.id }),
      page: explorePage,
      take: getPageSize(),
      type: 'UPCOMING',
      status: EventStatus.Published,
    },
    fetchPolicy: 'network-only',
  });
  const {
    data: pastData,
    loading: pastLoading,
    refetch: refetchPast,
  } = useQuery<Query, QueryGetEventsArgs>(GET_EVENTS, {
    variables: {
      ...(user?.id && { userId: user.id }),
      page: pastPage,
      take: getPageSize(),
      type: 'PAST',
      status: EventStatus.Published,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (user?.id) {
      getUserEvents();
    }
  }, [user]);

  useEffect(() => {
    if (data?.getUserEvents) {
      setUserEvents(data?.getUserEvents.data);
      setUserEventsCount(data?.getUserEvents.count);
    }
  }, [data]);

  useEffect(() => {
    if (exploreData?.getEvents) {
      setExploreEvents(exploreData?.getEvents.data);
      setExploreEventsCount(exploreData?.getEvents.count);
    }
  }, [exploreData]);

  useEffect(() => {
    if (pastData?.getEvents) {
      setPastEvents(pastData?.getEvents.data);
      setPastEventsCount(pastData?.getEvents.count);
    }
  }, [pastData]);

  const Spinner = () => (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  );

  const refetchEvents = () => {
    setUserPage(1);
    setExplorePage(1);
    setPastPage(1);
    refetch({
      page: 1,
      take: getPageSize(),
    });
    refetchExplore({
      page: 1,
      take: getPageSize(),
    });
    refetchPast({
      page: 1,
      take: getPageSize(),
    });
  };

  const tabs = [
       {
      key: 'upcoming',
      label: 'Explore upcoming',
      children: (
        <Col span={24} style={{ marginTop: 10 }}>
          {exploreLoading ? (
            <Spinner />
          ) : !!exploreEvents?.length ? (
            <Row gutter={[12, 12]}>
              {exploreEvents.map((event) => (
                <Col key={event.id} xs={24} md={12} lg={8} xxl={6}>
                  <EventItem event={event} />
                </Col>
              ))}
              <Col span={24} style={{ textAlign: 'end', marginTop: 16 }}>
                <Pagination
                  hideOnSinglePage
                  pageSize={getPageSize()}
                  current={explorePage}
                  total={exploreEventsCount}
                  onChange={(page) => setExplorePage(page)}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      ),
    },
    {
      key: 'your-events',
      label: `My events (${userEventsCount || '0'})`,
      hide: !user?.id,
      children: (
        <Col span={24} style={{ marginTop: 10 }}>
          {loading ? (
            <Spinner />
          ) : !!userEvents?.length ? (
            <Row gutter={[12, 12]}>
              {userEvents.map((event) => (
                <Col key={event.id} xs={24} md={12} lg={8} xxl={6}>
                  <EventItem event={event} />
                </Col>
              ))}
              <Col span={24} style={{ textAlign: 'end', marginTop: 16 }}>
                <Pagination
                  hideOnSinglePage
                  pageSize={getPageSize()}
                  current={userPage}
                  total={userEventsCount}
                  onChange={(page) => setUserPage(page)}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      ),
    },
    {
      key: 'past',
      label: 'Past events',
      children: (
        <Col span={24} style={{ marginTop: 10 }}>
          {pastLoading ? (
            <Spinner />
          ) : !!pastEvents?.length ? (
            <Row gutter={[12, 12]}>
              {pastEvents.map((event) => (
                <Col key={event.id} xs={24} md={12} lg={8} xxl={6}>
                  <EventItem event={event} />
                </Col>
              ))}
              <Col span={24} style={{ textAlign: 'end', marginTop: 16 }}>
                <Pagination
                  hideOnSinglePage
                  pageSize={getPageSize()}
                  current={pastPage}
                  total={pastEventsCount}
                  onChange={(page) => setPastPage(page)}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      ),
    },
  ];

  return (
    <Col span={24}>
      <Row gutter={[24, 16]} justify={'space-between'}>
        <Col xs={24} sm={14} md={18} style={{ display: 'grid', gap: 10 }}>
          <Text variant={'xlStrong'}>Events</Text>
          <Text color={'black6'}>
            Discover Ocean Culture Event dedicated to saving the ocean! Join
            impactful initiatives from beach cleanups to educational workshops.
            Dive in and make a difference today!
          </Text>
        </Col>
        <Col xs={24} sm={10} md={6}>
          <PrimaryButton
            icon={<PlusOutlined />}
            style={{ float: 'right' }}
            block={!sm}
            onClick={() => setCreate(true)}
          >
            Create new event
          </PrimaryButton>
        </Col>
      </Row>
      <Col span={24} style={{ marginTop: 16 }}>
        <Tabs items={tabs.filter((item) => !item.hide)} />
      </Col>

      {create &&
        (!user?.id ? (
          <LoginToContinueModal handleClose={() => setCreate(false)} />
        ) : (
          <EventFormModal
            onSuccess={refetchEvents}
            handleClose={() => setCreate(false)}
          />
        ))}
    </Col>
  );
}
