import { Card, Col, Row, Space } from 'antd';
import { Profile, ProfileUser, UserRole } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';
import { Text } from '../../../components';
import {
  colors,
  getRoleText,
  getUserLocation,
  openUrlInNewTab,
} from '../../../utils';
import { useCallback, useState } from 'react';
import {
  additionalDetails,
  EditProfileButton,
  EditTemplateButton,
  Interview,
  Overview,
  Photos,
  Posts,
  SendMessageButton,
  ShareProfileButton,
  Tab,
  TabItem,
  urls,
  Videos,
} from '.';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useMatch } from 'react-router-dom';

export default function TemplateA() {
  const { sm, md } = useBreakpoint();
  const user = useReactiveVar(ProfileUser);
  const [selectedTab, setSelectedTab] = useState<Tab>('overview');
  const isProfile = useMatch('/profile');

  const tabs: TabItem[] = [
    { label: 'overview', content: <Overview />, show: true },
    { label: 'interview', content: <Interview bordered />, show: true },
    { label: 'posts', content: <Posts />, show: true },
    {
      label: 'photos',
      content: <Photos bordered />,
      show: user?.role === 'STORYTELLER',
    },
    {
      label: 'videos',
      content: <Videos bordered />,
      show: user?.role === 'STORYTELLER',
    },
  ];

  const urlsSection = useCallback(
    () =>
      !!user &&
      user?.role !== UserRole.Ambassador &&
      !!urls &&
      urls?.some(({ key }) => !!user.profile?.[key as keyof Profile]) ? (
        <Row style={{ marginTop: 24 }} gutter={[16, 16]}>
          {urls.map((url) =>
            !!user.profile?.[url.key as keyof Profile] ? (
              <Col key={url.key}>
                <Card
                  onClick={() =>
                    openUrlInNewTab(user?.profile?.[url.key as keyof Profile])
                  }
                  className={'card-no-padding url-item clickable'}
                  style={{
                    padding: 10,
                    borderRadius: 4,
                  }}
                >
                  <img
                    src={url.icon}
                    alt={''}
                    style={{ width: 20, height: 20 }}
                  />
                </Card>
              </Col>
            ) : (
              <></>
            ),
          )}
        </Row>
      ) : (
        <></>
      ),
    [user, urls],
  );

  return !!user?.profile ? (
    <Row gutter={[24, 16]}>
      <Col md={24} lg={16}>
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Card className={'card-no-padding'}>
              <Row>
                <Col
                  span={24}
                  style={{
                    height: sm ? 210 : 170,
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                    background: `url(${user.profile.banner}) no-repeat`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                />
                <Col span={24}>
                  <Row>
                    <Col
                      style={{
                        padding: '0 24px 0 30px',
                        ...(!sm && { width: '100%' }),
                      }}
                    >
                      <Space
                        style={{
                          position: 'relative',
                          top: sm ? (md ? '-45%' : '-25%') : '-50%',
                          height: sm ? (md ? 190 : 130) : 110,
                          width: sm ? (md ? 190 : 130) : 110,
                          aspectRatio: 1,
                          padding: sm ? (md ? 6 : 4) : 3,
                          borderRadius: 190,
                          backgroundColor: 'white',
                        }}
                      >
                        <img
                          src={user.profile.picture as string}
                          style={{
                            height: sm ? (md ? 178 : 122) : 104,
                            width: sm ? (md ? 178 : 122) : 104,
                            borderRadius: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      </Space>
                    </Col>
                    <Col
                      style={{
                        padding: sm ? '24px 24px 24px 0' : '0 16px 16px 16px',
                        marginTop: sm ? 0 : -32,
                        flex: 1,
                      }}
                    >
                      <Row justify={'space-between'}>
                        <Col xs={24} md={16}>
                          <Row>
                            <Col style={{ display: 'grid' }}>
                              <Text variant={'heading3'} ellipsis={true}>
                                {user.firstName + ' ' + user.lastName}
                              </Text>
                              <Text
                                variant={'baseMedium'}
                                color={'black4'}
                                style={{ marginTop: 7, marginBottom: 14 }}
                              >
                                {getRoleText(user.role)}
                              </Text>
                            </Col>
                          </Row>
                          <Row>
                            <Space
                              style={{
                                width: 'fit-content',
                                backgroundColor: colors.black1,
                                borderRadius: 4,
                                padding: '0 10px',
                              }}
                            >
                              <Text>
                                {getUserLocation(user.profile.location)}
                              </Text>
                            </Space>
                          </Row>
                          {!md && urlsSection()}
                        </Col>
                        <Col xs={24} md={8} style={md ? {} : { marginTop: 24 }}>
                          <Row justify={'end'} gutter={[16, 8]}>
                            {isProfile && (
                              <Col xs={12} md={24}>
                                {isProfile ? (
                                  <EditProfileButton
                                    style={{ float: 'right', width: '100%' }}
                                  />
                                ) : (
                                  <SendMessageButton
                                    style={{ float: 'right', width: '100%' }}
                                  />
                                )}
                              </Col>
                            )}
                            <Col xs={isProfile ? 12 : 24} md={24}>
                              <ShareProfileButton
                                style={{ float: 'right', width: '100%' }}
                              />
                            </Col>
                            <Col
                              xs={isProfile ? 24 : 0}
                              md={0}
                              style={{ marginTop: 8 }}
                            >
                              <EditTemplateButton style={{ width: '100%' }} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {md && (
                        <Row
                          justify={'space-between'}
                          align={'bottom'}
                          style={{ width: '100%' }}
                        >
                          <Col>{urlsSection()}</Col>
                          {isProfile && (
                            <Col>
                              <EditTemplateButton style={{ marginTop: 24 }} />
                            </Col>
                          )}
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col
            span={24}
            className={'scroll-container'}
            style={{
              backgroundColor: colors.black1,
              borderRadius: 7,
              width: '100%',
              padding: 3,
              overflowX: 'scroll',
              overflowY: 'hidden',
            }}
          >
            <Row style={{ width: '100%', flexWrap: 'nowrap' }}>
              {tabs.map((tab) => (
                <Col
                  key={tab.label}
                  onClick={() => setSelectedTab(tab.label)}
                  className={'clickable'}
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    padding: '7px 31px',
                    borderRadius: 5,
                    display: tab.show ? 'flex' : 'none',
                    justifyContent: 'center',
                    backgroundColor:
                      selectedTab === tab.label ? 'white' : 'transparent',
                  }}
                >
                  <Text
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: 'max-content',
                      textTransform: 'capitalize',
                    }}
                    center
                    variant={'smMedium'}
                    color={selectedTab === tab.label ? 'black10' : 'black6'}
                  >
                    {tab.label}
                  </Text>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={24}>
            {tabs.find((tab) => tab.label === selectedTab)?.content}
          </Col>
        </Row>
      </Col>
      <Col md={24} lg={8}>
        <Card>
          <Row>
            <Text variant={'lgStrong'}>Additional Details</Text>
          </Row>
          <Row style={{ marginTop: 12 }} gutter={[0, 24]}>
            {additionalDetails(user).map((item) =>
              item.value ? (
                <Col xs={24} sm={12} lg={24} key={item.label}>
                  <Row
                    gutter={12}
                    align={typeof item.value === 'string' ? 'middle' : 'top'}
                    style={{ flexWrap: 'nowrap' }}
                  >
                    <Col>
                      <img
                        src={item.icon}
                        alt=""
                        style={{ width: 20, height: 20 }}
                      />
                    </Col>
                    <Col style={{ display: 'grid' }}>
                      <Text
                        color={'black3'}
                        variant={'smMedium'}
                        style={{ textTransform: 'uppercase' }}
                      >
                        {item.label}
                      </Text>
                      {typeof item.value === 'string' ? (
                        <Text
                          variant={'smMedium'}
                          color={!!item.onClick ? 'blue6' : 'black10'}
                          onClick={item.onClick}
                          className={!!item.onClick ? 'clickable' : ''}
                        >
                          {item.value}
                        </Text>
                      ) : (
                        item.value
                      )}
                    </Col>
                  </Row>
                </Col>
              ) : (
                <></>
              ),
            )}
          </Row>
        </Card>
      </Col>
    </Row>
  ) : (
    <></>
  );
}
