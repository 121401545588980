import { Col, Form, FormInstance, Row, Image } from 'antd';
import { Text } from '../../../components';
import { colors, templates } from '../../../utils';
import { CheckCircleOutlined } from '@ant-design/icons';

type FieldType = {
  template: string;
};

export default function ChooseTemplate({ form }: { form: FormInstance }) {
  const template = Form.useWatch('template', form);

  return (
    <Col span={24} style={{ marginTop: 36, textAlign: 'left' }}>
      <Row justify={'start'}>
        <Text variant={'lgStrong'}>Choose a template</Text>
      </Row>
      <Row justify={'start'} style={{ marginTop: 6 }}>
        <Text variant={'smNormal'}>
          To visualize your profile you can choose a template for presenting
          your personal page to others!
        </Text>
      </Row>
      <Col span={24} style={{ marginTop: 16 }}>
        <Form.Item<FieldType>
          name={'template'}
          rules={[{ required: true, message: 'Choose a template' }]}
        >
          <Row gutter={16}>
            {templates.map(({ label, value }) => (
              <Col
                key={value}
                span={8}
                className={'clickable template-cards'}
                onClick={() => form.setFieldValue('template', value)}
              >
                <Row gutter={[0, 4]}>
                  <Col span={24}>
                    <Image
                      preview={false}
                      className={'choose-template'}
                      src={require(`../../../assets/images/template_${value}.png`)}
                      style={{
                        width: '100%',
                        borderRadius: 6,
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <Row align={'middle'} style={{ gap: 4 }}>
                      {template === value && (
                        <Col>
                          <CheckCircleOutlined
                            style={{ color: colors.blue6 }}
                          />
                        </Col>
                      )}
                      <Col>
                        <Text
                          variant={'smMedium'}
                          color={template === value ? 'blue6' : 'black10'}
                        >
                          {label}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Form.Item>
      </Col>
    </Col>
  );
}
