import { Card, Col, Image as AntImage, Row, Space } from 'antd';
import { ProfileUser, UserRole } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';
import { Text } from '../../../components';
import {
  advocateQuestions,
  ambassadorQuestions,
  colors,
  getRoleText,
  getUserLocation,
  guardianQuestions,
  openUrlInNewTab,
  storytellerQuestions,
} from '../../../utils';
import {
  additionalDetails,
  EditProfileButton,
  EditTemplateButton,
  Posts,
  SendMessageButton,
  ShareProfileButton,
  urls,
} from '.';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useMatch } from 'react-router-dom';
import websiteUrl from '../../../assets/images/websiteUrlWhite.png';
import { useMemo } from 'react';

export default function TemplateC() {
  const { sm, md, lg } = useBreakpoint();
  const user = useReactiveVar(ProfileUser);
  const isProfile = useMatch('/profile');

  const questionsSet1 = Object.keys(storytellerQuestions).slice(0, 3) ?? [];
  const questionsSet2 = Object.keys(storytellerQuestions).slice(3, 6) ?? [];
  const questionsSet3 = Object.keys(storytellerQuestions).slice(6, 9) ?? [];

  const imageSet1 =
    user?.role === UserRole.Storyteller
      ? user?.profile?.storyteller?.images?.slice(0, 2) ?? []
      : [];
  const imageSet2 =
    user?.role === UserRole.Storyteller
      ? user?.profile?.storyteller?.images?.slice(2, 4) ?? []
      : [];
  const imageSet3 =
    user?.role === UserRole.Storyteller
      ? user?.profile?.storyteller?.images?.slice(4, 6) ?? []
      : [];
  const imageSet4 =
    user?.role === UserRole.Storyteller
      ? user?.profile?.storyteller?.images?.slice(6, 8) ?? []
      : [];
  const imageSet5 =
    user?.role === UserRole.Storyteller
      ? user?.profile?.storyteller?.images?.slice(8, 10) ?? []
      : [];

  const videos =
    user?.role === UserRole.Storyteller
      ? user?.profile?.storyteller?.videoUrls?.map((url) => {
          if (url.includes('youtu')) {
            if (url.includes('watch?v='))
              return `https://www.youtube.com/embed/${
                url.split('watch?v=')[1]
              }`;
            else
              return `https://www.youtube.com/embed/${
                url.split('youtu.be/')[1]
              }`;
          } else if (url.includes('vimeo')) {
            return `https://player.vimeo.com/video/${
              url.split('vimeo.com/')[1]
            }`;
          }
        }) ?? []
      : [];

  const Image = ({ image }: { image: string }) => (
    <Card bodyStyle={{ padding: 0 }}>
      <AntImage
        src={image}
        wrapperStyle={{ width: '100%' }}
        style={{
          width: '100%',
          height: sm ? (md ? (lg ? 577 : 480) : 320) : 280,
          objectFit: 'cover',
          borderRadius: 12,
        }}
      />
    </Card>
  );

  const Video = ({ video }: { video?: string }) =>
    !!video ? (
      <Col span={24}>
        <iframe
          width="100%"
          style={{
            width: '100%',
            height: sm ? (md ? 533 : 320) : 230,
            borderRadius: 12,
            border: `1px solid ${colors.black1}`,
          }}
          src={video}
        />
      </Col>
    ) : (
      <></>
    );

  const horizontalGutter = md ? 50 : 20;
  const verticalGutter = sm ? 50 : 36;

  const questions = useMemo(() => {
    switch (user?.role) {
      case 'STORYTELLER':
        return storytellerQuestions;
      case 'GUARDIAN':
        return guardianQuestions;
      case 'ADVOCATE':
        return advocateQuestions;
      case 'AMBASSADOR':
        return ambassadorQuestions;
      default:
        return {};
    }
  }, [user]);

  return !!user?.profile ? (
    <Row gutter={[0, verticalGutter]}>
      <Col span={24}>
        <Col
          span={24}
          style={{
            minHeight: 420,
            padding: `${sm ? (md ? 120 : 200) : 300}px 30px 30px 30px`,
            borderRadius: 12,
            background: `url(${user.profile.banner}) no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Row
            style={{
              background: `linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)`,
              position: 'absolute',
              height: '100%',
              top: 0,
              left: 0,
              width: md ? '40%' : '60%',
              borderRadius: 12,
            }}
          />
          <Row>
            <Col span={24}>
              <Space
                style={{
                  height: 60,
                  width: 60,
                  aspectRatio: 1,
                  padding: 2,
                  borderRadius: 110,
                  backgroundColor: 'rgba(255, 255, 255, 0.30)',
                }}
              >
                <img
                  src={user.profile.picture as string}
                  style={{
                    height: 56,
                    width: 56,
                    borderRadius: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Space>
            </Col>
            <Col span={24}>
              <Text
                fontSize={md ? 60 : 40}
                fontWeight={600}
                color={'white'}
                lineHeight={'normal'}
              >
                {user.firstName + ' ' + user.lastName}
              </Text>
            </Col>
            <Col span={24}>
              <Text variant={md ? 'heading3' : 'heading5'} color={'black1'}>
                {getRoleText(user.role)}
              </Text>
            </Col>
            <Col span={24} style={{ margin: '20px 0' }}>
              <Space
                style={{
                  width: 'fit-content',
                  backgroundColor: 'rgba(255, 255, 255, 0.14)',
                  borderRadius: 4,
                  padding: '2px 10px',
                }}
              >
                <Text
                  variant={'smMedium'}
                  color={'white'}
                  style={{ zIndex: 10 }}
                >
                  {getUserLocation(user.profile.location)}
                </Text>
              </Space>
            </Col>
            <Col span={24}>
              <Row gutter={[20, 20]} align={'middle'}>
                <Col xs={24} sm={8}>
                  <Row gutter={[12, 12]}>
                    {urls?.map((url) =>
                      !!user.profile?.[url.key as keyof typeof user.profile] ? (
                        <Col key={url.key}>
                          <Card
                            onClick={() =>
                              openUrlInNewTab(
                                user.profile?.[
                                  url.key as keyof typeof user.profile
                                ],
                              )
                            }
                            className={'card-no-padding clickable'}
                            bordered={false}
                            style={{ boxShadow: 'none' }}
                          >
                            <img
                              src={
                                url.key === 'websiteUrl' ? websiteUrl : url.icon
                              }
                              alt={''}
                              style={{ width: 24, height: 24 }}
                            />
                          </Card>
                        </Col>
                      ) : (
                        <></>
                      ),
                    )}
                  </Row>
                </Col>
                <Col xs={24} md={16}>
                  <Row justify={'end'} gutter={[10, 10]}>
                    {isProfile && (
                      <Col style={sm ? {} : { width: '50%' }}>
                        {isProfile ? (
                          <EditProfileButton style={{ width: '100%' }} />
                        ) : (
                          <SendMessageButton style={{ width: '100%' }} />
                        )}
                      </Col>
                    )}
                    <Col
                      style={sm ? {} : { width: isProfile ? '50%' : '100%' }}
                    >
                      <ShareProfileButton style={{ width: '100%' }} />
                    </Col>
                    {isProfile && (
                      <Col style={sm ? {} : { width: '100%' }}>
                        <EditTemplateButton
                          style={{
                            width: '100%',
                            border: '1px solid white',
                            color: 'white',
                            fontWeight: 500,
                            background: `${colors.primary}80`,
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Col>
      <Col span={24}>
        <Row
          gutter={[horizontalGutter, verticalGutter]}
          style={{ padding: `0 ${md ? 30 : 12}px` }}
        >
          <Col span={24}>
            <Text variant={'lgMedium'}>{user.profile.bio}</Text>
          </Col>
          {user?.role === UserRole.Storyteller && !!imageSet1.length && (
            <Col span={24}>
              <Row gutter={horizontalGutter}>
                {imageSet1.map((image) => (
                  <Col span={imageSet1.length === 2 ? 12 : 24} key={image}>
                    <Image image={image} />
                  </Col>
                ))}
              </Row>
            </Col>
          )}
          {user?.role === UserRole.Storyteller && <Video video={videos[0]} />}
          {user?.role === UserRole.Storyteller ? (
            !!questionsSet1.length ? (
              <Col span={24}>
                <Row gutter={[0, verticalGutter]}>
                  {questionsSet1.map((question) =>
                    // @ts-ignore
                    !!user?.profile?.[user.role.toLowerCase()]?.[question] ? (
                      <Col span={24} key={question}>
                        <Row gutter={[0, 14]}>
                          <Col span={24}>
                            <Text variant={'lgStrong'}>
                              {
                                storytellerQuestions[
                                  question as keyof typeof storytellerQuestions
                                ]
                              }
                            </Text>
                          </Col>
                          <Col span={24}>
                            <Text
                              variant={'baseItalic'}
                              color={'black8'}
                              style={{ whiteSpace: 'break-spaces' }}
                            >
                              {/*@ts-ignore*/}
                              {user?.profile?.[user.role.toLowerCase()]?.[
                                question
                              ] ?? ''}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    ) : (
                      <></>
                    ),
                  )}
                </Row>
              </Col>
            ) : (
              <></>
            )
          ) : (
            <Col span={24}>
              <Row gutter={[0, verticalGutter]}>
                {Object.keys(questions).map((question) =>
                  // @ts-ignore
                  !!user?.profile?.[user.role.toLowerCase()]?.[question] ? (
                    <Col span={24} key={question}>
                      <Row gutter={[0, 14]}>
                        <Col span={24}>
                          <Text variant={'lgStrong'}>
                            {questions[question as keyof typeof questions]}
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text
                            variant={'baseItalic'}
                            color={'black8'}
                            style={{ whiteSpace: 'break-spaces' }}
                          >
                            {/*@ts-ignore*/}
                            {user?.profile?.[user.role.toLowerCase()]?.[
                              question
                            ] ?? ''}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <></>
                  ),
                )}
              </Row>
            </Col>
          )}

          {user?.role === UserRole.Storyteller && !!imageSet2.length && (
            <Col span={24}>
              <Row gutter={horizontalGutter}>
                {imageSet2.map((image) => (
                  <Col span={imageSet2.length === 2 ? 12 : 24} key={image}>
                    <Image image={image} />
                  </Col>
                ))}
              </Row>
            </Col>
          )}
          {user?.role === UserRole.Storyteller && !!questionsSet2.length && (
            <Col span={24}>
              <Row gutter={[0, verticalGutter]}>
                {questionsSet2.map((question) =>
                  // @ts-ignore
                  !!user?.profile?.[user.role.toLowerCase()]?.[question] ? (
                    <Col span={24} key={question}>
                      <Row gutter={[0, 14]}>
                        <Col span={24}>
                          <Text variant={'lgStrong'}>
                            {
                              storytellerQuestions[
                                question as keyof typeof storytellerQuestions
                              ]
                            }
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text
                            variant={'baseItalic'}
                            color={'black8'}
                            style={{ whiteSpace: 'break-spaces' }}
                          >
                            {/*@ts-ignore*/}
                            {user?.profile?.[user.role.toLowerCase()]?.[
                              question
                            ] ?? ''}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <></>
                  ),
                )}
              </Row>
            </Col>
          )}

          {user?.role === UserRole.Storyteller && !!imageSet3.length && (
            <Col span={24}>
              <Row gutter={horizontalGutter}>
                {imageSet3.map((image) => (
                  <Col span={imageSet3.length === 2 ? 12 : 24} key={image}>
                    <Image image={image} />
                  </Col>
                ))}
              </Row>
            </Col>
          )}
          {user?.role === UserRole.Storyteller && !!questionsSet3.length && (
            <Col span={24}>
              <Row gutter={[0, verticalGutter]}>
                {questionsSet3.map((question) =>
                  // @ts-ignore
                  !!user?.profile?.[user.role.toLowerCase()]?.[question] ? (
                    <Col span={24} key={question}>
                      <Row gutter={[0, 14]}>
                        <Col span={24}>
                          <Text variant={'lgStrong'}>
                            {
                              storytellerQuestions[
                                question as keyof typeof storytellerQuestions
                              ]
                            }
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text
                            variant={'baseItalic'}
                            color={'black8'}
                            style={{ whiteSpace: 'break-spaces' }}
                          >
                            {/*@ts-ignore*/}
                            {user?.profile?.[user.role.toLowerCase()]?.[
                              question
                            ] ?? ''}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <></>
                  ),
                )}
              </Row>
            </Col>
          )}

          {user?.role === UserRole.Storyteller && !!imageSet4.length && (
            <Col span={24}>
              <Row gutter={horizontalGutter}>
                {imageSet4.map((image) => (
                  <Col span={imageSet4.length === 2 ? 12 : 24} key={image}>
                    <Image image={image} />
                  </Col>
                ))}
              </Row>
            </Col>
          )}

          {user?.role === UserRole.Storyteller && !!imageSet5.length && (
            <Col span={24}>
              <Row gutter={horizontalGutter}>
                {imageSet5.map((image) => (
                  <Col span={imageSet5.length === 2 ? 12 : 24} key={image}>
                    <Image image={image} />
                  </Col>
                ))}
              </Row>
            </Col>
          )}

          {user?.role === UserRole.Storyteller &&
            videos
              ?.slice(1)
              ?.map((video) => <Video video={video} key={video} />)}

          <Col span={24}>
            <Row gutter={[32, 32]}>
              <Col
                xs={{ span: 24, order: 2 }}
                md={{ span: 14, order: 1 }}
                lg={{ span: 16, order: 1 }}
              >
                <Posts />
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                md={{ span: 10, order: 2 }}
                lg={{ span: 8, order: 2 }}
              >
                <Card>
                  <Row>
                    <Text variant={'lgStrong'}>Additional Details</Text>
                  </Row>
                  <Row style={{ marginTop: 12 }} gutter={[0, 24]}>
                    {additionalDetails(user).map((item) =>
                      item.value ? (
                        <Col span={24} key={item.label}>
                          <Row
                            gutter={12}
                            align={
                              typeof item.value === 'string' ? 'middle' : 'top'
                            }
                            style={{ flexWrap: 'nowrap' }}
                          >
                            <Col>
                              <img
                                src={item.icon}
                                alt=""
                                style={{ width: 20, height: 20 }}
                              />
                            </Col>
                            <Col style={{ display: 'grid' }}>
                              <Text
                                color={'black3'}
                                variant={'smMedium'}
                                style={{ textTransform: 'uppercase' }}
                              >
                                {item.label}
                              </Text>
                              {typeof item.value === 'string' ? (
                                <Text
                                  variant={'smMedium'}
                                  color={!!item.onClick ? 'blue6' : 'black10'}
                                  onClick={item.onClick}
                                  className={!!item.onClick ? 'clickable' : ''}
                                >
                                  {item.value}
                                </Text>
                              ) : (
                                item.value
                              )}
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <></>
                      ),
                    )}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <></>
  );
}
