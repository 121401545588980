import React from 'react';
import { Text, PrimaryButton } from '../../components';
import { Input, Form, message } from 'antd';
import { AuthLayout } from './index';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '../../redux/reducers';
import {
  LoggedInUser,
  LOGIN,
  Mutation,
  MutationLoginArgs,
} from '../../graphql';
import { useMutation } from '@apollo/client';
import logo_svg from '../../assets/svg/logo-svg.svg';

type FieldType = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { loading }] = useMutation<Mutation, MutationLoginArgs>(LOGIN);

  const onFinish = async (values: FieldType) => {
    try {
      const res = await login({
        variables: {
          userData: {
            email: values.email,
            password: values.password,
          },
        },
      });
      if (res.data?.login) {
        // if (res.data.login.user.role === 'ADMIN') {
        //   message.error('Please use the admin dashboard for this account!');
        // } else {
        dispatch(setToken(res.data?.login));
        LoggedInUser(res.data.login.user);
        navigate('/');
        // }
      }
    } catch (error) {
      console.log('err login', error);
    }
  };

  return (
    <AuthLayout>
      <Form
        className="login-wrap"
        name="basic"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="logo-wrap">
          <img
            src={logo_svg}
            alt={''}
            style={{ height: '38px', width: '38px' }}
          />
          <Text color={'black10'} fontSize={19} fontWeight={600}>
            Ocean Culture Life
          </Text>
        </div>
        <div className="form-wrap">
          <Text className="item-wrap1" fontSize={20} fontWeight={500}>
            Welcome back!
          </Text>
          <div className="item-wrap2">
            <Text fontSize={12} fontWeight={400} color={'black10'}>
              Don’t have an account?
            </Text>
            <Text
              fontSize={12}
              fontWeight={400}
              color={'blue6'}
              onClick={() => navigate('/register')}
            >
              Sign up
            </Text>
          </div>
          <div className="item-wrap3">
            <Form.Item<FieldType>
              name="email"
              rules={[{ required: true, message: '' }]}
            >
              <Input type="email" placeholder="Email address" />
            </Form.Item>
            <Form.Item<FieldType>
              name="password"
              rules={[{ required: true, message: '' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </div>
          <Text
            className="item-wrap4 clickable"
            onClick={() => navigate('/forgot-password')}
            fontSize={12}
            fontWeight={500}
            color={'blue6'}
          >
            Forgot password?
          </Text>
          <div className="item-wrap5">
            <PrimaryButton htmlType="submit" loading={loading}>
              Log in
            </PrimaryButton>
          </div>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default Login;
