import { UserRole } from '../../graphql';
import {
  BuildAdvocateProfile,
  BuildAmbassadorProfile,
  BuildGuardianProfile,
  BuildStorytellerProfile,
} from './index';

export default function BuildProfilePage({ role }: { role: UserRole }) {
  switch (role) {
    case 'STORYTELLER':
      return <BuildStorytellerProfile />;
    case 'ADVOCATE':
      return <BuildAdvocateProfile />;
    case 'GUARDIAN':
      return <BuildGuardianProfile />;
    case 'AMBASSADOR':
      return <BuildAmbassadorProfile />;
    default:
      return <BuildStorytellerProfile />;
  }
}
