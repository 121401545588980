import { Col, Modal, Row, Tabs } from 'antd';
import { Paragraph, Text } from '../../components';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

type Props = { handleClose: () => void };

const RoleHelpModal = ({ handleClose }: Props) => {
  const { sm, md, lg, xl } = useBreakpoint();

  const getWidth = () => {
    if (xl) return '45%';
    if (lg) return '45%';
    if (md) return '80%';
    return '90%';
  };

  const items = [
    {
      key: 'Storyteller',
      label: 'Storyteller',
      children: <TabContentStoryteller />,
    },
    {
      key: 'Guardian',
      label: 'Guardian',
      children: <TabContentGuardian />,
    },
    {
      key: 'Advocate',
      label: 'Advocate',
      children: <TabContentAdvocate />,
    },
    {
      key: 'Jr. Ocean Ambassador',
      label: 'Jr. Ocean Ambassador',
      children: <TabContentAmbassador />,
    },
  ];

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      width={getWidth()}
      onCancel={handleClose}
    >
      <Row gutter={[0, 12]}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text fontSize={20} fontWeight={500} color={'black10'} center>
            Which role suits me?
          </Text>
          <div className='clickable' onClick={(handleClose)} style={{position: 'absolute', right:'0px', top:'0px'}}>
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.59094 7L13.0441 2.54687C13.2554 2.3359 13.3743 2.04962 13.3745 1.75099C13.3748 1.45237 13.2564 1.16587 13.0455 0.95453C12.8345 0.743185 12.5482 0.624305 12.2496 0.624041C11.951 0.623778 11.6645 0.742152 11.4531 0.953123L7 5.40625L2.54687 0.953123C2.33553 0.741779 2.04888 0.623047 1.75 0.623047C1.45111 0.623047 1.16447 0.741779 0.953123 0.953123C0.741779 1.16447 0.623047 1.45111 0.623047 1.75C0.623047 2.04888 0.741779 2.33553 0.953123 2.54687L5.40625 7L0.953123 11.4531C0.741779 11.6645 0.623047 11.9511 0.623047 12.25C0.623047 12.5489 0.741779 12.8355 0.953123 13.0469C1.16447 13.2582 1.45111 13.3769 1.75 13.3769C2.04888 13.3769 2.33553 13.2582 2.54687 13.0469L7 8.59375L11.4531 13.0469C11.6645 13.2582 11.9511 13.3769 12.25 13.3769C12.5489 13.3769 12.8355 13.2582 13.0469 13.0469C13.2582 12.8355 13.3769 12.5489 13.3769 12.25C13.3769 11.9511 13.2582 11.6645 13.0469 11.4531L8.59094 7Z" fill="#B6B6BF"/>
</svg>

          </div>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Tabs items={items} centered={sm} />
        </Col>
      </Row>
    </Modal>
  );
};

export default RoleHelpModal;

const TabContentStoryteller = () => (
  <Row>
    <Col
      span={24}
      style={{
        display: 'grid',
        gap: 6,
        textAlign: 'left',
        marginTop: 24,
      }}
    >
      <Text fontSize={16} fontWeight={600}>
        Storyteller
      </Text>
      <Paragraph>
      Join our vibrant community of ocean storytellers, where photographers, filmmakers, artisits and creatives unite to share their love for the ocean. As a storyteller, you can share captivating stories from the sea, gain visibility among our partners and brands, and even secure funding for your projects through our storytelling grants. Dive in, share your perspective, and make an impact through the power of narrative.
      </Paragraph>
      {/* <Paragraph>
        bero, sed tincidunt diam porttitor et. Aliquam congue ex nec risus
        placerat, et tristique augue interdum. Nunc ultricies maximus ex,
        dapibus malesuada lacus pulvinar egestas. In ut facilisis purus. Aliquam
        tristique mi ut mattis cursus. Aliquam eget mollis arcu, vitae molestie
        justo. Morbi imperdi
      </Paragraph> */}
    </Col>
    {/* <Col
      span={24}
      style={{
        display: 'grid',
        gap: 6,
        textAlign: 'left',
        marginTop: 24,
      }}
    >
      <Text fontSize={16} fontWeight={600}>
        Lorem ipsum
      </Text>
      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        urna lacus, viverra vitae erat porta, tempus dignissim augue. Vestibulum
        vel dui a magna facilisis pharetra. Morbi faucibus tristique leo, quis
        dapibus lectus finibus vel. In finibus sodales elit non consequat. Cras
        commodo viverra sem, eu lobortis justo posuere eu. Cras eget
        pellentesque odio. Integer sit amet sodales purus, vitae ultrices sem.
      </Paragraph>
      <Paragraph>bero, sed tincidunt diam porttitor et. Aliquam cong</Paragraph>
    </Col> */}
  </Row>
);
const TabContentGuardian = () => (
  <Row>
    <Col
      span={24}
      style={{
        display: 'grid',
        gap: 6,
        textAlign: 'left',
        marginTop: 24,
      }}
    >
      <Text fontSize={16} fontWeight={600}>
        Guardian
      </Text>
      <Paragraph>
      Dedicated to the ocean's health? Step into the role of an Ocean Guardian. This role is perfect for organisations and experts committed to marine protection. Guardians share inspiring stories, collaborate with our community, and access exclusive storytelling funding opportunities. Your efforts are crucial in enhancing ocean literacy, inspiring action, and advocating for the restoration of vibrant marine ecosystems.
      </Paragraph>
      {/* <Paragraph>
        bero, sed tincidunt diam porttitor et. Aliquam congue ex nec risus
        placerat, et tristique augue interdum. Nunc ultricies maximus ex,
        dapibus malesuada lacus pulvinar egestas. In ut facilisis purus. Aliquam
        tristique mi ut mattis cursus. Aliquam eget mollis arcu, vitae molestie
        justo. Morbi imperdi
      </Paragraph> */}
    </Col>
    {/* <Col
      span={24}
      style={{
        display: 'grid',
        gap: 6,
        textAlign: 'left',
        marginTop: 24,
      }}
    >
      <Text fontSize={16} fontWeight={600}>
        Lorem ipsum
      </Text>
      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        urna lacus, viverra vitae erat porta, tempus dignissim augue. Vestibulum
        vel dui a magna facilisis pharetra. Morbi faucibus tristique leo, quis
        dapibus lectus finibus vel. In finibus sodales elit non consequat. Cras
        commodo viverra sem, eu lobortis justo posuere eu. Cras eget
        pellentesque odio. Integer sit amet sodales purus, vitae ultrices sem.
      </Paragraph>
      <Paragraph>bero, sed tincidunt diam porttitor et. Aliquam cong</Paragraph>
    </Col> */}
  </Row>
);
const TabContentAdvocate = () => (
  <Row>
    <Col
      span={24}
      style={{
        display: 'grid',
        gap: 6,
        textAlign: 'left',
        marginTop: 24,
      }}
    >
      <Text fontSize={16} fontWeight={600}>
        Advocate
      </Text>
      <Paragraph>
      Calling all ocean lovers, from scientists and educators to surfers and beach dwellers! As an Advocate, your passion for the ocean drives us forward. Join our community to help amplify the message of ocean conservation. Whether you live by the sea or cherish it from afar, your voice can help protect our oceans and inspire others to act. Let's unite to safeguard our marine environments for generations to come.
      </Paragraph>
      {/* <Paragraph>
        bero, sed tincidunt diam porttitor et. Aliquam congue ex nec risus
        placerat, et tristique augue interdum. Nunc ultricies maximus ex,
        dapibus malesuada lacus pulvinar egestas. In ut facilisis purus. Aliquam
        tristique mi ut mattis cursus. Aliquam eget mollis arcu, vitae molestie
        justo. Morbi imperdi
      </Paragraph> */}
    </Col>
    {/* <Col
      span={24}
      style={{
        display: 'grid',
        gap: 6,
        textAlign: 'left',
        marginTop: 24,
      }}
    >
      <Text fontSize={16} fontWeight={600}>
        Lorem ipsum
      </Text>
      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        urna lacus, viverra vitae erat porta, tempus dignissim augue. Vestibulum
        vel dui a magna facilisis pharetra. Morbi faucibus tristique leo, quis
        dapibus lectus finibus vel. In finibus sodales elit non consequat. Cras
        commodo viverra sem, eu lobortis justo posuere eu. Cras eget
        pellentesque odio. Integer sit amet sodales purus, vitae ultrices sem.
      </Paragraph>
      <Paragraph>bero, sed tincidunt diam porttitor et. Aliquam cong</Paragraph>
    </Col> */}
  </Row>
);
const TabContentAmbassador = () => (
  <Row>
    <Col
      span={24}
      style={{
        display: 'grid',
        gap: 6,
        textAlign: 'left',
        marginTop: 24,
      }}
    >
      <Text fontSize={16} fontWeight={600}>
        Jr. Ocean Ambassador
      </Text>
      <Paragraph>
      Young, passionate, and ready to make a difference? Our Junior Ocean Ambassador program is your gateway to becoming an environmental leader in your school or community. This program equips young enthusiasts with essential ocean literacy skills, fosters self-confidence, and nurtures a lifelong commitment to ocean advocacy. Join us to start your journey as a champion for our oceans.
      </Paragraph>
      {/* <Paragraph>
        bero, sed tincidunt diam porttitor et. Aliquam congue ex nec risus
        placerat, et tristique augue interdum. Nunc ultricies maximus ex,
        dapibus malesuada lacus pulvinar egestas. In ut facilisis purus. Aliquam
        tristique mi ut mattis cursus. Aliquam eget mollis arcu, vitae molestie
        justo. Morbi imperdi
      </Paragraph> */}
    </Col>
    {/* <Col
      span={24}
      style={{
        display: 'grid',
        gap: 6,
        textAlign: 'left',
        marginTop: 24,
      }}
    >
      <Text fontSize={16} fontWeight={600}>
        Lorem ipsum
      </Text>
      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
        urna lacus, viverra vitae erat porta, tempus dignissim augue. Vestibulum
        vel dui a magna facilisis pharetra. Morbi faucibus tristique leo, quis
        dapibus lectus finibus vel. In finibus sodales elit non consequat. Cras
        commodo viverra sem, eu lobortis justo posuere eu. Cras eget
        pellentesque odio. Integer sit amet sodales purus, vitae ultrices sem.
      </Paragraph>
      <Paragraph>bero, sed tincidunt diam porttitor et. Aliquam cong</Paragraph>
    </Col> */}
  </Row>
);
