import * as React from 'react';
import { useMemo, useState } from 'react';
import Map, {
  FullscreenControl,
  Marker,
  NavigationControl,
  Popup,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { colors, getRoleText, getUserLocation } from '../../utils';
import { useQuery } from '@apollo/client';
import {
  Event,
  EventStatus,
  GET_ALL_USERS,
  GET_EVENTS,
  Query,
  QueryGetEventsArgs,
  QueryGetUsersArgs,
  User,
  UserRole,
  UserStatus,
} from '../../graphql';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';
import { Text } from '../Text';
import { PrimaryButton } from '../Button';
import { format } from 'date-fns';
import ocl_logo from '../../assets/images/ocl_logo.png';

export default function UsersMap({ roles }: { roles: UserRole[] }) {
  const navigate = useNavigate();
  const { data: usersData, loading: usersLoading } = useQuery<
    Query,
    QueryGetUsersArgs
  >(GET_ALL_USERS, {
    variables: { status: [UserStatus.Approved], roles },
  });
  const { data: eventsData, loading: eventsLoading } = useQuery<
    Query,
    QueryGetEventsArgs
  >(GET_EVENTS, {
    variables: {
      type: 'UPCOMING',
      status: EventStatus.Published,
    },
    fetchPolicy: 'network-only',
  });

  const [userPopupInfo, setUserPopupInfo] = useState<User | null>(null);
  const [eventPopupInfo, setEventPopupInfo] = useState<Event | null>(null);

  const userBorderColor = {
    [UserRole.Storyteller]: '#08F7EB',
    [UserRole.Guardian]: '#ED7064',
    [UserRole.Advocate]: '#10303B',
    [UserRole.Ambassador]: '#F8C491',
    [UserRole.Admin]: '#F6F6F6',
  };

  // Function to calculate offset for overlapping markers
  const calculateOffset = (index: number, total: number) => {
    const angle = (index / total) * 2 * Math.PI;
    const offsetDistance = 0.01; // Adjust this value to control the offset distance
    return {
      offsetLat: offsetDistance * Math.cos(angle),
      offsetLong: offsetDistance * Math.sin(angle),
    };
  };

  const groupByCoordinates = (items: { lat: number; long: number }[]) => {
    const groups: { [key: string]: any[] } = {};
    items.forEach((item) => {
      const key = `${item.lat},${item.long}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(item);
    });
    return groups;
  };

  const userGroups = useMemo(
    () =>
      groupByCoordinates(
        usersData?.getUsers?.data?.map((user) => ({
          lat: Number(user.profile?.lat),
          long: Number(user.profile?.long),
          user,
        })) || [],
      ),
    [usersData?.getUsers?.data],
  );

  const eventGroups = useMemo(
    () =>
      groupByCoordinates(
        eventsData?.getEvents?.data?.map((event) => ({
          lat: event.location.lat,
          long: event.location.long,
          event,
        })) || [],
      ),
    [eventsData?.getEvents?.data],
  );

  const userPins = useMemo(
    () =>
      Object.values(userGroups).flatMap((group, i) =>
        group.map((item, j) => {
          const { offsetLat, offsetLong } = calculateOffset(j, group.length);
          return (
            <Marker
              key={`user-marker-${item.user.id}-${j}`}
              longitude={item.long + offsetLong}
              latitude={item.lat + offsetLat}
              anchor="bottom"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setUserPopupInfo(item.user);
                setEventPopupInfo(null);
              }}
            >
              <img
                src={item.user.profile?.picture || ocl_logo}
                style={{
                  height: 36,
                  width: 36,
                  borderRadius: '100%',
                  border: `2px solid ${
                    userBorderColor[item.user.role as UserRole]
                  }`,
                  background: 'white',
                  objectFit: 'cover',
                }}
              />
            </Marker>
          );
        }),
      ),
    [userGroups],
  );

  const eventPins = useMemo(
    () =>
      Object.values(eventGroups).flatMap((group, i) =>
        group.map((item, j) => {
          const { offsetLat, offsetLong } = calculateOffset(j, group.length);
          return (
            <Marker
              key={`event-marker-${item.event.id}-${j}`}
              longitude={item.long + offsetLong}
              latitude={item.lat + offsetLat}
              anchor="bottom"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setEventPopupInfo(item.event);
                setUserPopupInfo(null);
              }}
            />
          );
        }),
      ),
    [eventGroups],
  );

  return usersLoading || eventsLoading ? (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  ) : (
    <Map
      initialViewState={{
        latitude: 50,
        zoom: 2,
        bearing: 0,
        pitch: 0,
      }}
      style={{ height: 400, borderRadius: 12 }}
      mapStyle="mapbox://styles/mapbox/light-v11"
      mapboxAccessToken={
        'pk.eyJ1IjoiYmFydGVybWVucyIsImEiOiJjbHoxMWszY2EybG13MmlzaDN0NDdld2ZrIn0.JJ16iFMT4rJI6V7Mjd68aw'
      }
    >
      <FullscreenControl position="top-left" />
      <NavigationControl position="top-left" />

      {userPins}
      {eventPins}

      {userPopupInfo && (
        <Popup
          anchor="top"
          closeButton={false}
          longitude={Number(userPopupInfo.profile?.long)}
          latitude={Number(userPopupInfo.profile?.lat)}
          onClose={() => setUserPopupInfo(null)}
        >
          <div style={{ display: 'grid', gap: 16, minWidth: 190 }}>
            <Row gutter={14} align={'middle'} style={{ flexWrap: 'nowrap' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 16.5,
                  width: 16.5,
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  cursor: 'pointer',
                  zIndex: '2',
                }}
                onClick={() => setUserPopupInfo(null)}
              >
                <img
                  src={require('../../assets/images/closeIcon.png')}
                  style={{ height: 8.5, width: 8.5 }}
                />
              </div>
              <Col>
                <img
                  src={userPopupInfo.profile?.picture || ocl_logo}
                  style={{
                    height: 40,
                    width: 40,
                    objectFit: 'cover',
                    borderRadius: 40,
                  }}
                />
              </Col>
              <Col style={{ display: 'grid' }}>
                <Text
                  variant={'baseStrong'}
                  lineHeight={18}
                  style={{ paddingRight: '10px' }}
                >{`${userPopupInfo.firstName} ${userPopupInfo.lastName}`}</Text>
                <Text
                  variant={'smNormal'}
                  lineHeight={20}
                  color={'black4'}
                  style={{ textWrap: 'nowrap' }}
                >
                  {getRoleText(userPopupInfo.role)}
                </Text>
              </Col>
            </Row>
            <div
              style={{
                padding: '2px 10px',
                background: colors.blue1,
                borderRadius: 4,
                width: 'fit-content',
              }}
            >
              <Text variant={'smMedium'} color={'blue6'} lineHeight={'normal'}>
                {getUserLocation(userPopupInfo.profile?.location)}
              </Text>
            </div>
            <PrimaryButton
              height={26}
              borderRadius={4}
              style={{ fontSize: 12 }}
              block
              onClick={() => navigate(`/user/${userPopupInfo?.id}`)}
            >
              View profile
            </PrimaryButton>
          </div>
        </Popup>
      )}
      {eventPopupInfo && (
        <Popup
          className={'event-popup'}
          anchor="top"
          closeButton={false}
          style={{ zIndex: 100 }}
          longitude={eventPopupInfo.location.long}
          latitude={eventPopupInfo.location.lat}
          onClose={() => setEventPopupInfo(null)}
        >
          <div style={{ minWidth: 230 }}>
            <Row>
              <Col
                span={24}
                style={{
                  height: 100,
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                  background: `url(${eventPopupInfo.banner}) no-repeat`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 16.5,
                    width: 16.5,
                    background: 'rgba(255, 255, 255, 0.50)',
                    borderRadius: '100%',
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    cursor: 'pointer',
                    zIndex: '2',
                  }}
                  onClick={() => setEventPopupInfo(null)}
                >
                  <img
                    src={require('../../assets/images/closeIcon.png')}
                    style={{ height: 8.5, width: 8.5 }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ padding: 12 }} gutter={[0, 16]}>
              <Col style={{ display: 'grid' }} span={24}>
                <Text
                  variant={'baseStrong'}
                  lineHeight={18}
                  style={{ paddingRight: '10px' }}
                >
                  {eventPopupInfo.title}
                </Text>
                <Text
                  variant={'smNormal'}
                  lineHeight={20}
                  color={'black4'}
                  style={{ textWrap: 'nowrap' }}
                >
                  {!!eventPopupInfo?.start
                    ? `${format(
                        new Date(Number(eventPopupInfo.start)),
                        'MMM, d',
                      )}${
                        !!eventPopupInfo.end
                          ? ` - ${format(
                              new Date(Number(eventPopupInfo.end)),
                              'MMM, d',
                            )}`
                          : ''
                      }`
                    : ''}
                </Text>
              </Col>
              <Col span={24}>
                <div
                  style={{
                    padding: '2px 10px',
                    background: colors.blue1,
                    borderRadius: 4,
                    width: 'fit-content',
                  }}
                >
                  <Text
                    variant={'smMedium'}
                    color={'blue6'}
                    lineHeight={'normal'}
                  >
                    {eventPopupInfo.location.title},{' '}
                    {eventPopupInfo.location.description}
                  </Text>
                </div>
              </Col>
              <Col span={24}>
                <PrimaryButton
                  height={26}
                  borderRadius={4}
                  style={{ fontSize: 12 }}
                  block
                  onClick={() => navigate(`events/${eventPopupInfo?.id}`)}
                >
                  View event
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </Popup>
      )}
    </Map>
  );
}
