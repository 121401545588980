import { Col, Form, FormInstance, Input, Row, Upload } from 'antd';
import { RcFile, UploadProps } from 'antd/es/upload';
import {
  BeforeUpload,
  colors,
  commonUploadProps,
  previewImage,
} from '../../utils';
import { EmptyUploadContainer, Text } from '../../components';
import {
  BannerPreview,
  BuildProfile,
  LoggedInUser,
  PicturePreview,
  UserRole,
} from '../../graphql';
import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';

type FieldType = {
  bio: string;
  profilePicture: string;
  profileBanner: string;
  websiteUrl: string;
  companyName: string;
  jobTitle: string;
  facebookUrl: string;
  instagramUrl: string;
  xUrl: string;
  behanceUrl: string;
  linkedInUrl: string;
  tiktokUrl: string;
  youtubeUrl: string;
};

export default function StyleYourProfile({ form }: { form: FormInstance }) {
  const user = useReactiveVar(LoggedInUser);
  const [picError, setPicError] = useState(false);
  const [bannerError, setBannerError] = useState(false);
  const profilePicture: any = Form.useWatch('profilePicture', form);
  const profileBanner: any = Form.useWatch('profileBanner', form);
  const buildProfile = useReactiveVar(BuildProfile);
  const picturePreview = useReactiveVar(PicturePreview);
  const bannerPreview = useReactiveVar(BannerPreview);

  useEffect(() => {
    if (!!profilePicture && picError && !picturePreview) {
      form.resetFields(['profilePicture']);
      setPicError(false);
    }
  }, [profilePicture]);

  useEffect(() => {
    if (!!profileBanner && bannerError && !bannerPreview) {
      form.resetFields(['profileBanner']);
      setBannerError(false);
    }
  }, [profileBanner]);

  const pictureUploadProps: UploadProps = {
    ...commonUploadProps,
    beforeUpload: (file: RcFile) =>
      BeforeUpload(file as RcFile, () => setPicError(true)),
    // @ts-ignore
    action: (file) => {
      BuildProfile({ ...buildProfile, picture: file });
      previewImage(file, PicturePreview);
    },
  };

  const bannerUploadProps: UploadProps = {
    ...commonUploadProps,
    beforeUpload: (file: RcFile) =>
      BeforeUpload(file as RcFile, () => setBannerError(true)),
    // @ts-ignore
    action: (file) => {
      BuildProfile({ ...buildProfile, banner: file });
      previewImage(file, BannerPreview);
    },
  };

  return (
    <Col span={24} style={{ marginTop: 36 }}>
      <Row gutter={[10, 20]}>
        <Col span={24}>
          <Form.Item<FieldType>
            label="Tell us a little about yourself"
            name="bio"
            rules={[{ required: true, message: '' }]}
          >
            <Input.TextArea placeholder="Type your answer here" rows={5} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item<FieldType>
            label="Select a profile picture."
            name={'profilePicture'}
            rules={[
              { required: true, message: 'Please add a profile picture' },
            ]}
          >
            <Upload.Dragger
              {...pictureUploadProps}
              style={{ backgroundColor: colors.black1 }}
            >
              {picturePreview ? (
                <img
                  src={picturePreview}
                  alt={'profile picture'}
                  style={{ height: 100, objectFit: 'contain' }}
                />
              ) : (
                <EmptyUploadContainer />
              )}
            </Upload.Dragger>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item<FieldType>
            label="Select a banner image. Image size: 1500px x 500px recommended."
            name={'profileBanner'}
            rules={[{ required: true, message: 'Please add a banner image' }]}
          >
            <Upload.Dragger
              {...bannerUploadProps}
              style={{ backgroundColor: colors.black1 }}
            >
              {bannerPreview ? (
                <img
                  src={bannerPreview}
                  alt={'banner image'}
                  style={{ height: 100, objectFit: 'contain' }}
                />
              ) : (
                <EmptyUploadContainer />
              )}
            </Upload.Dragger>
          </Form.Item>
        </Col>
        <Col span={user?.role === UserRole.Ambassador ? 0 : 24}>
          <Form.Item<FieldType>
            label={
              <Text variant={'smNormal'}>
                Enter your website/portfolio URL{' '}
                <Text variant={'smNormal'} color={'black4'}>
                  (optional)
                </Text>
              </Text>
            }
            name="websiteUrl"
          >
            <Input placeholder={'Enter website/portfolio URL'} />
          </Form.Item>
        </Col>
        <Col span={user?.role === UserRole.Ambassador ? 0 : 24}>
          <Form.Item<FieldType>
            label={
              <Text variant={'smNormal'}>
                What company do you work for?{' '}
                <Text variant={'smNormal'} color={'black4'}>
                  (optional)
                </Text>
              </Text>
            }
            name="companyName"
          >
            <Input placeholder={'Enter a company name'} />
          </Form.Item>
        </Col>
        <Col span={user?.role === UserRole.Ambassador ? 0 : 24}>
          <Form.Item<FieldType>
            label={
              <Text variant={'smNormal'}>
                What is your job title?{' '}
                <Text variant={'smNormal'} color={'black4'}>
                  (optional)
                </Text>
              </Text>
            }
            name="jobTitle"
          >
            <Input placeholder={'Enter job title'} />
          </Form.Item>
        </Col>
        <Col span={user?.role === UserRole.Ambassador ? 0 : 24}>
          <Row style={{ marginBottom: 4 }}>
            <Text variant={'smNormal'}>
              Add your social media URL’s{' '}
              <Text variant={'smNormal'} color={'black4'}>
                (optional)
              </Text>
            </Text>
          </Row>
          <Row gutter={[0, 6]}>
            <Col span={24}>
              <Form.Item<FieldType> name="facebookUrl">
                <Row
                  align={'middle'}
                  style={{ columnGap: 12, flexWrap: 'nowrap' }}
                >
                  <img
                    src={require('../../assets/images/facebookIcon.png')}
                    alt={'facebook'}
                    style={{ width: 36, height: 36 }}
                  />
                  <Input
                    placeholder={'Enter Facebook profile URL'}
                    style={{ width: '100%' }}
                  />
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item<FieldType> name="instagramUrl">
                <Row
                  align={'middle'}
                  style={{ columnGap: 12, flexWrap: 'nowrap' }}
                >
                  <img
                    src={require('../../assets/images/instagramIcon.png')}
                    alt={'instagram'}
                    style={{ width: 36, height: 36 }}
                  />
                  <Input
                    placeholder={'Enter Instagram profile URL'}
                    style={{ width: '100%' }}
                  />
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item<FieldType> name="xUrl">
                <Row
                  align={'middle'}
                  style={{ columnGap: 12, flexWrap: 'nowrap' }}
                >
                  <img
                    src={require('../../assets/images/xIcon.png')}
                    alt={'x'}
                    style={{ width: 36, height: 36 }}
                  />
                  <Input
                    placeholder={'Enter X profile URL'}
                    style={{ width: '100%' }}
                  />
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item<FieldType> name="behanceUrl">
                <Row
                  align={'middle'}
                  style={{ columnGap: 12, flexWrap: 'nowrap' }}
                >
                  <img
                    src={require('../../assets/images/behanceIcon.png')}
                    alt={'behance'}
                    style={{ width: 36, height: 36 }}
                  />
                  <Input
                    placeholder={'Enter Behance profile URL'}
                    style={{ width: '100%' }}
                  />
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item<FieldType> name="linkedInUrl">
                <Row
                  align={'middle'}
                  style={{ columnGap: 12, flexWrap: 'nowrap' }}
                >
                  <img
                    src={require('../../assets/images/linkedInIcon.png')}
                    alt={'linkedIn'}
                    style={{ width: 36, height: 36 }}
                  />
                  <Input
                    placeholder={'Enter LinkedIn profile URL'}
                    style={{ width: '100%' }}
                  />
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item<FieldType> name="tiktokUrl">
                <Row
                  align={'middle'}
                  style={{ columnGap: 12, flexWrap: 'nowrap' }}
                >
                  <img
                    src={require('../../assets/images/tiktokIcon.png')}
                    alt={'tiktok'}
                    style={{ width: 36, height: 36 }}
                  />
                  <Input
                    placeholder={'Enter TikTok profile URL'}
                    style={{ width: '100%' }}
                  />
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item<FieldType> name="youtubeUrl">
                <Row
                  align={'middle'}
                  style={{ columnGap: 12, flexWrap: 'nowrap' }}
                >
                  <img
                    src={require('../../assets/images/youtubeIcon.png')}
                    alt={'youtube'}
                    style={{ width: 36, height: 36 }}
                  />
                  <Input
                    placeholder={'Enter YouTube profile URL'}
                    style={{ width: '100%' }}
                  />
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
