import { Col, Image, message, Modal, Row } from 'antd';
import { OutlinedButton, PrimaryButton, Text } from '../../../components';
import { colors, templates } from '../../../utils';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useModalWidth } from '../../../hooks';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  Mutation,
  MutationUpdateUserArgs,
  Profile,
  ProfileUser,
  UPDATE_USER,
} from '../../../graphql';
import { useEffect, useState } from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

export default function EditTemplateModal({
  handleClose,
  onSuccess,
}: {
  handleClose: () => void;
  onSuccess: () => void;
}) {
  const { width } = useModalWidth();
  const user = useReactiveVar(ProfileUser);
  const [template, setTemplate] = useState<string>();
  const { sm } = useBreakpoint();
  const [updateUser, { loading }] = useMutation<
    Mutation,
    MutationUpdateUserArgs
  >(UPDATE_USER);

  useEffect(() => {
    setTemplate(
      user?.profile?.[user?.role?.toLowerCase() as keyof Profile].template,
    );
  }, [user]);

  const onSave = () => {
    if (
      template !==
      user?.profile?.[user?.role?.toLowerCase() as keyof Profile].template
    ) {
      updateUser({
        variables: {
          userUpdate: {
            profile: {
              [user?.role.toLowerCase() as keyof Profile]: {
                template,
              },
            },
          },
        },
      })
        .then(() => {
          message.success('Template updated!');
          onSuccess();
          handleClose();
        })
        .catch((err) => {
          console.log('error', err);
        });
    } else handleClose();
  };

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={
        <Row
          style={{ marginBottom: 24 }}
          align={'middle'}
          justify={'space-between'}
        >
          <Col>
            <Text variant={'xlMedium'}>Customise profile</Text>
          </Col>
          <Col style={{ display: sm ? 'flex' : 'none', gap: 12 }}>
            <OutlinedButton height={32} fontSize={14} onClick={handleClose}>
              Cancel
            </OutlinedButton>
            <PrimaryButton
              height={32}
              fontSize={14}
              onClick={onSave}
              loading={loading}
            >
              Save changes
            </PrimaryButton>
          </Col>
        </Row>
      }
      width={width}
      onCancel={handleClose}
    >
      <Col span={24} style={{ display: 'grid', gap: 4 }}>
        <Text variant={'lgStrong'}>Choose a template</Text>
        <Text variant={'smNormal'}>
          To visualize your profile you can choose a template for presenting
          your personal page to others!
        </Text>
        <Row gutter={[16, 16]} justify={'center'} style={{ marginTop: 12 }}>
          {templates.map(({ label, value }) => (
            <Col
              key={value}
              xs={12}
              sm={8}
              className={'clickable template-cards'}
              onClick={() => setTemplate(value)}
            >
              <Row gutter={[0, 4]}>
                <Col span={24}>
                  <Image
                    preview={false}
                    className={'choose-template'}
                    src={require(`../../../assets/images/template_${value}.png`)}
                    style={{
                      width: '100%',
                      borderRadius: 6,
                    }}
                  />
                </Col>
                <Col span={24}>
                  <Row align={'middle'} style={{ gap: 4 }}>
                    {template === value && (
                      <Col>
                        {/*@ts-ignore*/}
                        <CheckCircleOutlined style={{ color: colors.blue6 }} />
                      </Col>
                    )}
                    <Col>
                      <Text
                        variant={'smMedium'}
                        color={template === value ? 'blue6' : 'black10'}
                      >
                        {label}
                      </Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Col>
      <Col xs={24} sm={0} style={{ marginTop: 24 }}>
        <Row gutter={12}>
          <Col span={12}>
            <OutlinedButton
              block
              height={32}
              fontSize={14}
              onClick={handleClose}
            >
              Cancel
            </OutlinedButton>
          </Col>
          <Col span={12}>
            <PrimaryButton
              block
              height={32}
              fontSize={14}
              onClick={onSave}
              loading={loading}
            >
              Save changes
            </PrimaryButton>
          </Col>
        </Row>
      </Col>
    </Modal>
  );
}
