import { Col, Form, FormInstance, Input, Row } from 'antd';
import { Text } from '../../../components';
import { guardianQuestionFields } from '../../../utils';
import { GuardianFields as FieldType } from './EditProfileModal';
import { EditTemplate } from './index';

export default function EditGuardian({ form }: { form: FormInstance }) {
  return (
    <Row
      gutter={[10, 20]}
      style={{ height: 450, overflowY: 'scroll' }}
      className={'scroll-container'}
    >
      <EditTemplate form={form} />
      {guardianQuestionFields.map(({ title, subtitle, field }) => (
        <Col span={24} style={{ display: 'grid', gap: 4 }} key={field}>
          <Text variant={'lgStrong'}>{title}</Text>
          <Text variant={'smNormal'}>
            {subtitle}{' '}
            <Text color={'black4'} variant={'smNormal'}>
              (optional)
            </Text>
          </Text>
          <Form.Item<FieldType> name={field}>
            <Input.TextArea placeholder="Enter your answer" rows={5} />
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
}
