import { Col, Form, FormInstance, Input, Row } from 'antd';
import { Text } from '../../../components';
import { ambassadorQuestionFields } from '../../../utils';
import { AmbassadorFields as FieldType } from './EditProfileModal';
import { EditTemplate } from './index';

export default function EditAmbassador({ form }: { form: FormInstance }) {
  return (
    <Row
      gutter={[10, 20]}
      style={{ height: 450, overflowY: 'scroll' }}
      className={'scroll-container'}
    >
      <EditTemplate form={form} />
      <Col span={24}>
        <Form.Item<FieldType>
          name={'school'}
          label={'Which school are you at?'}
          rules={[{ required: true, message: '' }]}
        >
          <Input placeholder="Enter school name" />
        </Form.Item>
      </Col>
      {ambassadorQuestionFields.map(({ title, subtitle, field }) => (
        <Col span={24} style={{ display: 'grid', gap: 4 }} key={field}>
          <Text variant={'lgStrong'}>{title}</Text>
          <Text variant={'smNormal'}>
            {subtitle}{' '}
            <Text color={'black4'} variant={'smNormal'}>
              (optional)
            </Text>
          </Text>
          <Form.Item<FieldType> name={field}>
            <Input.TextArea placeholder="Enter your answer" rows={5} />
          </Form.Item>
        </Col>
      ))}
    </Row>
  );
}
