import React, { useEffect } from 'react';
import { Col, Spin, Row } from 'antd';
import { AuthLayout } from './index';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Mutation,
  MutationValidateResetPasswordArgs,
  VALIDATE_RESET_PASSWORD,
} from '../../graphql';
import { useMutation } from '@apollo/client';
import { PrimaryButton, Text } from '../../components';

export default function VerifyResetPassword() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [validateResetPassword, { called, error }] = useMutation<
    Mutation,
    MutationValidateResetPasswordArgs
  >(VALIDATE_RESET_PASSWORD);

  const verifyLink = () => {
    validateResetPassword({
      variables: { verificationId: id as string },
    }).then(() => {
      navigate('/reset-password', { state: { verificationId: id as string } });
    });
  };

  useEffect(() => {
    if (!!id) verifyLink();
  }, [id]);

  return (
    <AuthLayout>
      <Row justify={'center'} align={'middle'}>
        {called && !!error ? (
          <Row gutter={[0, 12]}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Text
                fontSize={20}
                fontWeight={600}
                style={{ textAlign: 'center' }}
              >
                Error!
              </Text>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Text style={{ textAlign: 'center' }} color={'black5'}>
                {error.message}
              </Text>
            </Col>
            <Col span={24}>
              <PrimaryButton onClick={() => navigate('/login')} block>
                Back to login
              </PrimaryButton>
            </Col>
          </Row>
        ) : (
          <Spin size={'large'} />
        )}
      </Row>
    </AuthLayout>
  );
}
