import { useState } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { Story, StoryType } from '../../../graphql';
import { DeleteStoryModal, StoryFormModal } from './index';

const FeedItemOptions = ({
  story,
  refetch,
}: {
  story: Story;
  refetch: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const handleEdit = () => {
    setOpen(false);
    setEdit(true);
  };

  const handleDelete = () => {
    setOpen(false);
    setShowDelete(true);
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Edit post',
      onClick: handleEdit,
    },
    {
      key: 'delete',
      label: 'Delete post',
      onClick: handleDelete,
    },
  ];

  return (
    <>
      <Dropdown open={open} menu={{ items }} placement={'bottomRight'}>
        <img
          onClick={() => setOpen(!open)}
          className={'clickable'}
          src={require('../../../assets/images/postOptions.png')}
          alt={''}
        />
      </Dropdown>
      {edit && (
        <StoryFormModal
          type={story.type}
          story={story}
          refetch={refetch}
          handleClose={() => setEdit(false)}
        />
      )}
      {showDelete && (
        <DeleteStoryModal
          story={story}
          refetch={refetch}
          handleClose={() => setShowDelete(false)}
        />
      )}
    </>
  );
};

export default FeedItemOptions;
