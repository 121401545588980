import { Checkbox, Col, DatePicker, Form, FormInstance, Input, Radio, Row } from 'antd';
import { useReactiveVar } from '@apollo/client';
import { ProfileToEdit, ProfileUser } from '../../../graphql';
import { BasicFields as FieldType } from './EditProfileModal';
import { LocationSearch } from '../../../components';
import { colors } from '../../../utils';

export default function EditBasic({ form }: { form: FormInstance }) {
  const user = useReactiveVar(ProfileUser);
  const profileToEdit = useReactiveVar(ProfileToEdit);

  return (
    <Row
      gutter={[10, 20]}
      style={{ height: 450, overflowY: 'scroll' }}
      className={'scroll-container'}
    >
      <Col span={12}>
        <Form.Item<FieldType>
          label={'First name'}
          name={'firstName'}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item<FieldType>
          label={'Last name'}
          name={'lastName'}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item<FieldType>
          label={'Contact & Account email'}
          name={'email'}
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item<FieldType>
          name="hideEmail"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox style={{float: 'left', marginTop: 8, color: colors.black5}}>Hide my email from users</Checkbox>
        </Form.Item> */}
      </Col>
      <Col span={24}>
        <Form.Item<FieldType>
          label={'Date of birth'}
          name={'dob'}
          // rules={[{ required: true, message: '' }]}
        >
          <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} />
        </Form.Item>
        {/* <Form.Item<FieldType>
          name="hideDob"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox style={{float: 'left', marginTop: 8, color: colors.black5}}>Hide my date of birth from users</Checkbox>
        </Form.Item> */}
      </Col>
      <Col span={24}>
        <Form.Item<FieldType>
          label="Where are you based?"
          name="location"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <LocationSearch
            options={{ types: ['(cities)'] }}
            location={user?.profile?.location ?? ''}
            setLocation={({ lat, long, locationString }) => {
              form.setFieldsValue({
                location: locationString,
              });
              ProfileToEdit({
                ...profileToEdit,
                lat: lat.toString(),
                long: long.toString(),
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item<FieldType>
          label="What are your preferred pronouns?"
          name="pronouns"
          rules={[
            {
              required: true,
              message: 'Pronouns are required',
            },
          ]}
        >
          <Radio.Group style={{ marginTop: 4 }}>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Radio value={'he/him/his'}>he/him/his</Radio>
              </Col>
              <Col span={24}>
                <Radio value={'she/her/hers'}>she/her/hers</Radio>
              </Col>
              <Col span={24}>
                <Radio value={'they/them/theirs'}>they/them/theirs</Radio>
              </Col>
              <Col span={24}>
                <Radio value={'other'}>Other</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
}
