import { useEffect, useState } from 'react';
import { Card, Checkbox, Col, Image, Input, Row, Spin, Tabs } from 'antd';
import { ArrowPagination, Text, UserItem } from '..';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_ALL_USERS,
  GET_USERS_BY_DISTANCE,
  LoggedInUser,
  Query,
  QueryGetUsersArgs,
  QueryGetUsersByDistanceArgs,
  UserRole,
  UserStatus,
} from '../../graphql';
import { getRoleText } from '../../utils';
import searchIcon from '../../assets/images/searchIcon.png';
import { debounce } from 'lodash';
import { useMatch } from 'react-router-dom';
import UsersMap from '../../components/common/UsersMap';

type TabKey = 'closest' | 'type' | 'all';

export default function FindAndSearchUsers() {
  const { id: userId } = useReactiveVar(LoggedInUser) ?? {};
  const [page, setPage] = useState<number>(1);
  const [activeKey, setActiveKey] = useState<TabKey>('type');
  const [searchValue, setSearchValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [roles, setRoles] = useState<UserRole[]>([]);
  const isMembersPage = useMatch('/members');
  const pageSize = isMembersPage ? 10 : 5;
  const { data, loading } = useQuery<Query, QueryGetUsersArgs>(GET_ALL_USERS, {
    variables: {
      ...(userId && { exclude: userId }),
      page,
      take: pageSize,
      search,
      roles,
      status: [UserStatus.Approved],
    },
  });
  const [
    getUsersByDistance,
    { data: distanceData, loading: distanceDataLoading },
  ] = useLazyQuery<Query, QueryGetUsersByDistanceArgs>(GET_USERS_BY_DISTANCE, {
    variables: {
      page,
      take: pageSize,
    },
  });

  useEffect(() => {
    if (userId) {
      getUsersByDistance();
      setActiveKey('closest');
    } else setActiveKey('type');
  }, [userId]);

  const debouncedSearch = debounce((searchTerm) => {
    setSearch(searchTerm);
  }, 500);

  const Spinner = () => (
    <Row style={{ padding: '6em' }} justify={'center'}>
      <Spin />
    </Row>
  );

  const roleOptions = Object.keys(UserRole)
    .filter((role) => role !== 'Admin')
    .map((role) => ({
      label: getRoleText(UserRole[role as keyof typeof UserRole]),
      value: UserRole[role as keyof typeof UserRole],
    }));

  const tabs = [
    {
      key: 'closest',
      label: 'Closest to me',
      hide: !userId,
      children: (
        <>
          {distanceDataLoading ? (
            <Spinner />
          ) : (
            <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
              {distanceData?.getUsersByDistance?.data?.map((user) => (
                <UserItem user={user} key={user.id} />
              ))}
              <ArrowPagination
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                totalCount={distanceData?.getUsersByDistance?.count ?? 0}
              />
            </Row>
          )}
        </>
      ),
    },
    {
      key: 'type',
      label: 'Member type',
      children: (
        <Row gutter={[10, 10]} style={{ marginTop: 10 }} justify={'center'}>
          <Col span={24} style={{ margin: '6px 0' }}>
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={(val) => {
                setRoles(val);
                if (page !== 1) setPage(1);
              }}
              value={roles}
            >
              <Row gutter={[8, 8]}>
                {roleOptions.map((option) => (
                  <Col xs={24} md={12} key={option.value}>
                    <Checkbox value={option.value}>{option.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Col>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {data?.getUsers?.data?.map((user) => (
                <UserItem user={user} key={user.id} />
              ))}
              <ArrowPagination
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                totalCount={data?.getUsers?.count ?? 0}
              />
            </>
          )}
        </Row>
      ),
    },
    {
      key: 'all',
      label: 'Search',
      children: (
        <Row gutter={[10, 10]} style={{ marginTop: 10 }} justify={'start'}>
          <Col span={24}>
            <Input
              value={searchValue}
              onChange={(e) => {
                if (page !== 1) setPage(1);
                setSearchValue(e.target.value);
                debouncedSearch(e.target.value);
              }}
              prefix={
                <img
                  src={searchIcon}
                  alt={''}
                  style={{ height: 24, width: 24, marginRight: 6 }}
                />
              }
              placeholder={'Search for a member, country or city'}
            />
          </Col>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {data?.getUsers?.data?.map((user) => (
                <UserItem user={user} key={user.id} />
              ))}
              <ArrowPagination
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                totalCount={data?.getUsers?.count ?? 0}
              />
            </>
          )}
        </Row>
      ),
    },
  ];

  return (
    <Row gutter={[16, 20]}>
      <Col span={24}>
        <Card
          bodyStyle={{
            display: 'grid',
          }}
        >
          <Text variant={'xlStrong'}>Find & Search Members</Text>
          <Text color={'black6'} style={{ margin: '10px 0' }}>
            Search and connect with fellow members based on location, account
            type, or name. Dive into conversations, collaborations, and
            initiatives dedicated to preserving and celebrating our planet's
            oceans.
          </Text>
          {/* <Image
            width={'100%'}
            src={mapsImage}
            preview={false}
            style={{ margin: '20px 0', maxWidth: '900px' }}
          /> */}
          <Col style={{minHeight: '400px'}}>
            <UsersMap roles={activeKey === 'type' ? roles : []} />
          </Col>
          <Tabs
            activeKey={activeKey}
            items={tabs.filter((item) => !item.hide)}
            onChange={(key) => {
              setPage(1);
              setActiveKey(key as TabKey);
            }}
          />
        </Card>
      </Col>
    </Row>
  );
}
