import React, { useEffect } from 'react';
import { Text, PrimaryButton } from '../../components';
import { Input, Form, message, Row, Col } from 'antd';
import { AuthLayout } from './index';
import {
  Mutation,
  MutationResetPasswordArgs,
  RESET_PASSWORD,
} from '../../graphql';
import { useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';

type FieldType = {
  password: string;
  confirmPassword: string;
};

export default function ResetPassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [resetPassword, { loading }] = useMutation<
    Mutation,
    MutationResetPasswordArgs
  >(RESET_PASSWORD);

  useEffect(() => {
    if (!state?.verificationId) {
      message.error('Invalid request');
      navigate('/login');
    }
  }, [state]);

  const onFinish = async ({ password }: FieldType) => {
    resetPassword({
      variables: {
        verificationId: state.verificationId,
        password,
      },
    }).then(() => {
      message.success('Password has been reset!');
      navigate('/login');
    });
  };

  return (
    <AuthLayout>
      <Form
        name="basic"
        style={{ width: '100%', textAlign: 'center' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row justify={'center'}>
          <Text variant={'xlMedium'} center>
            Set a new password
          </Text>
        </Row>
        <Row style={{ margin: '10px 0' }} justify={'center'}>
          <Text variant={'smNormal'} color={'black5'} center>
            Enter your new password
          </Text>
        </Row>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Form.Item<FieldType>
              name="password"
              rules={[{ required: true, message: '' }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item<FieldType>
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: '',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>
          </Col>
        </Row>
        <PrimaryButton
          block
          htmlType="submit"
          loading={loading}
          style={{ marginTop: 24 }}
        >
          Save password
        </PrimaryButton>
      </Form>
    </AuthLayout>
  );
}
