import { Avatar, Card, Col, Pagination, Row, Spin, Tabs, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_GROUP_BY_ID,
  GET_GROUP_MEMBERS,
  GET_STORIES_BY_GROUP, LoggedInUser,
  Query,
  QueryGetGroupByIdArgs,
  QueryGetGroupMembersArgs,
  QueryGetStoriesByGroupArgs,
  StoryType,
} from '../../../graphql';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Text, UserItem } from '../../../components';
import { getInitials, getVideoUrl } from '../../../utils';
import { CreateStoryInput, FeedItem } from '../Feed';
import { GroupActions } from './index';
import { useState } from 'react';

export default function SingleGroup() {
  const { id } = useParams();
  const { md, xxl } = useBreakpoint();
  const user = useReactiveVar(LoggedInUser);
  const [membersPage, setMembersPage] = useState<number>(1);
  const [feedPage, setFeedPage] = useState<number>(1);
  const {
    data,
    loading,
    refetch: refetchGroup,
  } = useQuery<Query, QueryGetGroupByIdArgs>(GET_GROUP_BY_ID, {
    variables: {
      groupId: id as string, ...(user?.id && { userId: user.id }),
    },
  });
  const {
    data: membersData,
    loading: membersLoading,
    refetch: refetchMembers,
  } = useQuery<Query, QueryGetGroupMembersArgs>(GET_GROUP_MEMBERS, {
    variables: { groupId: id as string, page: membersPage, take: 10 },
  });
  const {
    data: storiesData,
    loading: storiesLoading,
    refetch: refetchStories,
  } = useQuery<Query, QueryGetStoriesByGroupArgs>(GET_STORIES_BY_GROUP, {
    variables: { groupId: id as string, page: membersPage, take: 10 },
  });

  const About = () => (
    <Card bodyStyle={{ padding: 16 }}>
      <Row>
        <Text variant={'lgStrong'}>About</Text>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Text color={'black8'} style={{ whiteSpace: 'break-spaces' }}>{data?.getGroupById?.description}</Text>
      </Row>
    </Card>
  );

  const Spinner = () => (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  );

  const refetch = () => {
    refetchGroup();
    refetchStories();
    refetchMembers();
  };

  const tabs = [
    {
      key: 'feed',
      label: 'Feed',
      children: (
        <Row style={{ marginTop: 16 }} gutter={16}>
          <Col xs={24} lg={16} style={{ display: 'grid', gap: 16 }}>
            {data?.getGroupById.isMember && (
              <Row>
                <CreateStoryInput
                  type={StoryType.Group}
                  groupId={data?.getGroupById.id}
                  refetch={refetch}
                />
              </Row>
            )}
            {storiesLoading ? (
              <Spinner />
            ) : (
              <Row gutter={[0, 16]}>
                {storiesData?.getStoriesByGroup?.data?.map((story) => (
                  <Col span={24} key={story.id}>
                    <FeedItem story={story} refetch={refetch} />
                  </Col>
                ))}
                <Row justify={'end'} style={{ marginTop: 16 }}>
                  <Pagination
                    hideOnSinglePage
                    pageSize={10}
                    current={feedPage}
                    total={data?.getStoriesByGroup?.count}
                    onChange={(page) => setFeedPage(page)}
                  />
                </Row>
              </Row>
            )}
          </Col>
          <Col xs={0} lg={8}>
            <About />
          </Col>
        </Row>
      ),
    },
    {
      key: 'members',
      label: 'Members',
      children: (
        <Row style={{ marginTop: 16 }} gutter={16}>
          <Col xs={24} lg={16}>
            {membersLoading ? (
              <Spinner />
            ) : (
              <Row gutter={[0, 12]}>
                {membersData?.getGroupMembers?.data?.map(({ user }) => (
                  <Col span={24} key={user.id}>
                    <UserItem user={user} />
                  </Col>
                ))}
                <Row justify={'end'} style={{ marginTop: 16 }}>
                  <Pagination
                    hideOnSinglePage
                    pageSize={10}
                    current={membersPage}
                    total={data?.getGroupMembers?.count}
                    onChange={(page) => setMembersPage(page)}
                  />
                </Row>
              </Row>
            )}
          </Col>
          <Col xs={0} lg={8}>
            <About />
          </Col>
        </Row>
      ),
    },
    {
      key: 'media',
      label: 'Media',
      children: (
        <Row style={{ marginTop: 16 }} gutter={16}>
          <Col xs={24} lg={16}>
            <Row gutter={[12, 12]}>
              {data?.getGroupById?.media?.map(({ type, url }) => (
                <Col xs={24} sm={12} key={url}>
                  <Card bodyStyle={{ padding: 0 }}>
                    {type === 'image' ? (
                      <img
                        src={url}
                        style={{
                          width: '100%',
                          aspectRatio: 1,
                          objectFit: 'cover',
                          borderRadius: 12,
                        }}
                      />
                    ) : (
                      <video
                        src={getVideoUrl(url)}
                        preload={'metadata'}
                        controls
                        style={{
                          width: '100%',
                          aspectRatio: 1,
                          objectFit: 'cover',
                          borderRadius: 12,
                        }}
                      />
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={0} lg={8}>
            <About />
          </Col>
        </Row>
      ),
    },
  ];

  return loading ? (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  ) : !!data?.getGroupById ? (
    <Row>
      <Col span={24}>
        <Card bodyStyle={{ padding: 0 }}>
          <img
            src={data.getGroupById.banner}
            alt={''}
            style={{
              width: '100%',
              borderRadius: 8,
              height: xxl ? 380 : md ? 280 : 180,
              objectFit: 'cover',
            }}
          />
        </Card>
      </Col>
      <Col span={24} style={{ marginTop: 16 }}>
        <Row>
          <Text variant={'heading3'}>{data.getGroupById.name}</Text>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Text color={'black4'}>{data.getGroupById.membersCount} members</Text>
        </Row>
        <Row
          style={{ marginTop: 10 }}
          justify={'space-between'}
          align={'middle'}
          gutter={[20, 20]}
        >
          <Col xs={24} md={10}>
            {data.getGroupById.members?.map(({ id, user }, index) => (
              <Tooltip key={id} title={`${user.firstName} ${user.lastName}`}>
                <Avatar
                  size={40}
                  src={user.profile?.picture}
                  style={{
                    border: '1px solid white',
                    zIndex: 10,
                    ...(index !== 0 && { zIndex: 10 - index, marginLeft: -8 }),
                  }}
                >
                  {getInitials(user.firstName, user.lastName)}
                </Avatar>
              </Tooltip>
            ))}
          </Col>
          <Col xs={24} md={14}>
            <GroupActions group={data.getGroupById} refetch={refetch} />
          </Col>
        </Row>
        <Col xs={24} lg={0} style={{ marginTop: 24 }}>
          <About />
        </Col>
        <Col span={24} style={{ marginTop: 24 }}>
          <Tabs items={tabs} />
        </Col>
      </Col>
    </Row>
  ) : (
    <></>
  );
}
