import { Col, ConfigProvider, Row } from 'antd';
import { colors } from '../../utils';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

type LayoutProps = {
  children: React.ReactNode;
  center?: boolean;
  className?: string;
};

type ContentProps = {
  children: React.ReactNode;
  center?: boolean;
};

const Content = ({ children, center }: ContentProps) => {
  const { md } = useBreakpoint();

  const getPadding = () => {
    if (md) return '50px 60px';
    return '30px 16px';
  };

  return (
    <Row
      justify={'space-between'}
      style={{ padding: getPadding(), height: '100%' }}
      gutter={[0, 36]}
    >
      <Col span={24} style={center ? { alignSelf: 'center' } : {}}>
        {children}
      </Col>
    </Row>
  );
};

const MobileLayout = ({ children, center, className }: LayoutProps) => {
  return (
    <Row
      justify={'center'}
      align={'middle'}
      style={{
        height: '100vh',
        textAlign: 'center',
        backgroundPosition: 'center',
      }}
      className={className ?? 'bg-1'}
    >
      <Col
        xs={22}
        sm={20}
        md={16}
        className={'scroll-container main-card'}
        style={{
          backgroundColor: colors.white,
          height: '90vh',
          overflow: 'auto',
        }}
      >
        <Content center={center}>{children}</Content>
      </Col>
    </Row>
  );
};

const LargeLayout = ({ children, center, className }: LayoutProps) => {
  const { lg } = useBreakpoint();

  return (
    <Row style={{ height: '100vh', textAlign: 'center' }}>
      <Col
        md={8}
        lg={13}
        xl={14}
        className={className ?? 'bg-1'}
        style={lg ? { backgroundPosition: '30%' } : {}}
      />
      <Col
        md={16}
        lg={11}
        xl={10}
        style={{
          backgroundColor: colors.white,
          boxShadow: '17px 4px 60px 0px rgba(0, 0, 0, 0.45)',
        }}
      >
        <Content center={center}>{children}</Content>
      </Col>
    </Row>
  );
};

const AuthLayout = (props: LayoutProps) => {
  const { lg } = useBreakpoint();

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            borderRadius: 8,
          },
          Form: {
            itemMarginBottom: 0,
          },
        },
      }}
    >
      {lg ? <LargeLayout {...props} /> : <MobileLayout {...props} />}
    </ConfigProvider>
  );
};

export default AuthLayout;
