import { useState } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { Group } from '../../../graphql';
import { DeleteGroupModal, GroupFormModal } from './index';
import { OutlinedButton, Text } from '../../../components';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

export default function GroupOwnerOptions({
  group,
  refetch,
}: {
  group: Group;
  refetch: () => void;
}) {
  const { sm } = useBreakpoint();
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const handleEdit = () => {
    setOpen(false);
    setEdit(true);
  };

  const handleDelete = () => {
    setOpen(false);
    setShowDelete(true);
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Edit group info',
      onClick: handleEdit,
    },
    {
      key: 'delete',
      label: <Text color={'error'}>Delete group</Text>,
      onClick: handleDelete,
    },
  ];

  return (
    <>
      <Dropdown open={open} menu={{ items }} placement={'bottomRight'}>
        <OutlinedButton
          onClick={() => setOpen(!open)}
          block={!sm}
          style={{ gap: 8 }}
        >
          Group owner
          <img
            src={require('../../../assets/images/dropdownIcon.png')}
            style={{ height: 14 }}
          />
        </OutlinedButton>
      </Dropdown>
      {edit && (
        <GroupFormModal
          group={group}
          onSuccess={refetch}
          handleClose={() => setEdit(false)}
        />
      )}
      {showDelete && (
        <DeleteGroupModal
          groupId={group.id}
          handleClose={() => setShowDelete(false)}
        />
      )}
    </>
  );
}
