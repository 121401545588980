import { Card, Col, Row, Spin } from 'antd';
import {
  GET_STORIES_BY_USER,
  ProfileUser,
  Query,
  QueryGetStoriesByUserArgs,
} from '../../../graphql';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Text } from '../../../components';
import { FeedItem } from '../Feed';

export default function Overview() {
  const user = useReactiveVar(ProfileUser);
  const { data, loading, refetch } = useQuery<Query, QueryGetStoriesByUserArgs>(
    GET_STORIES_BY_USER,
    {
      variables: {
        userId: user?.id ?? '',
        take: 1,
        page: 1,
      },
    },
  );

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Card>
          <Row gutter={[0, 14]}>
            <Col span={24}>
              <Text variant={'lgStrong'}>Summary</Text>
            </Col>
            <Col span={24}>
              <Text color={'black8'} style={{ whiteSpace: 'break-spaces' }}>{user?.profile?.bio}</Text>
            </Col>
          </Row>
        </Card>
      </Col>
      {loading ? (
        <Row style={{ padding: '3em' }} justify={'center'}>
          <Spin />
        </Row>
      ) : !!data?.getStoriesByUser?.data?.length ? (
        <Col span={24}>
          {data?.getStoriesByUser?.data?.map((story) => (
            <FeedItem story={story} refetch={refetch} />
          ))}
        </Col>
      ) : (
        <></>
      )}
    </Row>
  );
}
