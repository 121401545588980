import React from 'react';

type IconProps = {
  fill?: string;
};

export const FeedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 22.75C4.35 22.75 2.25 20.65 2.25 17V7C2.25 3.35 4.35 1.25 8 1.25H16C19.65 1.25 21.75 3.35 21.75 7V17C21.75 20.65 19.65 22.75 16 22.75H8ZM3.75 7V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V7C20.25 4.14 18.86 2.75 16 2.75H8C5.14 2.75 3.75 4.14 3.75 7Z"
      fill="#fff"
    />
    <path
      d="M16.5 9.25C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25H16.5Z"
      fill="#fff"
    />
    <path
      d="M8 13.75C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H12C12.41 12.25 12.75 12.59 12.75 13C12.75 13.41 12.41 13.75 12 13.75H8Z"
      fill="#fff"
    />
    <path
      d="M8 17.75C7.59 17.75 7.25 17.41 7.25 17C7.25 16.59 7.59 16.25 8 16.25H16C16.41 16.25 16.75 16.59 16.75 17C16.75 17.41 16.41 17.75 16 17.75H8Z"
      fill="#fff"
    />
  </svg>
);

export const HomeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.25 17.78C1.25 20.52 3.47 22.75 6.21 22.75H17.79C20.53 22.75 22.75 20.52 22.75 17.79V10.51C22.75 9.05997 21.82 7.27997 20.63 6.44997L14.45 2.11997C12.77 0.939974 10.18 0.999974 8.56 2.25997L3.17 6.45997C2.09 7.29997 1.25 9.00997 1.25 10.37V17.78ZM2.75 10.37C2.75 9.46997 3.38 8.19997 4.09 7.63997L9.48 3.43997C10.57 2.58997 12.45 2.54997 13.59 3.34997L19.77 7.67997C20.56 8.21997 21.25 9.54997 21.25 10.51V17.79C21.25 19.7 19.7 21.25 17.79 21.25H6.21C4.3 21.25 2.75 19.69 2.75 17.78V10.37ZM11.25 15V18C11.25 18.41 11.59 18.75 12 18.75C12.41 18.75 12.75 18.41 12.75 18V15C12.75 14.59 12.41 14.25 12 14.25C11.59 14.25 11.25 14.59 11.25 15Z"
      fill="#fff"
    />
  </svg>
);

export const EventsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25 5V2C7.25 1.59 7.59 1.25 8 1.25C8.41 1.25 8.75 1.59 8.75 2V5C8.75 5.41 8.41 5.75 8 5.75C7.59 5.75 7.25 5.41 7.25 5Z"
      fill="#fff"
    />
    <path
      d="M15.25 5V2C15.25 1.59 15.59 1.25 16 1.25C16.41 1.25 16.75 1.59 16.75 2V5C16.75 5.41 16.41 5.75 16 5.75C15.59 5.75 15.25 5.41 15.25 5Z"
      fill="#fff"
    />
    <path
      d="M8.12 14.4203C7.99 14.3703 7.89 14.3003 7.79 14.2103C7.61 14.0203 7.5 13.7703 7.5 13.5003C7.5 13.3703 7.53 13.2403 7.58 13.1203C7.63 13.0003 7.7 12.8903 7.79 12.7903C7.89 12.7003 7.99 12.6303 8.12 12.5803C8.48 12.4303 8.93 12.5103 9.21 12.7903C9.39 12.9803 9.5 13.2403 9.5 13.5003C9.5 13.5603 9.49 13.6303 9.48 13.7003C9.47 13.7603 9.45 13.8203 9.42 13.8803C9.4 13.9403 9.37 14.0003 9.33 14.0603C9.3 14.1103 9.25 14.1603 9.21 14.2103C9.02 14.3903 8.76 14.5003 8.5 14.5003C8.37 14.5003 8.24 14.4703 8.12 14.4203Z"
      fill="#fff"
    />
    <path
      d="M11.62 14.4199C11.49 14.3699 11.39 14.2999 11.29 14.2099C11.11 14.0199 11 13.7699 11 13.4999C11 13.3699 11.03 13.2399 11.08 13.1199C11.13 12.9999 11.2 12.8899 11.29 12.7899C11.39 12.6999 11.49 12.6299 11.62 12.5799C11.98 12.4199 12.43 12.5099 12.71 12.7899C12.89 12.9799 13 13.2399 13 13.4999C13 13.5599 12.99 13.6299 12.98 13.6999C12.97 13.7599 12.95 13.8199 12.92 13.8799C12.9 13.9399 12.87 13.9999 12.83 14.0599C12.8 14.1099 12.75 14.1599 12.71 14.2099C12.52 14.3899 12.26 14.4999 12 14.4999C11.87 14.4999 11.74 14.4699 11.62 14.4199Z"
      fill="#fff"
    />
    <path
      d="M15.12 14.4199C14.99 14.3699 14.89 14.2999 14.79 14.2099C14.75 14.1599 14.71 14.1099 14.67 14.0599C14.63 13.9999 14.6 13.9399 14.58 13.8799C14.55 13.8199 14.53 13.7599 14.52 13.6999C14.51 13.6299 14.5 13.5599 14.5 13.4999C14.5 13.2399 14.61 12.9799 14.79 12.7899C14.89 12.6999 14.99 12.6299 15.12 12.5799C15.49 12.4199 15.93 12.5099 16.21 12.7899C16.39 12.9799 16.5 13.2399 16.5 13.4999C16.5 13.5599 16.49 13.6299 16.48 13.6999C16.47 13.7599 16.45 13.8199 16.42 13.8799C16.4 13.9399 16.37 13.9999 16.33 14.0599C16.3 14.1099 16.25 14.1599 16.21 14.2099C16.02 14.3899 15.76 14.4999 15.5 14.4999C15.37 14.4999 15.24 14.4699 15.12 14.4199Z"
      fill="#fff"
    />
    <path
      d="M8.12 17.9202C8 17.8702 7.89 17.8002 7.79 17.7102C7.61 17.5202 7.5 17.2602 7.5 17.0002C7.5 16.8702 7.53 16.7402 7.58 16.6202C7.63 16.4902 7.7 16.3802 7.79 16.2902C8.16 15.9202 8.84 15.9202 9.21 16.2902C9.39 16.4802 9.5 16.7402 9.5 17.0002C9.5 17.2602 9.39 17.5202 9.21 17.7102C9.02 17.8902 8.76 18.0002 8.5 18.0002C8.37 18.0002 8.24 17.9702 8.12 17.9202Z"
      fill="#fff"
    />
    <path
      d="M11.29 17.7102C11.11 17.5202 11 17.2602 11 17.0002C11 16.8702 11.03 16.7402 11.08 16.6202C11.13 16.4902 11.2 16.3802 11.29 16.2902C11.66 15.9202 12.34 15.9202 12.71 16.2902C12.8 16.3802 12.87 16.4902 12.92 16.6202C12.97 16.7402 13 16.8702 13 17.0002C13 17.2602 12.89 17.5202 12.71 17.7102C12.52 17.8902 12.26 18.0002 12 18.0002C11.74 18.0002 11.48 17.8902 11.29 17.7102Z"
      fill="#fff"
    />
    <path
      d="M14.79 17.7099C14.7 17.6199 14.63 17.5099 14.58 17.3799C14.53 17.2599 14.5 17.1299 14.5 16.9999C14.5 16.8699 14.53 16.7399 14.58 16.6199C14.63 16.4899 14.7 16.3799 14.79 16.2899C15.02 16.0599 15.37 15.9499 15.69 16.0199C15.76 16.0299 15.82 16.0499 15.88 16.0799C15.94 16.0999 16 16.1299 16.06 16.1699C16.11 16.1999 16.16 16.2499 16.21 16.2899C16.39 16.4799 16.5 16.7399 16.5 16.9999C16.5 17.2599 16.39 17.5199 16.21 17.7099C16.02 17.8899 15.76 17.9999 15.5 17.9999C15.24 17.9999 14.98 17.8899 14.79 17.7099Z"
      fill="#fff"
    />
    <path
      d="M3.5 9.83984C3.09 9.83984 2.75 9.49984 2.75 9.08984C2.75 8.67984 3.09 8.33984 3.5 8.33984H20.5C20.91 8.33984 21.25 8.67984 21.25 9.08984C21.25 9.49984 20.91 9.83984 20.5 9.83984H3.5Z"
      fill="#fff"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 22.75C4.35 22.75 2.25 20.65 2.25 17V8.5C2.25 4.85 4.35 2.75 8 2.75H16C19.65 2.75 21.75 4.85 21.75 8.5V17C21.75 20.65 19.65 22.75 16 22.75H8ZM3.75 8.5V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V8.5C20.25 5.64 18.86 4.25 16 4.25H8C5.14 4.25 3.75 5.64 3.75 8.5Z"
      fill="#fff"
    />
  </svg>
);

export const GroupsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.08055 11.62C9.03055 11.61 8.96055 11.61 8.90055 11.62C6.00055 11.53 3.81055 9.25 3.81055 6.44C3.81055 3.58 6.14055 1.25 9.00055 1.25C11.8605 1.25 14.1905 3.58 14.1905 6.44C14.1805 9.25 11.9805 11.53 9.19055 11.62C9.18055 11.62 9.17055 11.62 9.16055 11.62C9.13055 11.62 9.11055 11.62 9.08055 11.62ZM5.31055 6.44C5.31055 8.44 6.87055 10.05 8.86055 10.12C8.92055 10.11 9.05055 10.11 9.18055 10.12C11.1405 10.03 12.6805 8.42 12.6905 6.44C12.6905 4.41 11.0305 2.75 9.00055 2.75C6.97055 2.75 5.31055 4.41 5.31055 6.44Z"
      fill="#fff"
    />
    <path
      d="M16.4504 11.74C16.0404 11.78 15.6204 11.49 15.5804 11.08C15.5404 10.67 15.7904 10.3 16.2004 10.25C16.3204 10.24 16.4504 10.24 16.5604 10.24C18.0204 10.16 19.1604 8.96 19.1604 7.49C19.1604 5.97 17.9304 4.74 16.4104 4.74C16.0004 4.75 15.6604 4.41 15.6604 4C15.6604 3.59 16.0004 3.25 16.4104 3.25C18.7504 3.25 20.6604 5.16 20.6604 7.5C20.6604 9.8 18.8604 11.66 16.5704 11.75C16.5604 11.75 16.5504 11.75 16.5404 11.75C16.5104 11.75 16.4804 11.75 16.4504 11.74Z"
      fill="#fff"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05ZM3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51Z"
      fill="#fff"
    />
    <path
      d="M17.6102 20.15C17.5302 19.74 17.7902 19.35 18.1902 19.26C18.8202 19.13 19.4002 18.88 19.8502 18.53C20.4202 18.1 20.7302 17.56 20.7302 16.99C20.7302 16.42 20.4202 15.88 19.8602 15.46C19.4202 15.12 18.8702 14.88 18.2202 14.73C17.8202 14.64 17.5602 14.24 17.6502 13.83C17.7402 13.43 18.1402 13.17 18.5502 13.26C19.4102 13.45 20.1602 13.79 20.7702 14.26C21.7002 14.96 22.2302 15.95 22.2302 16.99C22.2302 18.03 21.6902 19.02 20.7602 19.73C20.1402 20.21 19.3602 20.56 18.5002 20.73C18.4402 20.75 18.3902 20.75 18.3402 20.75C17.9902 20.75 17.6802 20.51 17.6102 20.15Z"
      fill="#fff"
    />
  </svg>
);

export const FundingsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.12 19.6201C6.7 18.4501 1.25 14.2701 1.25 8.09009C1.25 4.92009 3.80999 2.3501 6.95999 2.3501C8.46999 2.3501 9.87999 2.93008 10.93 3.96008C11.99 2.93008 13.39 2.3501 14.9 2.3501C18.05 2.3501 20.61 4.92009 20.61 8.09009C20.61 8.52009 20.58 8.9601 20.52 9.4101C20.49 9.6401 20.35 9.85009 20.15 9.97009C19.95 10.0901 19.7 10.1001 19.48 10.0101C18.25 9.4801 16.72 9.8801 15.92 10.9501C15.78 11.1401 15.51 11.2401 15.32 11.2501C15.08 11.2501 14.86 11.1401 14.72 10.9501C14.15 10.1901 13.28 9.7601 12.34 9.7601C10.71 9.7601 9.38 11.1001 9.38 12.7501C9.38 15.3501 10.87 17.2001 12.13 18.3101C12.31 18.4701 12.4 18.7001 12.38 18.9401C12.36 19.1801 12.23 19.3901 12.02 19.5201C11.91 19.5801 11.82 19.6201 11.77 19.6301C11.51 19.7101 11.22 19.7501 10.92 19.7501C10.63 19.7501 10.35 19.7101 10.12 19.6201ZM2.75 8.09009C2.75 13.3201 7.10001 16.5401 9.60001 17.7801C8.65001 16.5101 7.87 14.8301 7.87 12.7401C7.87 10.2601 9.86999 8.25009 12.33 8.25009C13.45 8.25009 14.5 8.66011 15.31 9.39011C16.32 8.49011 17.76 8.08008 19.1 8.33008C19.1 8.25008 19.1 8.1701 19.1 8.1001C19.1 5.7601 17.21 3.86008 14.89 3.86008C13.56 3.86008 12.33 4.48008 11.52 5.55008C11.38 5.74008 11.16 5.8501 10.92 5.8501C10.68 5.8501 10.46 5.74008 10.32 5.55008C9.53001 4.47008 8.29999 3.8501 6.95999 3.8501C4.63999 3.8501 2.75 5.75009 2.75 8.09009Z"
      fill="#fff"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6606 21.55C13.8006 21.26 12.4106 20.55 11.1406 19.42C9.66062 18.11 7.89062 15.89 7.89062 12.74C7.89062 10.26 9.89062 8.25 12.3506 8.25C13.4706 8.25 14.5206 8.66001 15.3306 9.39001C16.5906 8.26001 18.5106 7.94 20.0906 8.63C21.7106 9.35 22.7606 10.97 22.7606 12.75C22.7606 17.49 18.6006 20.67 15.9806 21.56C15.7806 21.62 15.5506 21.65 15.3206 21.65C15.0906 21.65 14.8506 21.62 14.6606 21.55ZM9.38062 12.74C9.38062 15.34 10.8706 17.19 12.1306 18.3C13.2406 19.28 14.4106 19.89 15.1406 20.13C15.2206 20.16 15.4106 20.16 15.4806 20.13C16.9806 19.62 21.2506 17.17 21.2506 12.74C21.2506 11.55 20.5506 10.47 19.4806 10C18.2706 9.47 16.7206 9.87 15.9206 10.94C15.7806 11.13 15.5106 11.23 15.3206 11.24C15.0806 11.24 14.8606 11.13 14.7206 10.94C14.1506 10.18 13.2806 9.75 12.3406 9.75C10.7106 9.75 9.38062 11.09 9.38062 12.74Z"
      fill="#fff"
    />
  </svg>
);

export const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1105 22.27C10.6705 22.27 8.5305 20.52 8.1605 16.6C8.1205 16.19 8.4205 15.82 8.8405 15.78C9.2505 15.74 9.6205 16.05 9.6605 16.46C9.9505 19.6 11.4305 20.77 15.1205 20.77H15.2505C19.3205 20.77 20.7605 19.33 20.7605 15.26V8.73998C20.7605 4.66998 19.3205 3.22998 15.2505 3.22998H15.1205C11.4105 3.22998 9.9305 4.41998 9.6605 7.61998C9.6105 8.02998 9.2705 8.33998 8.8405 8.29998C8.4205 8.26998 8.1205 7.89998 8.1505 7.48998C8.4905 3.50998 10.6405 1.72998 15.1105 1.72998H15.2405C20.1505 1.72998 22.2505 3.82998 22.2505 8.73998V15.26C22.2505 20.17 20.1505 22.27 15.2405 22.27H15.1105Z"
      fill="black"
    />
    <path
      d="M2 12.75C1.59 12.75 1.25 12.41 1.25 12C1.25 11.59 1.59 11.25 2 11.25H14.88C15.29 11.25 15.63 11.59 15.63 12C15.63 12.41 15.3 12.75 14.88 12.75H2Z"
      fill="black"
    />
    <path
      d="M12.1198 15.8801C11.8298 15.5901 11.8298 15.1101 12.1198 14.8201L14.9398 12.0001L12.1198 9.18009C11.8298 8.89009 11.8298 8.41009 12.1198 8.12009C12.4098 7.83009 12.8898 7.83009 13.1798 8.12009L16.5298 11.4701C16.8198 11.7601 16.8198 12.2401 16.5298 12.5301L13.1798 15.8801C13.0298 16.0301 12.8398 16.1001 12.6498 16.1001C12.4598 16.1001 12.2698 16.0301 12.1198 15.8801Z"
      fill="black"
    />
  </svg>
);

export const MembersIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75C8.83 12.75 6.25 10.17 6.25 7ZM7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75C9.66 2.75 7.75 4.66 7.75 7Z"
      fill="#fff"
    />
    <path
      d="M2.66016 22C2.66016 17.73 6.85018 14.25 12.0002 14.25C12.4102 14.25 12.7502 14.59 12.7502 15C12.7502 15.41 12.4102 15.75 12.0002 15.75C7.68018 15.75 4.16016 18.55 4.16016 22C4.16016 22.41 3.82016 22.75 3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22Z"
      fill="#fff"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.25 18.2C14.25 16.02 16.02 14.25 18.2 14.25C20.38 14.25 22.15 16.02 22.15 18.2C22.15 20.38 20.38 22.15 18.2 22.15C16.02 22.15 14.25 20.38 14.25 18.2ZM15.75 18.2C15.75 19.55 16.85 20.65 18.2 20.65C19.55 20.65 20.65 19.55 20.65 18.2C20.65 16.85 19.55 15.75 18.2 15.75C16.85 15.75 15.75 16.85 15.75 18.2Z"
      fill="#fff"
    />
    <path
      d="M21.4695 22.53L20.4695 21.53C20.1795 21.24 20.1795 20.7599 20.4695 20.4699C20.7595 20.1799 21.2395 20.1799 21.5295 20.4699L22.5295 21.4699C22.8195 21.7599 22.8195 22.24 22.5295 22.53C22.3795 22.68 22.1895 22.75 21.9995 22.75C21.8095 22.75 21.6195 22.68 21.4695 22.53Z"
      fill="#fff"
    />
  </svg>
);

export const ProfileIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9005 11.62C11.9605 11.61 12.0305 11.61 12.0805 11.62H12.1605H12.1905C14.9805 11.53 17.1805 9.25 17.1905 6.44C17.1905 3.58 14.8605 1.25 12.0005 1.25C9.14055 1.25 6.81055 3.58 6.81055 6.44C6.81055 9.25 9.00055 11.53 11.9005 11.62ZM11.8605 10.12C9.87055 10.05 8.31055 8.44 8.31055 6.44C8.31055 4.41 9.97055 2.75 12.0005 2.75C14.0305 2.75 15.6905 4.41 15.6905 6.44C15.6805 8.42 14.1405 10.03 12.1805 10.12C12.0505 10.11 11.9105 10.11 11.8605 10.12ZM4.59961 17.5C4.59961 18.87 5.35961 20.13 6.74961 21.05C8.23961 22.05 10.2096 22.55 12.1696 22.55C14.1296 22.55 16.0896 22.05 17.5896 21.05C18.9796 20.12 19.7396 18.85 19.7396 17.48C19.7396 16.11 18.9696 14.85 17.5896 13.93C14.6096 11.94 9.74961 11.94 6.74961 13.93C5.35961 14.86 4.59961 16.13 4.59961 17.5ZM7.57961 19.81C6.62961 19.18 6.09961 18.36 6.09961 17.51C6.09961 16.65 6.61961 15.83 7.57961 15.19C10.0696 13.53 14.2696 13.53 16.7596 15.19C17.7096 15.82 18.2396 16.64 18.2396 17.49C18.2396 18.35 17.7196 19.17 16.7596 19.81C14.2696 21.48 10.0696 21.48 7.57961 19.81Z"
      fill="#fff"
    />
  </svg>
);

export const RightIcon = ({ fill = '#fff' }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.37961 20.45C8.08961 20.16 8.08961 19.68 8.37961 19.39L14.8996 12.87C15.3796 12.39 15.3796 11.61 14.8996 11.13L8.37961 4.61002C8.08961 4.32002 8.08961 3.84002 8.37961 3.55002C8.66961 3.26002 9.14961 3.26002 9.43961 3.55002L15.9596 10.07C16.4696 10.58 16.7596 11.27 16.7596 12C16.7596 12.73 16.4796 13.42 15.9596 13.93L9.43961 20.45C9.28961 20.59 9.09961 20.67 8.90961 20.67C8.71961 20.67 8.52961 20.6 8.37961 20.45Z"
      fill={fill}
    />
  </svg>
);

export const LeftIcon = ({ fill = '#fff' }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4693 20.45L7.9493 13.93C6.8893 12.87 6.8893 11.13 7.9493 10.07L14.4693 3.55002C14.7593 3.26002 15.2393 3.26002 15.5293 3.55002C15.8193 3.84002 15.8193 4.32002 15.5293 4.61002L9.0093 11.13C8.5293 11.61 8.5293 12.39 9.0093 12.87L15.5293 19.39C15.8193 19.68 15.8193 20.16 15.5293 20.45C15.3793 20.59 15.1893 20.67 14.9993 20.67C14.8093 20.67 14.6193 20.6 14.4693 20.45Z"
      fill={fill}
    />
  </svg>
);
