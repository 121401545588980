import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

type AppState = {
  sidebarVisible: boolean;
  sidebarOpen: boolean;
  MobileMenuOpen: boolean;
};

const initialState: AppState = {
  sidebarVisible: false,
  sidebarOpen: true,
  MobileMenuOpen: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarVisible = !state.sidebarVisible;
      return state;
    },
    setSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
      return state;
    },
    setMobileMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.MobileMenuOpen = action.payload;
      return state;
    },
  },
});

export const { toggleSidebar, setSidebarOpen, setMobileMenuOpen } = appSlice.actions;

export const appState = (state: RootState) => state.app;

export default appSlice.reducer;
