import { useEffect, useState } from 'react';
import { Avatar, Card, Col, Divider, Pagination, Row, Skeleton } from 'antd';
import { PrimaryButton, Text } from '../../../components';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_STORIES,
  LoggedInUser,
  Query,
  QueryGetStoriesArgs,
  Story,
  StoryStatus,
  StoryType,
} from '../../../graphql';
import { FeedItem, StoryFormModal } from './index';
import { colors, getInitials } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const Feed = () => {
  const navigate = useNavigate();
  const user = useReactiveVar(LoggedInUser);
  const [create, setCreate] = useState<boolean>(false);
  const [stories, setStories] = useState<Story[]>([]);
  const [page, setPage] = useState<number>(1);
  const { data, loading, refetch } = useQuery<Query, QueryGetStoriesArgs>(
    GET_STORIES,
    {
      variables: { status: StoryStatus.Published, page, take: 10 },
      fetchPolicy: 'no-cache',
      pollInterval: 600000,
      onCompleted: (data) => setStories(data.getStories.data),
    },
  );

  useEffect(() => {
    setStories(data?.getStories?.data ?? []);
  }, [data]);

  return (
    <Row
      className="home-wrap"
      style={{
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Col
        className="feed-wrap"
        style={{
          maxWidth: '700px',
          width:'100%',
        }}
      >
        <Row>
          <Col span={24}>
            <Text color={'black7'} fontSize={12} fontWeight={400}>
              Feed
            </Text>
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col
            span={24}
            className="create-story-wrap"
            style={{ height: 'fit-content' }}
          >
            <Card
              className={!!user?.id ? 'clickable' : ''}
              onClick={() => {
                if (!!user?.id) setCreate(true);
              }}
              bodyStyle={!user?.id ? { padding: 0 } : {}}
            >
              {!user?.id ? (
                <Row
                  style={{
                    position: 'absolute',
                    zIndex: 5,
                    width: '100%',
                    display: 'grid',
                    gap: 10,
                    top: '25%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text center variant={'baseMedium'}>
                    You must be signed in to create a post
                  </Text>
                  <div style={{ justifySelf: 'center' }}>
                    <PrimaryButton onClick={() => navigate('/login')}>
                      Sign in
                    </PrimaryButton>
                  </div>
                </Row>
              ) : (
                <></>
              )}
              <div
                style={!user?.id ? { filter: 'blur(2px)', padding: 20 } : {}}
              >
                <Row
                  className="row1"
                  justify={'space-between'}
                  align={'middle'}
                  gutter={10}
                  style={{ flexWrap: 'nowrap', margin: 0 }}
                >
                  <Avatar size={40} src={user?.profile?.picture}>
                    {!!user?.id
                      ? getInitials(user?.firstName, user?.lastName)
                      : ''}
                  </Avatar>
                  <Card
                    className={'card-no-padding'}
                    style={{
                      padding: 10,
                      background: colors.black1,
                      borderRadius: 16,
                      width: '90%',
                    }}
                  >
                    <Text color={'black5'}>Type a story...</Text>
                  </Card>
                </Row>
                <Divider style={{ margin: '16px 0' }} />
                <Row align={'middle'} style={{ gap: '10px' }}>
                  <Col>
                    <img
                      src={require('../../../assets/images/addMediaIcon.png')}
                      alt={''}
                      style={{ height: 24 }}
                    />
                  </Col>
                  <Col>
                    <Text color={'black5'}>Share Photo/Video</Text>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
          <Col span={24} className="FeedItem-wrap">
            {loading ? (
              <Skeleton active />
            ) : (
              <Row gutter={[0, 12]}>
                {!!stories.length ? (
                  stories.map((story) => (
                    <Col span={24}>
                      <FeedItem story={story} refetch={refetch} />
                    </Col>
                  ))
                ) : (
                  <Col
                    span={24}
                    style={{ textAlign: 'center', padding: '3em 2em' }}
                  >
                    <Text fontSize={16} fontWeight={500} color={'black7'}>
                      No stories found
                    </Text>
                  </Col>
                )}
              </Row>
            )}
          </Col>
        </Row>
        <Row justify={'end'} style={{ marginTop: 16 }}>
          <Pagination
            hideOnSinglePage
            pageSize={10}
            current={page}
            total={data?.getStories?.count}
            onChange={(page) => setPage(page)}
          />
        </Row>
      </Col>

      {create && (
        <StoryFormModal
          type={StoryType.Feed}
          refetch={refetch}
          handleClose={() => setCreate(false)}
        />
      )}
    </Row>
  );
};

export default Feed;
