import { useState } from 'react';
import { Avatar, Card, Col, Dropdown, MenuProps, Row } from 'antd';
import { colors, getInitials } from '../../../utils';
import {
  ErrorButton,
  OutlinedButton,
  PrimaryButton,
  Text,
} from '../../../components';
import { formatDistanceToNowStrict } from 'date-fns';
import {
  Mutation,
  Comment,
  MutationUpdateCommentArgs,
  MutationDeleteCommentArgs,
  UPDATE_COMMENT,
  LoggedInUser,
  DELETE_COMMENT,
} from '../../../graphql';
import { useMutation } from '@apollo/client';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';

const StoryCommentItem = ({
  comment,
  refetch,
}: {
  comment: Comment;
  refetch: () => void;
}) => {
  const user = LoggedInUser();
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [commentString, setCommentString] = useState<string>(
    comment.comment ?? '',
  );
  const [updateComment, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateCommentArgs
  >(UPDATE_COMMENT);
  const [deleteComment, { loading: deleting }] = useMutation<
    Mutation,
    MutationDeleteCommentArgs
  >(DELETE_COMMENT);

  const onClickEdit = () => {
    setOpen(false);
    setEdit(true);
  };

  const handleCloseEdit = () => {
    setEdit(false);
    setCommentString(comment.comment ?? '');
  };

  const handleEdit = () => {
    updateComment({
      variables: {
        commentId: comment.id,
        update: commentString,
      },
    }).then(() => {
      handleCloseEdit();
      refetch();
    });
  };

  const onClickDelete = () => {
    setOpen(false);
    setShowDelete(true);
  };

  const handleDelete = () => {
    deleteComment({
      variables: {
        commentId: comment.id,
      },
    }).then(() => {
      setShowDelete(false);
      refetch();
    });
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Edit comment',
      onClick: onClickEdit,
    },
    {
      key: 'delete',
      label: 'Delete comment',
      onClick: onClickDelete,
    },
  ];
  const navigate = useNavigate();
  const navigateToUser = () => navigate(`/user/${comment.user.id}`);

  return (
    <Col span={24}>
      <Row
        justify={'space-between'}
        align={'top'}
        gutter={10}
        style={{ flexWrap: 'nowrap', margin: '0', gap: '10px' }}
      >
        <div style={{ width: '42px!important' }}>
          <Avatar
            className="clickable"
            size={40}
            src={comment.user?.profile?.picture}
            onClick={navigateToUser}
          >
            {getInitials(comment.user.firstName, comment.user.lastName)}
          </Avatar>
        </div>
        <Card
          className={'card-no-padding'}
          style={{
            padding: 12,
            background: colors.black1,
            borderRadius: 16,
            width: '100%',
          }}
        >
          <Col span={24} style={{ padding: '0' }}>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <Row align={'middle'} gutter={6} justify={'start'}>
                  <Col>
                    <Text
                      className="clickable user-name"
                      fontWeight={600}
                      color={'black10'}
                      onClick={navigateToUser}
                    >
                      {`${comment.user.firstName} ${comment.user.lastName}`}
                    </Text>
                  </Col>
                  <Col>
                    <img
                      src={require('../../../assets/images/ellipse.png')}
                      alt={''}
                    />
                  </Col>
                  <Col>
                    <Text color={'black5'}>
                      {formatDistanceToNowStrict(new Date(comment.createdAt), {
                        addSuffix: true,
                      })}
                    </Text>
                  </Col>
                </Row>
              </Col>
              {comment.user.id === user?.id && !edit && !showDelete && (
                <Col>
                  <Dropdown
                    open={open}
                    menu={{ items }}
                    placement={'bottomRight'}
                    overlayClassName="comment-dropdown"
                  >
                    <img
                      onClick={() => setOpen(!open)}
                      className={'clickable'}
                      src={require('../../../assets/images/commentOptionsIcon.png')}
                      alt={''}
                      style={{ height: 22 }}
                    />
                  </Dropdown>
                </Col>
              )}
            </Row>
          </Col>
          <Col span={edit ? 24 : 0}>
            <TextArea
              value={commentString}
              onChange={(e) => setCommentString(e.target.value)}
              style={{ padding: 0, borderRadius: 0 }}
              autoSize={{ minRows: 2, maxRows: 4 }}
            />
          </Col>
          <Col span={edit ? 0 : 24} style={{ padding: '0' }}>
            <Text color={'black8'}>{comment.comment}</Text>
          </Col>
          <Col span={edit ? 24 : 0}>
            <Row justify={'end'} gutter={4}>
              <Col>
                <OutlinedButton
                  fontSize={14}
                  onClick={handleCloseEdit}
                  height={32}
                >
                  Cancel
                </OutlinedButton>
              </Col>
              <Col>
                <PrimaryButton
                  loading={updating}
                  disabled={!commentString}
                  fontSize={14}
                  onClick={handleEdit}
                  height={32}
                >
                  Save changes
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
          <Col span={showDelete ? 24 : 0}>
            <Row justify={'end'} gutter={4}>
              <Col>
                <OutlinedButton
                  fontSize={14}
                  onClick={() => setShowDelete(false)}
                  height={32}
                >
                  Cancel
                </OutlinedButton>
              </Col>
              <Col>
                <ErrorButton
                  fontSize={14}
                  loading={deleting}
                  onClick={handleDelete}
                  height={32}
                >
                  Delete comment
                </ErrorButton>
              </Col>
            </Row>
          </Col>
        </Card>
      </Row>
    </Col>
  );
};

export default StoryCommentItem;
