import { Avatar, Col, Divider, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Comment,
  Mutation,
  MutationAddCommentArgs,
  Story,
  ADD_COMMENT,
  LoggedInUser,
} from '../../../graphql';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { StoryCommentItem } from './index';
import { Text } from '../../../components';
import { getInitials } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const StoryComments = ({
  story,
  refetch,
}: {
  story: Story;
  refetch: () => void;
}) => {
  const user = LoggedInUser();
  const [addComment, { loading: commenting }] = useMutation<
    Mutation,
    MutationAddCommentArgs
  >(ADD_COMMENT);
  const [comment, setComment] = useState<string>('');
  const [comments, setComments] = useState<Comment[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (!showMore && story.comments.length > 3) {
      setComments(story.comments.slice(story.comments.length - 3));
    } else setComments(story.comments);
  }, [story]);

  const handleComment = () => {
    if (!!comment) {
      addComment({
        variables: {
          storyId: story.id,
          comment,
        },
      }).then(() => {
        refetch();
        setComment('');
      });
    }
  };

  const handleShowComments = () => {
    setShowMore(!showMore);
    if (showMore) {
      setComments(story.comments.slice(story.comments.length - 3));
    } else {
      setComments(story.comments);
    }
  };
  // const navigate = useNavigate();
  // const navigateToUser = () => navigate(`/user/${story.user.id}`);

  return (
    <div
      className="story-comments-wrap"
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        gap: '10px',
      }}
    >
      <Col
        span={story.comments.length > 3 ? 24 : 0}
        className={'clickable'}
        onClick={handleShowComments}
      >
        <Text color={'black5'}>
          {`Show ${showMore ? 'less' : 'more'} comments`}
        </Text>
      </Col>
      {comments.map((comment) => (
        <StoryCommentItem comment={comment} refetch={refetch} />
      ))}
      {!!story.comments.length && (
        <Divider style={{ marginBottom: '6px', marginTop: '0' }} />
      )}
      {!!user?.id && (
        <Col span={24} className="commentbox" style={{ marginBottom: '16px' }}>
          <Input
            placeholder={'Add a comment'}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            prefix={
              <Avatar
                size={40}
                style={{ marginRight: 6 }}
                src={user?.profile?.picture}
                className="comment-avatar"
              >
                {getInitials(user?.firstName, user?.lastName)}
              </Avatar>
            }
            suffix={
              commenting ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 22 }} spin />}
                />
              ) : (
                <img
                  className={'clickable'}
                  onClick={handleComment}
                  src={
                    !comment
                      ? require('../../../assets/images/addCommentIcon.png')
                      : require('../../../assets/images/addCommentIconFilled.png')
                  }
                  alt={''}
                  style={{ height: 24 }}
                />
              )
            }
          />
        </Col>
      )}
    </div>
  );
};

export default StoryComments;
