import React, { Dispatch, SetStateAction } from 'react';
import { Col, Row, Space } from 'antd';
import { colors } from '../utils';
import { Text } from './Text';

type StepProps = {
  index: number;
  current: number;
  setCurrent: Dispatch<SetStateAction<number>>;
  label: string;
};

export default function Step({ index, current, setCurrent, label }: StepProps) {
  return (
    <Col>
      <Row justify={'center'}>
        <Space
          className={index <= current ? 'clickable' : ''}
          onClick={() => {
            if (index <= current) setCurrent(index);
            else return;
          }}
          style={{
            height: 28,
            width: 28,
            borderRadius: '100%',
            backgroundColor: index <= current ? colors.primary : colors.blue1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            variant={'baseNormal'}
            style={{ maxWidth: '100%' }}
            color={index <= current ? 'white' : 'blue6'}
          >
            {index + 1}
          </Text>
        </Space>
      </Row>
      <Row style={{ marginTop: 8 }} justify={'center'}>
        <Text
          center
          color={'black8'}
          fontSize={12}
          fontWeight={500}
          lineHeight={16}
        >
          {label}
        </Text>
      </Row>
    </Col>
  );
}
