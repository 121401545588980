import { Card, Col, Row, Spin } from 'antd';
import {
  GET_STORIES_BY_USER,
  ProfileUser,
  Query,
  QueryGetStoriesByUserArgs,
} from '../../../graphql';
import { useQuery, useReactiveVar } from '@apollo/client';
import { FeedItem } from '../Feed';

export default function Posts() {
  const user = useReactiveVar(ProfileUser);
  const { data, loading, refetch } = useQuery<Query, QueryGetStoriesByUserArgs>(
    GET_STORIES_BY_USER,
    {
      variables: {
        userId: user?.id ?? '',
      },
    },
  );

  return loading ? (
    <Row style={{ margin: '3em' }} justify={'center'}>
      <Spin />
    </Row>
  ) : !!data?.getStoriesByUser?.data?.length ? (
    <Row gutter={[0, 16]}>
      {data?.getStoriesByUser?.data?.map((story) => (
        <Col span={24} key={story.id}>
          <FeedItem story={story} refetch={refetch} />
        </Col>
      ))}
    </Row>
  ) : (
    <></>
  );
}
