import { Avatar, Card, Col, Pagination, Row, Spin, Tabs, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  GET_EVENT_BY_ID,
  GET_EVENT_MEMBERS,
  GET_STORIES_BY_EVENT,
  LoggedInUser,
  Query,
  QueryGetEventByIdArgs,
  QueryGetEventMembersArgs,
  QueryGetStoriesByEventArgs,
  StoryType,
} from '../../../graphql';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Text, UserItem, Map } from '../../../components';
import { colors, getInitials } from '../../../utils';
import { CreateStoryInput, FeedItem } from '../Feed';
import { EventActions } from './index';
import { format, intervalToDuration, formatDuration, getDate } from 'date-fns';
import { useState } from 'react';

export default function SingleEvent() {
  const { id } = useParams();
  const user = useReactiveVar(LoggedInUser);
  const { md, xxl } = useBreakpoint();
  const [membersPage, setMembersPage] = useState<number>(1);
  const [feedPage, setFeedPage] = useState<number>(1);
  const {
    data,
    loading,
    refetch: refetchEvent,
  } = useQuery<Query, QueryGetEventByIdArgs>(GET_EVENT_BY_ID, {
    variables: { eventId: id as string, ...(user?.id && { userId: user.id }) },
  });
  const {
    data: membersData,
    loading: membersLoading,
    refetch: refetchMembers,
  } = useQuery<Query, QueryGetEventMembersArgs>(GET_EVENT_MEMBERS, {
    variables: { eventId: id as string, page: membersPage, take: 10 },
  });
  const {
    data: storiesData,
    loading: storiesLoading,
    refetch: refetchStories,
  } = useQuery<Query, QueryGetStoriesByEventArgs>(GET_STORIES_BY_EVENT, {
    variables: { eventId: id as string, page: membersPage, take: 10 },
  });

  const Location = () => (
    <Card bodyStyle={{ padding: 0 }} className={'card-no-padding'}>
      <Col span={24} style={{ padding: 0 }}>
        <Map
          center={{
            lat: data?.getEventById?.location.lat as number,
            lng: data?.getEventById?.location.long as number,
          }}
        />
      </Col>
      <Col span={24} style={{ padding: 16 }}>
        <Text variant={'lgStrong'}>{data?.getEventById?.location?.title}</Text>
      </Col>
    </Card>
  );

  const Spinner = () => (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  );

  const refetch = () => {
    refetchEvent();
    refetchStories();
    refetchMembers();
  };

  const tabs = [
    {
      key: 'info',
      label: 'Info',
      children: (
        <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
          <Col xs={24} lg={16} style={{ display: 'grid', gap: 16 }}>
            <Card bodyStyle={{ padding: 16 }} style={{ height: 'fit-content' }}>
              <Row>
                <Text variant={'lgStrong'}>Details</Text>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[0, 14]}>
                <Col span={24}>
                  <Row gutter={10}>
                    <Col>
                      <img
                        src={require('../../../assets/images/eventMembersIcon.png')}
                        alt={''}
                        style={{ height: 24, width: 24 }}
                      />
                    </Col>
                    <Col style={{ display: 'grid', gap: 10, flex: 1 }}>
                      <Text>
                        {data?.getEventById?.membersCount} people are going
                      </Text>
                      <Row>
                        <Col xs={24} md={10}>
                          {data?.getEventById.members?.map(
                            ({ id, user }, index) => (
                              <Tooltip
                                key={id}
                                title={`${user.firstName} ${user.lastName}`}
                              >
                                <Avatar
                                  size={40}
                                  src={user.profile?.picture}
                                  style={{
                                    border: '1px solid white',
                                    zIndex: 10,
                                    ...(index !== 0 && {
                                      zIndex: 10 - index,
                                      marginLeft: -8,
                                    }),
                                  }}
                                >
                                  {getInitials(user.firstName, user.lastName)}
                                </Avatar>
                              </Tooltip>
                            ),
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={10} align={'middle'}>
                    <Col>
                      <img
                        src={require('../../../assets/images/eventOwnerIcon.png')}
                        alt={''}
                        style={{ height: 24, width: 24 }}
                      />
                    </Col>
                    <Col>
                      <Text>
                        Event created by{' '}
                        <Text variant={'baseStrong'}>
                          {data?.getEventById?.owner?.firstName}{' '}
                          {data?.getEventById?.owner.lastName}
                        </Text>
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={10} align={'middle'}>
                    <Col>
                      <img
                        src={require('../../../assets/images/eventLocationIcon.png')}
                        alt={''}
                        style={{ height: 24, width: 24 }}
                      />
                    </Col>
                    <Col>
                      <Text>{data?.getEventById?.location?.title}, {data?.getEventById?.location?.description}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={10} align={'middle'}>
                    <Col>
                      <img
                        src={require('../../../assets/images/eventDurationIcon.png')}
                        alt={''}
                        style={{ height: 24, width: 24 }}
                      />
                    </Col>
                    <Col>
                      <Text>
                        Duration:{' '}
                        {data?.getEventById?.end
                          ? `${formatDuration(
                            intervalToDuration({
                              start: new Date(
                                Number(data?.getEventById?.start),
                              ),
                              end: new Date(Number(data?.getEventById?.end)),
                            }),
                            { format: ['days', 'hours'], delimiter: ', ' },
                          )}`
                          : '1 day'}
                      </Text>
                    </Col>
                  </Row>
                </Col>
                <Row style={{ marginTop: 24 }}>
                  <Text style={{ whiteSpace: 'break-spaces' }}>{data?.getEventById.description}</Text>
                </Row>
              </Row>
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Location />
          </Col>
        </Row>
      ),
    },
    {
      key: 'feed',
      label: 'Feed',
      children: (
        <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
          <Col xs={24} lg={16} style={{ display: 'grid', gap: 16 }}>
            <Row>
              <CreateStoryInput
                type={StoryType.Event}
                eventId={data?.getEventById.id}
                refetch={refetch}
              />
            </Row>
            {storiesLoading ? (
              <Spinner />
            ) : (
              <Row gutter={[0, 16]}>
                {storiesData?.getStoriesByEvent?.data?.map((story) => (
                  <Col span={24} key={story.id}>
                    <FeedItem story={story} refetch={refetch} />
                  </Col>
                ))}
                <Row justify={'end'} style={{ marginTop: 16 }}>
                  <Pagination
                    hideOnSinglePage
                    pageSize={10}
                    current={feedPage}
                    total={data?.getStoriesByEvent?.count}
                    onChange={(page) => setFeedPage(page)}
                  />
                </Row>
              </Row>
            )}
          </Col>
          <Col xs={24} lg={8}>
            <Location />
          </Col>
        </Row>
      ),
    },
    {
      key: 'members',
      label: 'Members',
      children: (
        <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
          <Col xs={24} lg={16}>
            {membersLoading ? (
              <Spinner />
            ) : (
              <Row gutter={[0, 12]}>
                {membersData?.getEventMembers?.data?.map(({ user }) => (
                  <Col span={24} key={user.id}>
                    <UserItem user={user} />
                  </Col>
                ))}
                <Row justify={'end'} style={{ marginTop: 16 }}>
                  <Pagination
                    hideOnSinglePage
                    pageSize={10}
                    current={membersPage}
                    total={data?.getEventMembers?.count}
                    onChange={(page) => setMembersPage(page)}
                  />
                </Row>
              </Row>
            )}
          </Col>
          <Col xs={24} lg={8}>
            <Location />
          </Col>
        </Row>
      ),
    },
  ];

  return loading ? (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  ) : !!data?.getEventById ? (
    <Row>
      <Col span={24}>
        <Card bodyStyle={{ padding: 0 }} className={'card-no-padding'}>
          <img
            src={data.getEventById.banner}
            alt={''}
            style={{
              width: '100%',
              borderRadius: 8,
              height: xxl ? 380 : md ? 280 : 180,
              objectFit: 'cover',
            }}
          />
          <Card
            bordered={false}
            bodyStyle={{ padding: 0 }}
            className={'card-no-padding'}
            style={{
              position: 'absolute',
              bottom: '-12px',
              left: 24,
              width: 'fit-content',
              minWidth: 80,
              boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.12)',
            }}
          >
            <Col
              span={24}
              style={{
                height: 25,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                backgroundColor: colors.blue6,
              }}
            />
            <Col
              span={24}
              style={{
                padding: `12px 28px`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text variant={'heading2'}>
                {getDate(new Date(Number(data?.getEventById.start)))}
              </Text>
            </Col>
          </Card>
        </Card>
      </Col>
      <Col span={24} style={{ marginTop: 32 }}>
        <Row justify={'space-between'} gutter={[16, 16]}>
          <Col
            xs={24}
            md={data.getEventById.owner.id === user?.id ? 24 : 10}
            lg={10}
          >
            <Row>
              <Text variant={'heading5'} color={'blue6'}>
                {!!data.getEventById?.start
                  ? `${format(
                    new Date(Number(data.getEventById?.start)),
                    'MMM, d \'at\' hh:mm a',
                  )}${
                    !!data.getEventById?.end
                      ? ` - ${format(
                        new Date(Number(data.getEventById?.end)),
                        'MMM, d \'at\' hh:mm a',
                      )}`
                      : ''
                  }`
                  : ''}
              </Text>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Text variant={'heading3'}>{data.getEventById.title}</Text>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Text color={'black4'}>{data.getEventById.location?.title}</Text>
            </Row>
          </Col>
          <Col
            xs={24}
            md={data.getEventById.owner.id === user?.id ? 24 : 14}
            lg={14}
          >
            <EventActions event={data.getEventById} refetch={refetch} />
          </Col>
        </Row>
        <Col span={24} style={{ marginTop: 16 }}>
          <Tabs items={tabs} />
        </Col>
      </Col>
    </Row>
  ) : (
    <></>
  );
}
