import { Col, ConfigProvider, Form, Input, Modal, Row, Upload } from 'antd';
import { EmptyUploadContainer, PrimaryButton, Text } from '../../../components';
import { useMutation } from '@apollo/client';
import { useModalWidth } from '../../../hooks';
import { RcFile, UploadProps } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import {
  CREATE_GROUP,
  CreateGroupInput,
  Group,
  Mutation,
  MutationCreateGroupArgs,
  MutationUpdateGroupArgs,
  MutationUploadFileArgs,
  UPDATE_GROUP,
  UPLOAD_FILE,
  uploadClient,
} from '../../../graphql';
import { colors, commonUploadProps, previewImage } from '../../../utils';

type Props = {
  group?: Group;
  onSuccess: () => void;
  handleClose: () => void;
};

type FieldType = {
  name: string;
  description: string;
  bannerImage: RcFile;
};

export default function GroupFormModal({
  group,
  onSuccess,
  handleClose,
}: Props) {
  const { width } = useModalWidth();
  const [preview, setPreview] = useState<any>();
  const [file, setFile] = useState<UploadFile>();
  const [createEvent, { loading: creating }] = useMutation<
    Mutation,
    MutationCreateGroupArgs
  >(CREATE_GROUP);
  const [updateEvent, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateGroupArgs
  >(UPDATE_GROUP);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });

  useEffect(() => {
    if (!!group) {
      if (!!group.banner) {
        setPreview(group.banner);
      }
    }
  }, [group]);

  const handleSave = (values: {
    name: string;
    description: string;
    banner?: string;
  }) => {
    if (!group) {
      createEvent({
        variables: {
          group: values as CreateGroupInput,
        },
      }).then(() => {
        onSuccess();
        handleClose();
      });
    } else {
      updateEvent({
        variables: {
          groupId: group.id,
          update: values,
        },
      }).then(() => {
        onSuccess();
        handleClose();
      });
    }
  };

  const onFinish = ({ name, description }: FieldType) => {
    if (!!file) {
      uploadFile({ variables: { file } }).then((res) => {
        const fileString = res.data?.uploadFile;
        handleSave({ name, description, banner: fileString });
      });
    } else {
      handleSave({ name, description });
    }
  };

  const uploadProps: UploadProps = {
    ...commonUploadProps,
    // @ts-ignore
    action: (file) => {
      setFile(file);
      previewImage(file, setPreview);
    },
  };

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      width={width}
      onCancel={handleClose}
    >
      <ConfigProvider
        theme={{
          components: {
            Input: { borderRadius: 8 },
            Form: { itemMarginBottom: 0 },
          },
        }}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Text variant={'heading3'} color={'black10'}>
              {`${!!group ? 'Edit' : 'Create'} group`}
            </Text>
          </Col>
          <Col span={24}>
            <Form
              layout={'vertical'}
              requiredMark={false}
              name="basic"
              style={{ width: '100%' }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={group}
            >
              <Row gutter={[0, 20]}>
                <Col span={24}>
                  <Form.Item<FieldType>
                    name={'name'}
                    label={'Group name'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input placeholder={'Enter a group name'} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item<FieldType>
                    name={'description'}
                    label={'Group description'}
                    rules={[{ required: true, message: '' }]}
                  >
                    <Input.TextArea
                      rows={5}
                      placeholder={'Enter a description'}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item<FieldType>
                    label="Group banner image. Image size: 1500px x 500px recommended."
                    name={'bannerImage'}
                    rules={[
                      {
                        required: !group,
                        message: 'Please add a banner image',
                      },
                    ]}
                  >
                    <Upload.Dragger
                      {...uploadProps}
                      style={{ backgroundColor: colors.black1 }}
                    >
                      {preview ? (
                        <img
                          src={preview}
                          alt={'banner'}
                          style={{ height: 100, objectFit: 'contain' }}
                        />
                      ) : (
                        <EmptyUploadContainer />
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ marginTop: 4 }}>
                  <PrimaryButton
                    htmlType={'submit'}
                    loading={uploading || creating || updating}
                    block
                  >
                    {!!group ? 'Save changes' : 'Create'}
                  </PrimaryButton>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </ConfigProvider>
    </Modal>
  );
}
