import { useState } from 'react';
import { Col, Card, Row, Divider, Avatar } from 'antd';
import { PrimaryButton, Text } from '../../../components';
import { useReactiveVar } from '@apollo/client';
import { LoggedInUser, StoryType } from '../../../graphql';
import { StoryFormModal } from './index';
import { colors, getInitials } from '../../../utils';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useNavigate } from 'react-router-dom';

type Props = {
  type: StoryType;
  groupId?: string;
  eventId?: string;
  refetch: () => void;
};

export default function CreateStoryInput({
  type,
  groupId,
  eventId,
  refetch,
}: Props) {
  const navigate = useNavigate();
  const { md } = useBreakpoint();
  const user = useReactiveVar(LoggedInUser);
  const [create, setCreate] = useState<boolean>(false);

  return (
    <Col
      span={24}
      className="create-story-wrap"
      style={{ height: 'fit-content' }}
    >
      <Card
        className={!!user?.id ? 'clickable' : ''}
        onClick={() => {
          if (!!user?.id) setCreate(true);
        }}
        bodyStyle={!user?.id ? { padding: 0 } : md ? {} : { padding: 16 }}
      >
        {!user?.id ? (
          <Row
            style={{
              position: 'absolute',
              zIndex: 5,
              width: '100%',
              display: 'grid',
              gap: 10,
              top: '25%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text center variant={'baseMedium'}>
              You must be signed in to create a post
            </Text>
            <div style={{ justifySelf: 'center' }}>
              <PrimaryButton onClick={() => navigate('/login')}>
                Sign in
              </PrimaryButton>
            </div>
          </Row>
        ) : (
          <></>
        )}
        <div style={!user?.id ? { filter: 'blur(2px)', padding: 20 } : {}}>
          <Row
            className="row1"
            justify={'space-between'}
            align={'middle'}
            gutter={10}
            style={{ flexWrap: 'nowrap', columnGap: 10, margin: 0 }}
          >
            <Avatar size={40} src={user?.profile?.picture}>
              {!!user?.id ? getInitials(user?.firstName, user?.lastName) : ''}
            </Avatar>
            <Card
              className={'card-no-padding'}
              style={{
                padding: 10,
                background: colors.black1,
                borderRadius: 16,
                width: '100%',
              }}
            >
              <Text color={'black5'}>Type a story...</Text>
            </Card>
          </Row>
          <Divider style={{ margin: '16px 0' }} />
          <Row align={'middle'} style={{ gap: '10px' }}>
            <Col>
              <img
                src={require('../../../assets/images/addMediaIcon.png')}
                alt={''}
                style={{ height: 24 }}
              />
            </Col>
            <Col>
              <Text color={'black5'}>Share Photo/Video</Text>
            </Col>
          </Row>
        </div>
      </Card>
      {create && (
        <StoryFormModal
          groupId={groupId}
          eventId={eventId}
          type={type}
          refetch={refetch}
          handleClose={() => setCreate(false)}
        />
      )}
    </Col>
  );
}
