import { Col, message, Modal, Row } from 'antd';
import { ErrorButton, OutlinedButton, Text } from '../../../components';
import { useMutation } from '@apollo/client';
import {
  DELETE_STORY,
  Mutation,
  MutationDeleteStoryArgs,
  Story,
} from '../../../graphql';

type Props = { story: Story; refetch: () => void; handleClose: () => void };

const DeleteStoryModal = ({ story, refetch, handleClose }: Props) => {
  const [deleteStory, { loading: deleting }] = useMutation<
    Mutation,
    MutationDeleteStoryArgs
  >(DELETE_STORY);

  const handleDelete = () => {
    deleteStory({
      variables: {
        storyId: story.id,
      },
    }).then(() => {
      message.success('Story deleted successfully!');
      refetch();
      handleClose();
    });
  };

  return (
    <Modal
      open
      centered
      closable={false}
      footer={null}
      title={null}
      onCancel={handleClose}
      style={{ textAlign: 'center' }}
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Text fontSize={24} fontWeight={600} color={'black10'}>
            Delete story
          </Text>
        </Col>
        <Col span={24}>
          <Text color={'black5'}>
            Are you sure you want to delete this story? You can’t undo this
            action.
          </Text>
        </Col>
        <Col span={24} style={{ marginTop: 6 }}>
          <Row gutter={16}>
            <Col span={12}>
              <OutlinedButton block onClick={handleClose}>
                Cancel
              </OutlinedButton>
            </Col>
            <Col span={12}>
              <ErrorButton block loading={deleting} onClick={handleDelete}>
                Delete
              </ErrorButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteStoryModal;
