import { Col, Form, FormInstance, Input, Row, Upload } from 'antd';
import { EmptyUploadContainer, Text } from '../../../components';
import { UploadProps } from 'antd/es/upload';
import { useEffect } from 'react';
import {
  BannerPreview,
  LoggedInUser,
  PicturePreview,
  ProfileToEdit,
  UserRole,
} from '../../../graphql';
import { colors, commonUploadProps, previewImage } from '../../../utils';
import { useReactiveVar } from '@apollo/client';
import { AdditionalFields as FieldType } from './EditProfileModal';

export default function EditAdditional({ form }: { form: FormInstance }) {
  const user = useReactiveVar(LoggedInUser);
  const profileToEdit = useReactiveVar(ProfileToEdit);
  const picturePreview = useReactiveVar(PicturePreview);
  const bannerPreview = useReactiveVar(BannerPreview);

  const pictureUploadProps: UploadProps = {
    ...commonUploadProps,
    // @ts-ignore
    action: (file) => {
      ProfileToEdit({ ...profileToEdit, picture: file });
      previewImage(file, PicturePreview);
    },
  };

  const bannerUploadProps: UploadProps = {
    ...commonUploadProps,
    // @ts-ignore
    action: (file) => {
      ProfileToEdit({ ...profileToEdit, banner: file });
      previewImage(file, BannerPreview);
    },
  };

  useEffect(() => {
    if (!!user) {
      if (!!user.profile?.picture) {
        PicturePreview(user.profile?.picture);
        form.setFieldValue('profilePicture', 'something');
      }
      if (!!user.profile?.banner) {
        BannerPreview(user.profile?.banner);
        form.setFieldValue('profileBanner', 'something');
      }
    }
  }, [user]);

  return (
    <Row
      gutter={[10, 20]}
      style={{ height: 450, overflowY: 'scroll' }}
      className={'scroll-container'}
    >
      <Col span={24}>
        <Form.Item<FieldType>
          label="Tell us a little about yourself"
          name="bio"
          rules={[{ required: true, message: '' }]}
        >
          <Input.TextArea placeholder="Type your answer here" rows={5} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item<FieldType>
          label="Select a profile picture - Max file upload size: 2 MB"
          name={'profilePicture'}
          rules={[
            {
              required: true,
              message: 'Please add a profile picture',
            },
          ]}
        >
          <Upload.Dragger
            {...pictureUploadProps}
            style={{ backgroundColor: colors.black1 }}
          >
            {picturePreview ? (
              <img
                src={picturePreview}
                alt={'profile picture'}
                style={{ height: 100, objectFit: 'contain' }}
              />
            ) : (
              <EmptyUploadContainer />
            )}
          </Upload.Dragger>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item<FieldType>
          label="Select a banner image - Max file upload size: 2 MB - Size: 1500px x 500px recommended."
          name={'profileBanner'}
          rules={[
            {
              required: true,
              message: 'Please add a banner image',
            },
          ]}
        >
          <Upload.Dragger
            {...bannerUploadProps}
            style={{ backgroundColor: colors.black1 }}
          >
            {bannerPreview ? (
              <img
                src={bannerPreview}
                alt={'banner image'}
                style={{ height: 100, objectFit: 'contain' }}
              />
            ) : (
              <EmptyUploadContainer />
            )}
          </Upload.Dragger>
        </Form.Item>
      </Col>
      <Col span={user?.role === UserRole.Ambassador ? 0 : 24}>
        <Form.Item<FieldType>
          label={
            <Text variant={'smNormal'}>
              Enter your website/portfolio URL{' '}
              <Text variant={'smNormal'} color={'black4'}>
                (optional)
              </Text>
            </Text>
          }
          name="websiteUrl"
        >
          <Input placeholder={'Enter website/portfolio URL'} />
        </Form.Item>
      </Col>
      <Col span={user?.role === UserRole.Ambassador ? 0 : 24}>
        <Form.Item<FieldType>
          label={
            <Text variant={'smNormal'}>
              What company do you work for?{' '}
              <Text variant={'smNormal'} color={'black4'}>
                (optional)
              </Text>
            </Text>
          }
          name="companyName"
        >
          <Input placeholder={'Enter a company name'} />
        </Form.Item>
      </Col>
      <Col span={user?.role === UserRole.Ambassador ? 0 : 24}>
        <Form.Item<FieldType>
          label={
            <Text variant={'smNormal'}>
              What is your job title?{' '}
              <Text variant={'smNormal'} color={'black4'}>
                (optional)
              </Text>
            </Text>
          }
          name="jobTitle"
        >
          <Input placeholder={'Enter job title'} />
        </Form.Item>
      </Col>
      <Col span={user?.role === UserRole.Ambassador ? 0 : 24}>
        <Row style={{ marginBottom: 4 }}>
          <Text variant={'smNormal'}>
            Add your social media URL’s{' '}
            <Text variant={'smNormal'} color={'black4'}>
              (optional)
            </Text>
          </Text>
        </Row>
        <Row gutter={[0, 6]}>
          <Col span={24}>
            <Row align={'middle'} style={{ columnGap: 12, flexWrap: 'nowrap' }}>
              <img
                src={require('../../../assets/images/facebookIcon.png')}
                alt={'facebook'}
                style={{ width: 36, height: 36 }}
              />
              <Form.Item<FieldType>
                name="facebookUrl"
                style={{ width: '100%' }}
              >
                <Input
                  placeholder={'Enter Facebook profile URL'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Row align={'middle'} style={{ columnGap: 12, flexWrap: 'nowrap' }}>
              <img
                src={require('../../../assets/images/instagramIcon.png')}
                alt={'instagram'}
                style={{ width: 36, height: 36 }}
              />
              <Form.Item<FieldType>
                name="instagramUrl"
                style={{ width: '100%' }}
              >
                <Input
                  placeholder={'Enter Instagram profile URL'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Row align={'middle'} style={{ columnGap: 12, flexWrap: 'nowrap' }}>
              <img
                src={require('../../../assets/images/xIcon.png')}
                alt={'x'}
                style={{ width: 36, height: 36 }}
              />
              <Form.Item<FieldType> name="xUrl" style={{ width: '100%' }}>
                <Input
                  placeholder={'Enter X profile URL'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Row align={'middle'} style={{ columnGap: 12, flexWrap: 'nowrap' }}>
              <img
                src={require('../../../assets/images/behanceIcon.png')}
                alt={'behance'}
                style={{ width: 36, height: 36 }}
              />
              <Form.Item<FieldType> name="behanceUrl" style={{ width: '100%' }}>
                <Input
                  placeholder={'Enter Behance profile URL'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Row align={'middle'} style={{ columnGap: 12, flexWrap: 'nowrap' }}>
              <img
                src={require('../../../assets/images/linkedInIcon.png')}
                alt={'linkedIn'}
                style={{ width: 36, height: 36 }}
              />
              <Form.Item<FieldType>
                name="linkedInUrl"
                style={{ width: '100%' }}
              >
                <Input
                  placeholder={'Enter LinkedIn profile URL'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Row align={'middle'} style={{ columnGap: 12, flexWrap: 'nowrap' }}>
              <img
                src={require('../../../assets/images/tiktokIcon.png')}
                alt={'tiktok'}
                style={{ width: 36, height: 36 }}
              />
              <Form.Item<FieldType> name="tiktokUrl" style={{ width: '100%' }}>
                <Input
                  placeholder={'Enter TikTok profile URL'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
          <Col span={24}>
            <Row align={'middle'} style={{ columnGap: 12, flexWrap: 'nowrap' }}>
              <img
                src={require('../../../assets/images/youtubeIcon.png')}
                alt={'youtube'}
                style={{ width: 36, height: 36 }}
              />
              <Form.Item<FieldType> name="youtubeUrl" style={{ width: '100%' }}>
                <Input
                  placeholder={'Enter YouTube profile URL'}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
