import {
  Button,
  Col,
  Form,
  FormInstance,
  Image,
  Input,
  Row,
  Upload,
} from 'antd';
import { EmptyUploadContainer, PrimaryButton, Text } from '../../../components';
import { useReactiveVar } from '@apollo/client';
import { RcFile, UploadProps } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import {
  KeepOldImages,
  ProfileToEdit,
  ProfileUser,
  VideoUrls,
} from '../../../graphql';
import {
  BeforeUpload,
  colors,
  commonUploadProps,
  previewImages,
  storytellerQuestionFields,
} from '../../../utils';
import { CloseOutlined } from '@ant-design/icons';
import { uniq } from 'lodash';
import { StorytellerFields as FieldType } from './EditProfileModal';
import { EditTemplate } from './index';

export default function EditStoryteller({ form }: { form: FormInstance }) {
  const user = useReactiveVar(ProfileUser);
  const profileToEdit = useReactiveVar(ProfileToEdit);
  const videoUrls = useReactiveVar(VideoUrls);
  const [imagesPreview, setImagesPreview] = useState<any[]>([]);

  useEffect(() => {
    if (!!user) {
      if (!!user.profile?.storyteller?.images) {
        setImagesPreview(user.profile?.storyteller.images);
      }
      if (!!user.profile?.storyteller?.videoUrls) {
        VideoUrls(user.profile?.storyteller.videoUrls);
      }
    }
  }, [user]);

  useEffect(() => {
    if (!!profileToEdit.storyteller?.images?.length) {
      const prevImages = imagesPreview;
      setImagesPreview([]);
      previewImages(profileToEdit.storyteller?.images, (res) => {
        setImagesPreview((prev) => uniq([...prevImages, ...prev, res]));
      });
    }
  }, [profileToEdit.storyteller?.images]);

  const imagesUploadProps: UploadProps = {
    ...commonUploadProps,
    multiple: true,
    // @ts-ignore
    action: () => {},
    disabled: imagesPreview?.length >= 10,
    beforeUpload: (_, files) => {
      let validFiles: RcFile[] = [];

      BeforeUpload(
        files,
        () => {},
        (valid) => {
          validFiles = valid;
        },
      );

      if (validFiles.length) {
        ProfileToEdit({
          ...profileToEdit,
          storyteller: {
            ...profileToEdit.storyteller,
            images: [
              ...((profileToEdit.storyteller?.images ?? []) as any[]),
              ...validFiles.slice(0, 10 - imagesPreview.length),
            ],
          },
        });
      }
    },
  };

  return (
    <Row
      gutter={[10, 20]}
      style={{ height: 450, overflowY: 'scroll' }}
      className={'scroll-container'}
    >
      <EditTemplate form={form} />
      {storytellerQuestionFields.map(({ title, subtitle, field }, index) => (
        <Col span={24} style={{ display: 'grid', gap: 4 }} key={field}>
          <Text variant={'lgStrong'}>{title}</Text>
          <Text variant={'smNormal'}>
            {subtitle}{' '}
            {index !== 9 && (
              <Text color={'black4'} variant={'smNormal'}>
                (optional)
              </Text>
            )}
          </Text>
          {index === 9 && (
            <Row gutter={12} align={'middle'} justify={'space-between'}>
              <Col style={{ marginTop: '12px' }}>
                <Text fontSize={12}>Upload up to 10 images.</Text>
                <Text fontSize={12} style={{ opacity: '0.6' }}>
                  {' '}
                  Max file upload size: 2 MB each
                </Text>
              </Col>
              {!!imagesPreview.length && (
                <Col>
                  <Button
                    type={'link'}
                    icon={<CloseOutlined />}
                    style={{
                      height: 18,
                      fontSize: 12,
                      padding: 0,
                      color: colors.blue6,
                    }}
                    onClick={() => {
                      setImagesPreview([]);
                      ProfileToEdit({
                        ...profileToEdit,
                        storyteller: { images: undefined },
                      });
                      KeepOldImages(false);
                    }}
                  >
                    Clear all
                  </Button>
                </Col>
              )}
            </Row>
          )}
          {index === 9 ? (
            <Form.Item<FieldType>
              name={'storytellerImages'}
              rules={[
                {
                  required: !imagesPreview?.length,
                  message: 'Please add images',
                },
              ]}
            >
              <Upload.Dragger
                {...imagesUploadProps}
                style={{ backgroundColor: colors.black1 }}
              >
                {!!imagesPreview.length ? (
                  <Row gutter={[6, 6]}>
                    {imagesPreview.map((img) => (
                      <Col span={6} key={img}>
                        <img
                          src={img}
                          alt={''}
                          style={{
                            width: '100%',
                            aspectRatio: 1,
                            borderRadius: 4,
                            objectFit: 'cover',
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <EmptyUploadContainer />
                )}
              </Upload.Dragger>
            </Form.Item>
          ) : (
            <Form.Item<FieldType> name={field}>
              <Input.TextArea placeholder="Enter your answer" rows={5} />
            </Form.Item>
          )}
        </Col>
      ))}
      <Col span={24}>
        <Row style={{ marginBottom: 2 }}>
          <Text variant={'smNormal'}>Enter a video url (YouTube or Vimeo)</Text>
        </Row>
        <Row gutter={[0, 6]}>
          {videoUrls?.map((videoUrl: string, index: number) => (
            <Col span={24}>
              <Row
                align={'middle'}
                style={{
                  columnGap: 12,
                  flexWrap: 'nowrap',
                }}
              >
                <Input
                  value={videoUrl}
                  onChange={(e) => {
                    VideoUrls(
                      videoUrls.map((url, idx) =>
                        idx === index ? e.target.value : url,
                      ),
                    );
                  }}
                  placeholder={'Enter video url'}
                  style={{ width: '100%' }}
                />
                <CloseOutlined
                  className={'clickable'}
                  onClick={() => {
                    const _videoUrls = videoUrls.slice();
                    _videoUrls.splice(index, 1);

                    VideoUrls(_videoUrls);
                  }}
                  style={{ color: colors.black3 }}
                />
              </Row>
            </Col>
          ))}
          <PrimaryButton
            style={{ marginBottom: '36px' }}
            fontSize={14}
            height={34}
            disabled={
              videoUrls?.[videoUrls?.length - 1] === '' ||
              videoUrls?.length === 5
            }
            onClick={() => {
              VideoUrls([...videoUrls, '']);
            }}
          >
            Add extra URL
          </PrimaryButton>
        </Row>
      </Col>
    </Row>
  );
}
