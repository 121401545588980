import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const useModalWidth = () => {
  const { md, lg, xl } = useBreakpoint();

  const getModalWidth = () => {
    if (xl) return '45%';
    if (lg) return '50%';
    if (md) return '80%';
    return '98%';
  };

  return {
    width: getModalWidth(),
  };
};

export default useModalWidth;
