import React from 'react';
import { ConfigProvider, Layout } from 'antd';
import { colors } from '../../utils';

type LayoutProps = {
  children: React.ReactNode;
};

const AuthLayout = (props: LayoutProps) => {
  return (
    <Layout
      className="NotLoggedInRoutes "
      style={{ minHeight: '100vh', background: colors.blue3 }}
    >
      <div className="bg-wrap">
        <img style={{width: '100%'}} src={require('../../assets/images/programmes_hero.png')} />
      </div>
      <div className="not-log-in-row">
        <ConfigProvider
          theme={{
            components: {
              Input: {
                borderRadius: 8,
              },
              Form: {
                itemMarginBottom: 0,
              },
            },
          }}
        >
          {props.children}
        </ConfigProvider>
      </div>
    </Layout>
  );
};

export default AuthLayout;
