import { Col, Form, Input, Row, Space } from 'antd';
import { PrimaryButton, Text } from '../../../components';
import { useMemo, useState } from 'react';
import { BuildProfile } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';
import { startCase } from 'lodash';
import {
  advocateQuestions,
  advocateQuestionFields as fields,
} from '../../../utils';

type FieldType = {
  localBeach: string;
  inspirations: string;
  coreValues: string;
  environmentalChange: string;
  contributions: string;
  criticalAdvocacyChange: string;
};

type FieldData = {
  title: string;
  subtitle: string;
  field: keyof FieldType;
};

type Props = {
  subStep: number;
};

export default function AdvocateQuestions({ subStep }: Props) {
  const buildProfile = useReactiveVar(BuildProfile);
  const [other, setOther] = useState<string>('');
  const [otherActivities, setOtherActivities] = useState<string[]>([]);

  const handleAdd = () => {
    const activityToAdd = startCase(other);

    BuildProfile({
      ...buildProfile,
      advocate: {
        ...buildProfile.advocate,
        activities: buildProfile.advocate?.activities
          ? [...buildProfile.advocate?.activities, activityToAdd]
          : [activityToAdd],
      },
    });
    setOtherActivities((prev) => [...prev, activityToAdd]);
    setOther('');
  };

  const handleSelect = (activity: string) => {
    if (buildProfile.advocate?.activities?.includes(activity)) {
      BuildProfile({
        ...buildProfile,
        advocate: {
          ...buildProfile.advocate,
          activities: buildProfile.advocate?.activities?.filter(
            (item) => item !== activity,
          ),
        },
      });
    } else {
      BuildProfile({
        ...buildProfile,
        advocate: {
          ...buildProfile.advocate,
          activities: buildProfile.advocate?.activities
            ? [...buildProfile.advocate?.activities, activity]
            : [activity],
        },
      });
    }
  };

  const activities = useMemo(
    () => [
      'Surfing',
      'Free-diving',
      'Scuba Diving',
      'Sea Swimming',
      'Kayak, Canoe',
      'Lifesaving',
      'Education',
      'Beach Cleanup',
      'Kite-surfing',
    ],
    [],
  );

  return subStep === 1 ? (
    <Col span={24} style={{ marginTop: 36, textAlign: 'left' }}>
      <Row justify={'start'}>
        <Text variant={'lgStrong'}>Local Beach</Text>
      </Row>
      <Col span={24} style={{ marginTop: 6 }}>
        <Form.Item<FieldType>
          label={
            <Text variant={'smNormal'}>
              {advocateQuestions.localBeach}{' '}
              <Text color={'black4'} variant={'smNormal'}>
                (optional)
              </Text>
            </Text>
          }
          name={'localBeach'}
        >
          <Input placeholder="Enter a beach location/name" />
        </Form.Item>
      </Col>
      <Row justify={'start'} style={{ marginTop: 36 }}>
        <Text variant={'lgStrong'}>Ocean Activities</Text>
      </Row>
      <Row justify={'start'} style={{ marginTop: 6 }}>
        <Text variant={'smNormal'}>
          What are you doing in or around the ocean? (Select all that apply){' '}
          <Text color={'black4'} variant={'smNormal'}>
            (optional)
          </Text>
        </Text>
      </Row>
      <Col span={24} style={{ marginTop: 6 }}>
        <Row gutter={[6, 6]}>
          {[...activities, ...otherActivities].map((activity) => (
            <Col span={8} key={activity}>
              <Space
                onClick={() => handleSelect(activity)}
                className={`activity-item${
                  buildProfile.advocate?.activities?.includes(activity)
                    ? '-selected'
                    : ''
                }`}
              >
                <Text className={'text'}>{activity}</Text>
              </Space>
            </Col>
          ))}
          <Col span={24}>
            <Row style={{ flexWrap: 'nowrap', gap: 6 }}>
              <Input
                placeholder={'Other'}
                value={other}
                onChange={(e) => setOther(e.target.value)}
              />
              <PrimaryButton disabled={!other.length} onClick={handleAdd}>
                Add
              </PrimaryButton>
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  ) : (
    <Col span={24} style={{ marginTop: 36, textAlign: 'left' }}>
      <Row justify={'start'}>
        <Text variant={'lgStrong'}>{fields[subStep - 2].title}</Text>
      </Row>
      <Row justify={'start'} style={{ marginTop: 6 }}>
        <Text variant={'smNormal'}>
          {fields[subStep - 2].subtitle}{' '}
          <Text color={'black4'} variant={'smNormal'}>
            (optional)
          </Text>
        </Text>
      </Row>
      <Col span={24} style={{ marginTop: 16 }}>
        <Form.Item<FieldType> name={fields[subStep - 2].field}>
          <Input.TextArea placeholder="Enter your answer" rows={5} />
        </Form.Item>
      </Col>
    </Col>
  );
}
