import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Upload } from 'antd';
import { EmptyUploadContainer, PrimaryButton, Text } from '../../../components';
import {
  BeforeUpload,
  colors,
  commonUploadProps,
  previewImages,
  storytellerQuestionFields,
} from '../../../utils';
import { RcFile, UploadProps } from 'antd/es/upload';
import { BuildProfile } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import { uniq } from 'lodash';

type FieldType = {
  background: string;
  memorableInfluences: string;
  professionalPathways: string;
  currentInspirations: string;
  ongoingProjects: string;
  impactfulChanges: string;
  influentialMedia: string;
  legacyAspirations: string;
  guidingAdvice: string;
};

type Props = {
  subStep: number;
};

export default function StorytellerQuestions({ subStep }: Props) {
  const buildProfile = useReactiveVar(BuildProfile);
  const [preview, setPreview] = useState<any[]>([]);

  useEffect(() => {
    if (buildProfile.storyteller?.images?.length) {
      setPreview([]);
      previewImages(buildProfile.storyteller.images, (res) => {
        setPreview((prev) => uniq([...prev, res]));
      });
    } else {
      setPreview([]);
    }
  }, [buildProfile.storyteller?.images]);

  useEffect(() => {
    if (!buildProfile.storyteller?.videoUrls?.length) {
      BuildProfile({
        ...buildProfile,
        storyteller: {
          ...buildProfile.storyteller,
          videoUrls: [''],
        },
      });
    }
  }, []);

  const previewImage = (files: any[]) => {
    files.forEach((file) => {
      var oFReader = new FileReader();
      oFReader.readAsDataURL(file);

      oFReader.onload = function (oFREvent) {
        setPreview((prev) => [...prev, oFREvent?.target?.result]);
      };
    });
  };

  const uploadProps: UploadProps = {
    ...commonUploadProps,
    multiple: true,
    // @ts-ignore
    action: () => {},
    disabled: (buildProfile.storyteller?.images ?? []).length >= 10,
    beforeUpload: (_, files) => {
      let validFiles: RcFile[] = [];

      BeforeUpload(
        files,
        () => {},
        (valid) => {
          validFiles = valid;
        },
      );

      if (validFiles.length) {
        BuildProfile({
          ...buildProfile,
          storyteller: {
            ...buildProfile.storyteller,
            images: [
              ...((buildProfile.storyteller?.images ?? []) as any[]),
              ...validFiles.slice(
                0,
                10 - (buildProfile.storyteller?.images?.length ?? 0),
              ),
            ],
          },
        });
      }
    },
  };

  return (
    <Col span={24} style={{ marginTop: 36, textAlign: 'left' }}>
      <Row justify={'start'}>
        <Text variant={'lgStrong'}>
          {storytellerQuestionFields[subStep - 1].title}
        </Text>
      </Row>
      <Row justify={'start'} style={{ marginTop: 6 }}>
        <Text variant={'smNormal'}>
          {storytellerQuestionFields[subStep - 1].subtitle}{' '}
          {subStep !== 10 && (
            <Text color={'black4'} variant={'smNormal'}>
              (optional)
            </Text>
          )}
        </Text>
      </Row>
      <Col span={subStep === 10 ? 0 : 24} style={{ marginTop: 16 }}>
        <Form.Item<FieldType>
          name={storytellerQuestionFields[subStep - 1].field}
        >
          <Input.TextArea placeholder="Enter your answer" rows={5} />
        </Form.Item>
      </Col>
      <Col span={subStep === 10 ? 24 : 0} style={{ marginTop: 16 }}>
        <Row gutter={12} align={'middle'} justify={'space-between'}>
          <Col>
            <Text fontSize={12}>
              Upload up to 10 images. (max file size: 2MB each)
            </Text>
          </Col>
          {!!buildProfile.storyteller?.images?.length && (
            <Col>
              <Button
                type={'link'}
                icon={<CloseOutlined />}
                style={{
                  height: 18,
                  fontSize: 12,
                  padding: 0,
                  color: colors.blue6,
                }}
                onClick={() => {
                  BuildProfile({
                    ...buildProfile,
                    storyteller: {
                      ...buildProfile.storyteller,
                      images: undefined,
                    },
                  });
                }}
              >
                Clear all
              </Button>
            </Col>
          )}
        </Row>
        <Form.Item<FieldType>>
          <Upload.Dragger
            {...uploadProps}
            style={{ backgroundColor: colors.black1 }}
          >
            {!!preview.length ? (
              <Row gutter={[6, 6]}>
                {preview.map((img) => (
                  <Col span={6} key={img}>
                    <img
                      src={img}
                      alt={''}
                      style={{
                        width: '100%',
                        aspectRatio: 1,
                        borderRadius: 4,
                        objectFit: 'cover',
                      }}
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <EmptyUploadContainer />
            )}
          </Upload.Dragger>
        </Form.Item>
      </Col>
      <Col span={subStep === 10 ? 24 : 0} style={{ marginTop: 20 }}>
        <Row style={{ marginBottom: 2 }}>
          <Text variant={'smNormal'}>Enter a video url (YouTube or Vimeo)</Text>
        </Row>
        <Row gutter={[0, 6]}>
          {buildProfile.storyteller?.videoUrls?.map((videoUrl, index) => (
            <Col span={24}>
              <Row
                align={'middle'}
                style={{ columnGap: 12, flexWrap: 'nowrap' }}
              >
                <Input
                  value={videoUrl}
                  onChange={(e) => {
                    const videoUrls = buildProfile.storyteller?.videoUrls ?? [];
                    videoUrls[index] = e.target.value;

                    BuildProfile({
                      ...buildProfile,
                      storyteller: {
                        ...buildProfile.storyteller,
                        videoUrls,
                      },
                    });
                  }}
                  placeholder={'Enter video url'}
                  style={{ width: '100%' }}
                />
                {/*@ts-ignore*/}
                <CloseOutlined
                  className={'clickable'}
                  onClick={() => {
                    const videoUrls = buildProfile.storyteller?.videoUrls ?? [];
                    videoUrls.splice(index, 1);

                    BuildProfile({
                      ...buildProfile,
                      storyteller: {
                        ...buildProfile.storyteller,
                        videoUrls,
                      },
                    });
                  }}
                  style={{ color: colors.black3 }}
                />
              </Row>
            </Col>
          ))}
          <PrimaryButton
            style={{ marginBottom: '36px' }}
            fontSize={14}
            height={34}
            disabled={
              buildProfile.storyteller?.videoUrls?.[
                buildProfile.storyteller?.videoUrls?.length - 1
              ] === '' || buildProfile.storyteller?.videoUrls?.length === 5
            }
            onClick={() => {
              BuildProfile({
                ...buildProfile,
                storyteller: {
                  ...buildProfile.storyteller,
                  videoUrls: [
                    ...(buildProfile.storyteller?.videoUrls as string[]),
                    '',
                  ],
                },
              });
            }}
          >
            Add extra URL
          </PrimaryButton>
        </Row>
      </Col>
    </Col>
  );
}
