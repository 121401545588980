import { Avatar, Card, Col, Divider, Row, Skeleton } from 'antd';
import { Text } from '../../../components';
import {
  GET_STORY_BY_ID,
  LIKE_STORY,
  LoggedInUser,
  Mutation,
  MutationLikeStoryArgs,
  Query,
  QueryGetStoryByIdArgs,
  Story,
} from '../../../graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { formatDistanceToNowStrict } from 'date-fns';
import {
  FeedItemOptions,
  ShareStoryModal,
  StoryComments,
  StoryLikesModal,
} from '../Feed';
import { useEffect, useState } from 'react';
import { getInitials, getVideoUrl } from '../../../utils';
import { useNavigate } from 'react-router-dom';

const StoryForm = () => {
  const user = LoggedInUser();
  const { id } = useParams();
  const [story, setStory] = useState<Story>();
  const { data, loading, refetch } = useQuery<Query, QueryGetStoryByIdArgs>(
    GET_STORY_BY_ID,
    { variables: { storyId: id ?? '' } },
  );
  const [likeStory] = useMutation<Mutation, MutationLikeStoryArgs>(LIKE_STORY);
  const [share, setShare] = useState<boolean>(false);
  const [showLikes, setShowLikes] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(false);

  useEffect(() => {
    if (!!story)
      setLiked(story.likes?.some((like) => like.user.id === user?.id));
  }, [user, story]);

  const handleLike = () => {
    const prevLiked = liked;
    setLiked(!prevLiked);

    likeStory({
      variables: {
        storyId: story?.id ?? '',
        like: !liked,
      },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        setLiked(prevLiked);
      });
  };

  useEffect(() => {
    if (data?.getStoryById) {
      setStory(data.getStoryById);
    }
  }, [data]);

  const navigate = useNavigate();
  const navigateToUser = () => navigate(`/user/${story?.user.id}`);

  return (
    <Row className="single-story">
      <Col span={2} className="spacer" />
      <Col span={20} className="single-story-content">
        {!story || loading ? (
          <Skeleton />
        ) : (
          <Row gutter={10} className="single-story-content">
            <Col span={16} className="single-story-content">
              <Card
                className={'card-no-padding'}
                style={{
                  padding: '24px 24px 10px 24px',
                }}
              >
                <Row
                  align={'middle'}
                  justify={'space-between'}
                  gutter={[0, 24]}
                >
                  <Col
                    span={!!story?.image || !!story?.video ? 24 : 0}
                    style={{ textAlign: 'center' }}
                  >
                    {!!story?.image && (
                      <img
                        src={story?.image}
                        alt={''}
                        style={{
                          width: '100%',
                          aspectRatio: 5 / 4,
                          borderRadius: 16,
                          objectFit: 'cover',
                        }}
                      />
                    )}
                    {!!story?.video && (
                      <video
                        src={getVideoUrl(story?.video)}
                        preload={'metadata'}
                        style={{ width: '100%', borderRadius: 16 }}
                        controls
                      />
                    )}
                  </Col>
                  <Col>
                    <Row
                      className="user-info clickable"
                      align={'middle'}
                      gutter={10}
                    >
                      <Col>
                        <Avatar size={40} src={story?.user?.profile?.picture}>
                          {getInitials(
                            story?.user?.firstName,
                            story?.user?.lastName,
                          )}
                        </Avatar>
                      </Col>
                      <Col>
                        <Row
                          align={'middle'}
                          gutter={6}
                          justify={'space-between'}
                        >
                          <Col>
                            <Text
                              fontWeight={600}
                              color={'black10'}
                              className="clickable user-name"
                              onClick={navigateToUser}
                            >
                              {`${story?.user.firstName} ${story?.user.lastName}`}
                            </Text>
                          </Col>
                          <Col>
                            <img
                              src={require('../../../assets/images/ellipse.png')}
                              alt={''}
                            />
                          </Col>
                          <Col>
                            <Text color={'black5'}>
                              {formatDistanceToNowStrict(
                                new Date(story?.createdAt ?? ''),
                                {
                                  addSuffix: true,
                                },
                              )}
                            </Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {story?.user.id === user?.id && (
                    <Col>
                      <FeedItemOptions story={story} refetch={refetch} />
                    </Col>
                  )}
                </Row>
                <Row gutter={[0, 8]} style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Text fontSize={14}>{story?.content}</Text>
                  </Col>
                  <Divider style={{ margin: '5px 0' }} />
                  <Col span={24}>
                    <Row align={'middle'} justify={'space-between'}>
                      <Col>
                        <Row align={'middle'} gutter={20}>
                          <Col>
                            <Row align={'middle'} gutter={6}>
                              <Col className={'clickable'} onClick={handleLike}>
                                <img
                                  src={
                                    liked
                                      ? require('../../../assets/images/likedIcon.png')
                                      : require('../../../assets/images/likeIcon.png')
                                  }
                                  alt={''}
                                  style={{ height: 24 }}
                                />
                              </Col>
                              <Col
                                className={'clickable'}
                                onClick={() => setShowLikes(true)}
                              >
                                <Text color={'black5'}>
                                  {`${story.likes?.length ?? '0'} Like${
                                    story.likes?.length === 1 ? '' : 's'
                                  }`}
                                </Text>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row align={'middle'} gutter={6}>
                              <Col>
                                <img
                                  src={require('../../../assets/images/commentIcon.png')}
                                  alt={''}
                                  style={{ height: 24 }}
                                />
                              </Col>
                              <Col>
                                <Text color={'black5'}>
                                  {`${story.comments?.length ?? '0'} Comment${
                                    story.comments?.length === 1 ? '' : 's'
                                  }`}
                                </Text>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        className={'clickable'}
                        onClick={() => setShare(true)}
                      >
                        <Row align={'middle'} gutter={6}>
                          <Col>
                            <img
                              src={require('../../../assets/images/shareIcon.png')}
                              alt={''}
                              style={{ height: 24 }}
                            />
                          </Col>
                          <Col>
                            <Text color={'black5'}>Share</Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Divider style={{ margin: '5px 0' }} />
                  <StoryComments story={story} refetch={refetch} />
                </Row>
              </Card>
            </Col>
            {/* <Col span={8}>
              <Card
                style={{
                  filter: 'blur(1px)',
                  height: '50vh',
                }}
              />
              <Text
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
                color={'red6'}
                fontSize={18}
                fontWeight={600}
              >
                Coming soon
              </Text>
            </Col> */}
            {showLikes && (
              <StoryLikesModal
                story={story}
                handleClose={() => setShowLikes(false)}
              />
            )}
            {share && (
              <ShareStoryModal
                storyId={story.id}
                handleClose={() => setShare(false)}
              />
            )}
          </Row>
        )}
      </Col>
      <Col span={2} className="spacer" />
    </Row>
  );
};

export default StoryForm;
