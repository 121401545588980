import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Radio, Row, Spin } from 'antd';
import { PrimaryButton, Text } from '../../../components';
import {
  CREATE_STORY,
  GET_STORY_BY_ID,
  Mutation,
  MutationCreateStoryArgs,
  MutationUpdateStoryArgs,
  MutationUploadFileArgs,
  Query,
  QueryGetStoryByIdArgs,
  StoryType,
  UPDATE_STORY,
  UPLOAD_FILE,
  uploadClient,
} from '../../../graphql';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { useLazyQuery, useMutation } from '@apollo/client';
import Dragger from 'antd/es/upload/Dragger';
import { UploadProps } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { FolderAddOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import { commonUploadProps, getVideoUrl } from '../../../utils';

type FieldType = {
  content: string;
  file: UploadFile;
};

type MediaType = 'image' | 'video';

const StoryForm = ({ forEdit }: { forEdit?: boolean }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [image, setImage] = useState<string>('');
  const [video, setVideo] = useState<string>('');
  const [file, setFile] = useState<UploadFile>();
  const [mediaType, setMediaType] = useState<MediaType>('image');
  const [createStory, { loading: creating }] = useMutation<
    Mutation,
    MutationCreateStoryArgs
  >(CREATE_STORY);
  const [updateStory, { loading: updating }] = useMutation<
    Mutation,
    MutationUpdateStoryArgs
  >(UPDATE_STORY);
  const [uploadFile, { loading: uploading }] = useMutation<
    Mutation,
    MutationUploadFileArgs
  >(UPLOAD_FILE, { client: uploadClient });
  const [getStoryById, { loading: loadingStory }] = useLazyQuery<
    Query,
    QueryGetStoryByIdArgs
  >(GET_STORY_BY_ID, {
    variables: { storyId: id ?? '' },
    onCompleted: (data) => {
      form.setFieldsValue({
        description: data.getStoryById.content,
      });
      setImage(data.getStoryById.image ?? '');
      setVideo(data.getStoryById.video ?? '');
    },
  });
  const [form] = Form.useForm();

  const uploadProps: UploadProps = {
    ...commonUploadProps,
    accept: mediaType === 'image' ? 'image/*' : 'video/*',
    // @ts-ignore
    action: (file) => {
      setFile(file);
    },
  };

  const loading = useMemo(
    () => creating || updating || uploading,
    [creating, updating, uploading],
  );

  const getLabel = (label: string) => (
    <Text fontWeight={600} fontSize={16}>
      {label}
    </Text>
  );

  useEffect(() => {
    if (!!id) getStoryById();
  }, [id]);

  const handleSave = (
    values: FieldType & { image?: string; video?: string },
  ) => {
    if (forEdit) {
      updateStory({
        variables: {
          storyId: id ?? '',
          update: values,
        },
      }).then(() => {
        navigate(-1);
      });
    } else {
      createStory({
        variables: {
          story: { ...values, type: StoryType.Feed },
        },
      }).then(() => {
        navigate(-1);
      });
    }
  };

  const onFinish = (values: FieldType) => {
    if (!!file) {
      uploadFile({ variables: { file } }).then((res) => {
        const fileString = res.data?.uploadFile;
        handleSave({ ...values, [mediaType]: fileString });
      });
    } else {
      handleSave(values);
    }
  };

  return (
    <Row justify={'center'} gutter={[0, 16]}>
      <Col span={24}>
        <Text fontWeight={700} fontSize={24}>
          {forEdit ? 'Edit' : 'Create'} story
        </Text>
      </Col>
      <Col span={24}>
        {forEdit && loadingStory ? (
          <Row justify={'center'} style={{ margin: '5em 2em' }}>
            <Spin size={'large'} />
          </Row>
        ) : (
          <Form
            form={form}
            layout={'vertical'}
            onFinish={onFinish}
            autoComplete={'off'}
            disabled={loading}
            className={loading ? 'disabled' : ''}
          >
            <Row style={{ textAlign: 'center' }} gutter={[0, 12]}>
              <Col span={24} style={{ textAlign: 'left' }}>
                <Radio.Group
                  onChange={(e) => setMediaType(e.target.value)}
                  value={mediaType}
                >
                  <Radio value={'image'}>Image</Radio>
                  <Radio value={'video'}>Video</Radio>
                </Radio.Group>
              </Col>
              <Col span={24}>
                <Dragger
                  {...uploadProps}
                  style={{
                    borderRadius: 22,
                    backgroundColor: !!file ? 'lightcyan' : 'aliceblue',
                  }}
                >
                  <Row gutter={[0, 8]} style={{ padding: '0.5em' }}>
                    <Col span={24}>
                      {mediaType === 'image' ? (
                        forEdit && !!image ? (
                          <img
                            src={image}
                            alt={''}
                            style={{ height: 200, borderRadius: 8 }}
                          />
                        ) : (
                          // @ts-ignore
                          <FolderAddOutlined style={{ fontSize: 42 }} />
                        )
                      ) : forEdit && !!video ? (
                        <video
                          src={getVideoUrl(video)}
                          preload={'metadata'}
                          style={{ height: 200, borderRadius: 8 }}
                        />
                      ) : (
                        // @ts-ignore
                        <VideoCameraAddOutlined style={{ fontSize: 42 }} />
                      )}
                    </Col>
                    <Col span={24}>
                      <Text>Click or drag file here to upload</Text>
                    </Col>
                  </Row>
                </Dragger>
              </Col>
              <Col span={24}>
                <Form.Item<FieldType>
                  name={'content'}
                  label={getLabel('Content')}
                >
                  <TextArea rows={6} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <PrimaryButton
                  loading={loading}
                  style={{ float: 'right', width: '20%' }}
                  htmlType={'submit'}
                >
                  Submit
                </PrimaryButton>
              </Col>
            </Row>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default StoryForm;
