import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';
import 'antd/dist/reset.css';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import './assets/scss/App.scss';
import { Main } from './pages';
import { breakpoints, colors } from './utils';
import { GqClient } from './graphql';

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<Main />} />),
);

function App() {
  const { primary50, success, warning, error } = colors;
  const { screenXS, screenSM, screenMD, screenLG, screenXL, screenXXL } =
    breakpoints;

  return (
    <div className="App">
      <ApolloProvider client={GqClient}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Poppins',
              colorPrimary: primary50,
              colorText: 'black',
              colorSuccess: success,
              colorError: error,
              colorWarning: warning,
              colorTextDisabled: colors.black6,
              screenXS,
              screenXSMin: screenXS,
              screenXSMax: screenSM - 1,
              screenSM,
              screenSMMin: screenSM,
              screenSMMax: screenMD - 1,
              screenMD,
              screenMDMin: screenMD,
              screenMDMax: screenLG - 1,
              screenLG,
              screenLGMin: screenLG,
              screenLGMax: screenXL - 1,
              screenXL,
              screenXLMin: screenXL,
              screenXLMax: screenXXL - 1,
              screenXXL,
              screenXXLMin: screenXXL,
            },
            components: {
              Card: {
                colorBgContainer: colors.white,
                colorBorder: colors.black1,
                colorBorderSecondary: colors.black1,
                borderRadius: 12,
                borderRadiusLG: 12,
                paddingLG: 24,
              },
              Carousel: {
                dotWidth: 4,
                dotHeight: 4,
                dotActiveWidth: 4,
              },
              DatePicker: {
                colorBgContainer: colors.black1,
                colorTextLightSolid: colors.black10,
                colorBorder: colors.black1,
                colorTextPlaceholder: colors.black5,
                colorText: colors.black10,
                borderRadius: 8,
                colorIcon: colors.black5,
                colorIconHover: colors.black7,
                colorTextDisabled: colors.black3,
                controlHeight: 42,
                fontSize: 14,
                padding: 10,
                //@ts-ignore
                activeBg: colors.black1,
                hoverBg: colors.black1,
                activeShadow: 'none',
                colorPrimaryHover: colors.black1,
                colorPrimaryActive: colors.black1,
                colorPrimaryBorder: colors.black1,
                colorPrimary: colors.black1,
              },
              Select: {
                colorBgContainer: colors.black1,
                colorBorder: colors.black1,
                colorTextPlaceholder: colors.black5,
                colorText: colors.black10,
                borderRadius: 8,
                colorIcon: colors.black5,
                colorIconHover: colors.black7,
                controlHeight: 42,
                fontSize: 14,
                padding: 10,
                //@ts-ignore
                activeBg: colors.black1,
                hoverBg: colors.black1,
                activeShadow: 'none',
                colorPrimaryHover: colors.black1,
                colorPrimaryActive: colors.black1,
                colorPrimaryBorder: colors.black1,
                colorPrimary: colors.black1,
              },
              Input: {
                colorBgContainer: colors.black1,
                colorBorder: colors.black1,
                colorTextPlaceholder: colors.black5,
                colorText: colors.black10,
                borderRadius: 12,
                activeBorderColor: colors.black7,
                hoverBorderColor: colors.black7,
                colorPrimaryBorderHover: colors.primary3,
                colorIcon: colors.black5,
                colorIconHover: colors.black7,
                controlHeight: 42,
                fontSize: 14,
                padding: 10,
                paddingXL: 10,
                //@ts-ignore
                activeBg: colors.black1,
                hoverBg: colors.black1,
                activeShadow: 'none',
                colorPrimaryHover: colors.black1,
                colorPrimaryActive: colors.black1,
                colorPrimaryBorder: colors.black1,
                colorPrimary: colors.black1,
              },
              InputNumber: {
                activeBorderColor: colors.primary,
                hoverBorderColor: colors.primary,
                colorBgContainer: 'transparent',
                colorBorder: colors.black5,
                colorText: colors.black,
                fontSize: 16,
                paddingBlock: 11,
              },
              Menu: {
                itemSelectedBg: colors.primary,
                itemSelectedColor: colors.white,
                fontSize: 14,
                fontFamily: 'Poppins',
                iconSize: 24,
                borderRadius: 4,
                itemBorderRadius: 4,
                iconMarginInlineEnd: 8,
                itemMarginInline: 0,
              },
              Form: {
                itemMarginBottom: 12,
                labelFontSize: 12,
                verticalLabelPadding: '2px 0',
                verticalLabelMargin: 0,
              },
              Alert: {
                colorErrorBg: '#2A1215',
                colorErrorBorder: '#58181C',
                colorIcon: '#6E6E6E',
              },
              Checkbox: {
                colorBorder: colors.borderColor,
                colorPrimary: colors.blue6,
                colorPrimaryHover: colors.blue5,
                colorBgContainer: 'transparent',
                colorIcon: colors.white,
                colorWhite: colors.white,
              },
              Segmented: {
                borderRadius: 4,
                itemSelectedBg: 'transparent',
                colorBgLayout: colors.background,
                itemSelectedColor: colors.primary,
                itemColor: 'white',
                motionDurationMid: '0s',
                motionDurationSlow: '0.1s',
                controlPaddingHorizontal: 8,
                colorBorder: 'red',
              },
              Modal: {
                contentBg: colors.white,
                headerBg: colors.white,
                colorBorder: colors.white,
                colorPrimaryBorder: 'white',
                borderRadius: 12,
                borderRadiusLG: 12,
                titleFontSize: 24,
                colorIcon: colors.black3,
                padding: 24,
                paddingContentHorizontalLG: 24,
                paddingLG: 24,
                paddingMD: 24,
                paddingXS: 24,
                colorText: 'white',
                fontWeightStrong: 400,
              },
              Spin: {
                colorPrimary: colors.primary,
              },
              Radio: {
                colorBgContainer: 'transparent',
                buttonCheckedBg: colors.white,
                buttonSolidCheckedColor: 'black',
                buttonSolidCheckedBg: colors.white,
                buttonSolidCheckedHoverBg: colors.white,
                colorBorder: colors.borderColor,
                colorTextDisabled: colors.black5,
                controlHeight: 48,
                fontSize: 14,
                colorPrimary: colors.borderColor,
              },
              Upload: {
                colorBorder: colors.borderColor,
              },
              Avatar: {
                colorTextPlaceholder: colors.black5,
              },
              Divider: {
                colorSplit: colors.black1,
                verticalMarginInline: 4,
              },
              Table: {
                headerBg: colors.darkBackground,
                rowHoverBg: colors.darkBackground,
                rowSelectedBg: colors.primary1,
                rowSelectedHoverBg: colors.primary1,
                headerBorderRadius: 0,
                borderColor: colors.black4,
                headerSplitColor: colors.black4,
                bodySortBg: colors.darkBackground,
                headerSortActiveBg: colors.darkBackground,
                colorBgContainer: colors.darkBackground,
                controlItemBgHover: colors.primary1,
                headerColor: colors.black6,
              },
              Pagination: {
                colorPrimary: colors.blue6,
                colorPrimaryHover: colors.blue5,
                colorBgTextHover: colors.blue1,
              },
              Tag: {
                defaultBg: colors.black4,
                defaultColor: 'white',
                colorBorder: colors.black5,
              },
              Tabs: {
                cardBg: 'transparent',
                itemSelectedColor: colors.primary,
                inkBarColor: colors.primary,
                colorBgContainer: colors.black2,
                lineWidth: 1,
                colorPrimaryHover: colors.black10,
                colorPrimaryActive: colors.black10,
                margin: 0,
              },
              Tooltip: {
                colorBgSpotlight: colors.black4,
              },
            },
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
