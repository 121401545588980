export default function CloseSidebar() {
  return (
    <svg
      className="is_open"
      style={{ height: '26px', width: '26px' }}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H18C22.4183 0 26 3.58172 26 8V18C26 22.4183 22.4183 26 18 26H8C3.58172 26 0 22.4183 0 18V8Z"
        fill="#F2F2F5"
      />
      <path
        d="M14.6462 18.6333L10.2995 14.2867C9.59286 13.58 9.59286 12.42 10.2995 11.7133L14.6462 7.36668C14.8395 7.17335 15.1595 7.17335 15.3529 7.36668C15.5462 7.56001 15.5462 7.88001 15.3529 8.07335L11.0062 12.42C10.6862 12.74 10.6862 13.26 11.0062 13.58L15.3529 17.9267C15.5462 18.12 15.5462 18.44 15.3529 18.6333C15.2529 18.7267 15.1262 18.78 14.9995 18.78C14.8729 18.78 14.7462 18.7333 14.6462 18.6333Z"
        fill="#112A34"
      />
    </svg>
  );
}
