import { useEffect, useState } from 'react';
import { Card, Layout } from 'antd';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { colors } from '../../utils';
import {
  Event,
  Events,
  Feed,
  Group,
  Groups,
  Home,
  OclMembers,
  Profile,
  Story,
  StoryForm,
} from '../dashboard';
import {
  ForgotPassword,
  Login,
  ResetPassword,
  SignUp,
  VerifyEmail,
  VerifyResetPassword,
} from '../auth';
import { useDispatch, useSelector } from 'react-redux';
import { appState, logout, userState } from '../../redux/reducers';
import { Header, Sidebar } from '../../components';
import {
  GET_USER,
  LoggedInUser,
  Query,
  UserRole,
  UserStatus,
} from '../../graphql';
import { useQuery, useReactiveVar } from '@apollo/client';
import '../../assets/scss/Main.scss';
import '../../assets/scss/NotLoggedInRoutes.scss';
import {
  BlockedRejectedNotice,
  BuildProfile,
  SubmittedNotice,
} from '../questionnaire';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Content } = Layout;

const commonRoutes = [
  <Route key={'/'} path={'/'} element={<Home />} />,
  <Route key={'/feed'} path={'/feed'} element={<Feed />} />,
  <Route key={'/members'} path={'/members'} element={<OclMembers />} />,
  <Route key={'/groups'} path={'/groups'} element={<Groups />} />,
  <Route key={'/groups/:id'} path={'/groups/:id'} element={<Group />} />,
  <Route key={'/events'} path={'/events'} element={<Events />} />,
  <Route key={'/events/:id'} path={'/events/:id'} element={<Event />} />,
  <Route key={'/user/:id'} path={'/user/:id'} element={<Profile />} />,
  <Route key={'/stories/:id'} path={'/stories/:id'} element={<Story />} />,
  <Route
    key={'/stories/create'}
    path={'/stories/create'}
    element={<StoryForm />}
  />,
  <Route
    key={'/stories/:id/edit/'}
    path={'/stories/:id/edit/'}
    element={<StoryForm forEdit />}
  />,
  <Route key={'*'} path={'*'} element={<Home />} />,
];

const loggedInRoutes = [
  <Route key={'/profile'} path={'/profile'} element={<Profile />} />,
];

const notLoggedInRoutes = [
  <Route key={'/login'} path={'/login'} element={<Login />} />,
  <Route key={'/register'} path={'/register'} element={<SignUp />} />,
  <Route key={'/verify/:id'} path={'/verify/:id'} element={<VerifyEmail />} />,
  <Route
    key={'/forgot-password'}
    path={'/forgot-password'}
    element={<ForgotPassword />}
  />,
  <Route
    key={'/reset-password'}
    path={'/reset-password'}
    element={<ResetPassword />}
  />,
  <Route
    key={'/reset-password/:id'}
    path={'/reset-password/:id'}
    element={<VerifyResetPassword />}
  />,
];

const Main = () => {
  const { token } = useSelector(userState);

  return (
    <Routes>
      {notLoggedInRoutes.map((route) => route)}
      <Route
        key={'*'}
        path={'*'}
        element={
          <LoggedInRoutes>
            {token ? loggedInRoutes.map((route) => route) : undefined}
          </LoggedInRoutes>
        }
      />
    </Routes>
  );
};

const LoggedInRoutes = ({ children }: { children?: any[] }) => {
  const { token } = useSelector(userState);
  const navigate = useNavigate();
  const { sidebarOpen } = useSelector(appState);
  const dispatch = useDispatch();
  const { data } = useQuery<Query>(GET_USER, {
    skip: !token,
    fetchPolicy: 'network-only',
    // onError: () => {
    //   dispatch(logout());
    //   navigate('/');
    // },
  });
  const user = useReactiveVar(LoggedInUser);

  useEffect(() => {
    if (!!data?.getUser) LoggedInUser(data.getUser);
  }, [data]);

  useEffect(() => {
    if (location.pathname.includes('verify')) {
      dispatch(logout());
    }
  }, [location.pathname]);

  // ------------GetBreakpointWidth_Logic-------------
  const { sm, md, lg } = useBreakpoint();
  const getBreakpointWidth = () => {
    if (sm) {
      // screenSize > 960
      if (sidebarOpen) {
        return 270 + 12 + 12 + 12;
      } else {
        return 72 + 12;
      }
    }
    return 0;
  };

  const [BreakpointWidth, setBreakpointWidth] = useState(getBreakpointWidth());

  useEffect(() => {
    setBreakpointWidth(getBreakpointWidth());
  }, [sidebarOpen, sm, md]);
  // ------------GetBreakpointWidth_Logic-------------

  return !user || user?.status === UserStatus.Approved ? (
    <Layout
      hasSider
      style={{
        minHeight: '100vh',
        backgroundColor: colors.blue1,
        rowGap: 12,
        columnGap: 12,
        padding: sm ? 12 : 0,
      }}
    >
      <Sidebar />
      <Layout
        className={
          'main-wrap ' +
          (sidebarOpen ? ' sidebar_is_open' : ' sidebar_is_close')
        }
        style={{
          rowGap: sm ? 12 : 0,
          columnGap: sm ? 12 : 0,
          backgroundColor: 'transparent',
          marginLeft: BreakpointWidth,
          transition: 'all 0.31s 0s ease-in-out',
        }}
      >
        <Header />
        <Content
          className="main-content scroll-container"
          style={{
            borderRadius: '12px',
            maxHeight: 'calc(100vh - (80px + (12px * 3)))',
            overflowY: 'scroll',
            ...(sidebarOpen && !md && { filter: 'blur(2px)' }),
          }}
        >
          <Card
            style={{ minHeight: '100%' }}
            bodyStyle={{ padding: lg ? 24 : 16 }}
          >
            <Routes>
              {commonRoutes.map((route) => route)}
              {children}
            </Routes>
          </Card>
        </Content>
      </Layout>
    </Layout>
  ) : (
    <QuestionnaireRoutes />
  );
};

const QuestionnaireRoutes = () => {
  const user = useReactiveVar(LoggedInUser);

  return !!user ? (
    <Layout style={{ minHeight: '100vh', background: colors.black }}>
      {user.status === UserStatus.Verified ? (
        <Routes>
          <Route path={'/'} element={<BuildProfile role={user.role} />} />
          <Route path={'*'} element={<BuildProfile role={user.role} />} />
        </Routes>
      ) : user.status === UserStatus.PendingApproval ? (
        <Routes>
          <Route path={'/'} element={<SubmittedNotice />} />
          <Route path={'*'} element={<SubmittedNotice />} />
        </Routes>
      ) : (
        <Routes>
          <Route
            path={'/'}
            element={<BlockedRejectedNotice status={user.status} />}
          />
          <Route
            path={'*'}
            element={<BlockedRejectedNotice status={user.status} />}
          />
        </Routes>
      )}
    </Layout>
  ) : (
    <></>
  );
};

export default Main;
