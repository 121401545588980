import React from 'react';
import { Text, PrimaryButton } from '../../components';
import { Input, Form, message, Row } from 'antd';
import { AuthLayout } from './index';
import {
  FORGOT_PASSWORD,
  Mutation,
  MutationForgotPasswordArgs,
} from '../../graphql';
import { useMutation } from '@apollo/client';

type FieldType = {
  email: string;
};

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const [forgotPassword, { loading }] = useMutation<
    Mutation,
    MutationForgotPasswordArgs
  >(FORGOT_PASSWORD);

  const onFinish = async ({ email }: FieldType) => {
    forgotPassword({
      variables: {
        email,
      },
    }).then(() => {
      form.resetFields();
      message.success('Email sent!');
    });
  };

  return (
    <AuthLayout>
      <Form
        name="basic"
        form={form}
        style={{ width: '100%', textAlign: 'center' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row justify={'center'}>
          <Text variant={'xlMedium'} center>
            Get password reset link
          </Text>
        </Row>
        <Row style={{ margin: '10px 0' }} justify={'center'}>
          <Text variant={'smNormal'} color={'black5'} center>
            Insert your email to recover your password
          </Text>
        </Row>
        <Form.Item<FieldType>
          name="email"
          rules={[{ required: true, message: '' }]}
        >
          <Input type="email" placeholder="Email address" />
        </Form.Item>
        <PrimaryButton
          block
          htmlType="submit"
          loading={loading}
          style={{ marginTop: 24 }}
        >
          Recover password
        </PrimaryButton>
      </Form>
    </AuthLayout>
  );
}
