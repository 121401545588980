import { Card, Col, Row } from 'antd';
import { ProfileUser } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';
import { Text } from '../../../components';
import {
  advocateQuestions,
  ambassadorQuestions,
  guardianQuestions,
  storytellerQuestions,
} from '../../../utils';
import { useMemo } from 'react';

export default function Interview({
  bordered = false,
}: {
  bordered?: boolean;
}) {
  const user = useReactiveVar(ProfileUser);
  const questions = useMemo(() => {
    switch (user?.role) {
      case 'STORYTELLER':
        return storytellerQuestions;
      case 'GUARDIAN':
        return guardianQuestions;
      case 'ADVOCATE':
        return advocateQuestions;
      case 'AMBASSADOR':
        return ambassadorQuestions;
      default:
        return {};
    }
  }, [user]);

  return (
    <Card bordered={bordered} style={bordered ? {} : { boxShadow: 'none' }}>
      <Row gutter={[0, 36]}>
        {Object.keys(questions).map((question) =>
          // @ts-ignore
          !!user?.profile?.[user.role.toLowerCase()]?.[question] ? (
            <Col span={24} key={question}>
              <Row gutter={[0, 14]}>
                <Col span={24}>
                  <Text variant={'lgStrong'}>
                    {questions[question as keyof typeof questions]}
                  </Text>
                </Col>
                <Col span={24}>
                  <Text
                    variant={'baseItalic'}
                    color={'black8'}
                    style={{ whiteSpace: 'break-spaces' }}
                  >
                    {/*@ts-ignore*/}
                    {user?.profile?.[user.role.toLowerCase()]?.[question] ?? ''}
                  </Text>
                </Col>
              </Row>
            </Col>
          ) : (
            <></>
          ),
        )}
      </Row>
    </Card>
  );
}
