import { useState } from 'react';
import { Dropdown, MenuProps, Row, Spin } from 'antd';
import {
  Event,
  LEAVE_EVENT,
  LoggedInUser,
  Mutation,
  MutationLeaveEventArgs,
} from '../../../graphql';
import { PrimaryButton, Text } from '../../../components';
import { useMutation } from '@apollo/client';
import { CheckCircleOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

export default function EventMemberOptions({
  event,
  refetch,
}: {
  event: Event;
  refetch: () => void;
}) {
  const { sm } = useBreakpoint();
  const user = LoggedInUser();
  const [open, setOpen] = useState<boolean>(false);
  const [leaveEvent, { loading: leaving }] = useMutation<
    Mutation,
    MutationLeaveEventArgs
  >(LEAVE_EVENT);

  const handleLeave = () => {
    leaveEvent({
      variables: { eventId: event.id },
    }).then(() => {
      refetch();
    });
  };

  const items: MenuProps['items'] = [
    {
      key: 'leave',
      label: (
        <Row align={'middle'} style={{ gap: 8 }}>
          {leaving && <Spin size={'small'} />}
          <Text>Don't go to this event</Text>
        </Row>
      ),
      onClick: handleLeave,
    },
  ];

  return (
    <>
      <Dropdown open={open} menu={{ items }} placement={'bottomRight'}>
        <PrimaryButton
          block={event.owner.id === user?.id && !sm}
          icon={<CheckCircleOutlined />}
          onClick={() => setOpen(!open)}
        >
          Go to this event
          <img
            src={require('../../../assets/images/dropdownWhiteIcon.png')}
            style={{ height: 14, marginLeft: 8 }}
          />
        </PrimaryButton>
      </Dropdown>
    </>
  );
}
