import { Col, Row } from 'antd';
import { Text } from './Text';

export default function EmptyUploadContainer() {
  return (
    <Col span={24}>
      <Row justify={'center'}>
        <img
          src={require('../assets/images/uploadIcon.png')}
          style={{ height: 32, width: 32 }}
        />
      </Row>
      <Row justify={'center'} style={{ marginTop: 16 }}>
        <Text center>Click or drag an image to this area to upload</Text>
      </Row>
      <Row justify={'center'} style={{ marginTop: 4 }}>
        <Text variant={'smNormal'} color={'colorTextDescription'} center>
          Allowed extensions: .jpg .jpeg .png. <br />Max file upload size: 2 MB
        </Text>
      </Row>
    </Col>
  );
}
