import { Col, Pagination, Row, Spin, Tabs } from 'antd';
import {
  GroupItem,
  LoginToContinueModal,
  PrimaryButton,
  Text,
} from '../../../components';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import {
  GET_GROUPS,
  GET_USERS_GROUPS,
  Group,
  GroupStatus,
  LoggedInUser,
  Query,
  QueryGetGroupsArgs,
  QueryGetUserGroupsArgs,
} from '../../../graphql';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { GroupFormModal } from '.';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

export default function Groups() {
  const { sm, xxl } = useBreakpoint();
  const user = useReactiveVar(LoggedInUser);
  const [userGroups, setUserGroups] = useState<Group[]>();
  const [userGroupsCount, setUserGroupsCount] = useState<number>(0);
  const [exploreGroupsCount, setExploreGroupsCount] = useState<number>(0);
  const [exploreGroups, setExploreGroups] = useState<Group[]>();
  const [create, setCreate] = useState<boolean>(false);
  const [userPage, setUserPage] = useState<number>(1);
  const [explorePage, setExplorePage] = useState<number>(1);
  const getPageSize = () => {
    if (xxl) return 12;
    return 6;
  };
  const [getUserGroups, { data, loading, refetch }] = useLazyQuery<
    Query,
    QueryGetUserGroupsArgs
  >(GET_USERS_GROUPS, {
    variables: {
      page: userPage,
      take: getPageSize(),
    },
    fetchPolicy: 'network-only',
  });
  const {
    data: exploreData,
    loading: exploreLoading,
    refetch: refetchExplore,
  } = useQuery<Query, QueryGetGroupsArgs>(GET_GROUPS, {
    variables: {
      ...(user?.id && { userId: user.id }),
      page: explorePage,
      take: getPageSize(),
      status: GroupStatus.Published,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (user?.id) {
      getUserGroups();
    }
  }, [user]);

  useEffect(() => {
    if (data?.getUserGroups) {
      setUserGroups(data?.getUserGroups.data);
      setUserGroupsCount(data?.getUserGroups.count);
    }
  }, [data]);

  useEffect(() => {
    if (exploreData?.getGroups) {
      setExploreGroups(exploreData?.getGroups.data);
      setExploreGroupsCount(exploreData?.getGroups.count);
    }
  }, [exploreData]);

  const Spinner = () => (
    <Row justify={'center'} style={{ padding: '6em' }}>
      <Spin />
    </Row>
  );

  const refetchGroups = () => {
    setUserPage(1);
    setExplorePage(1);
    refetch({
      page: 1,
      take: getPageSize(),
    });
    refetchExplore({
      page: 1,
      take: getPageSize(),
    });
  };

  const tabs = [
    {
      key: 'explore',
      label: 'Explore',
      children: (
        <Col span={24} style={{ marginTop: 10 }}>
          {exploreLoading ? (
            <Spinner />
          ) : !!exploreGroups?.length ? (
            <Row gutter={[12, 12]}>
              {exploreGroups.map((group) => (
                <Col key={group.id} xs={24} md={12} lg={8} xxl={6}>
                  <GroupItem group={group} refetch={refetchGroups} />
                </Col>
              ))}
              <Col span={24} style={{ textAlign: 'end', marginTop: 16 }}>
                <Pagination
                  hideOnSinglePage
                  pageSize={getPageSize()}
                  current={explorePage}
                  total={exploreGroupsCount}
                  onChange={(page) => setExplorePage(page)}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      ),
    },
    {
      key: 'your-groups',
      label: `My groups (${userGroupsCount || '0'})`,
      hide: !user?.id,
      children: (
        <Col span={24} style={{ marginTop: 10 }}>
          {loading ? (
            <Spinner />
          ) : !!userGroups?.length ? (
            <Row gutter={[12, 12]}>
              {userGroups.map((group) => (
                <Col key={group.id} xs={24} md={12} lg={8} xxl={6}>
                  <GroupItem group={group} refetch={refetchGroups} />
                </Col>
              ))}
              <Col span={24} style={{ textAlign: 'end', marginTop: 16 }}>
                <Pagination
                  hideOnSinglePage
                  pageSize={getPageSize()}
                  current={userPage}
                  total={userGroupsCount}
                  onChange={(page) => setUserPage(page)}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Col>
      ),
    },
  ];

  return (
    <Col span={24}>
      <Row gutter={[24, 16]} justify={'space-between'}>
        <Col xs={24} sm={14} md={18} style={{ display: 'grid', gap: 10 }}>
          <Text variant={'xlStrong'}>Community Groups</Text>
          <Text color={'black6'}>
            Discover a community united in saving the ocean! Connect with
            members, share ideas, and take action for a healthier marine
            ecosystem. Dive in and make a difference today!
          </Text>
        </Col>
        <Col xs={24} sm={10} md={6}>
          <PrimaryButton
            icon={<PlusOutlined />}
            style={{ float: 'right' }}
            block={!sm}
            onClick={() => setCreate(true)}
          >
            Create new group
          </PrimaryButton>
        </Col>
      </Row>
      <Col span={24} style={{ marginTop: 16 }}>
        <Tabs items={tabs.filter((item) => !item.hide)} />
      </Col>

      {create &&
        (!user?.id ? (
          <LoginToContinueModal handleClose={() => setCreate(false)} />
        ) : (
          <GroupFormModal
            onSuccess={refetchGroups}
            handleClose={() => setCreate(false)}
          />
        ))}
    </Col>
  );
}
