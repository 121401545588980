import React from 'react';
import { PrimaryButton, Text } from '../../components';
import { Col, Row } from 'antd';
import { QuestionsLayout } from '.';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/reducers';
import { UserStatus } from '../../graphql';
import { useNavigate } from 'react-router-dom';

const BlockedRejectedNotice = ({ status }: { status: UserStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <QuestionsLayout center>
      <Row justify={'center'} gutter={[0, 12]}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text fontSize={20} fontWeight={600} style={{ textAlign: 'center' }}>
            Access denied!
          </Text>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text style={{ textAlign: 'center' }} color={'black5'}>
            {`Your account has been ${status.toLowerCase()}.`}
            <br />
            You will be notified in case of any changes.
          </Text>
        </Col>
        <Col span={24}>
          <PrimaryButton
            onClick={() => {
              dispatch(logout());
              navigate('/login');
            }}
            block
          >
            Logout
          </PrimaryButton>
        </Col>
      </Row>
    </QuestionsLayout>
  );
};

export default BlockedRejectedNotice;
