import { Col, Row } from 'antd';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  Event,
  JOIN_EVENT,
  LoggedInUser,
  Mutation,
  MutationJoinEventArgs,
} from '../../../graphql';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import {
  LoginToContinueModal,
  OutlinedButton,
  PrimaryOutlinedButton,
} from '../../../components';
import { CheckCircleOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  EventMemberOptions,
  EventOwnerOptions,
  ShareEventModal,
} from './index';
import { useState } from 'react';

type Props = { event: Event; refetch: () => void };

export default function EventActions({ event, refetch }: Props) {
  const user = useReactiveVar(LoggedInUser);
  const { sm, md, lg } = useBreakpoint();
  const [share, setShare] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [joinEvent, { loading: joining }] = useMutation<
    Mutation,
    MutationJoinEventArgs
  >(JOIN_EVENT);

  const handleJoin = () => {
    if (!user?.id) {
      setShowLogin(true);
    } else {
      joinEvent({
        variables: { eventId: event.id },
      }).then(() => {
        refetch();
      });
    }
  };

  const ShareModal = () => {
    return share ? (
      <ShareEventModal eventId={event.id} handleClose={() => setShare(false)} />
    ) : (
      <></>
    );
  };

  if (event?.owner.id === user?.id) {
    return (
      <Row gutter={[8, 8]} justify={lg ? 'end' : 'start'}>
        <Col {...(!sm && { span: 24 })}>
          <EventMemberOptions event={event} refetch={refetch} />
        </Col>
        <Col {...(!sm && { span: 12 })}>
          <OutlinedButton
            icon={<ShareAltOutlined />}
            block={!sm}
            onClick={() => setShare(true)}
          >
            Share
          </OutlinedButton>
        </Col>
        <Col {...(!sm && { span: 12 })}>
          <EventOwnerOptions event={event} refetch={refetch} />
        </Col>
        <ShareModal />
      </Row>
    );
  } else if (event?.isMember) {
    return (
      <Row gutter={[8, 8]} justify={md ? 'end' : 'start'}>
        <Col>
          <EventMemberOptions event={event} refetch={refetch} />
        </Col>
        <Col>
          <OutlinedButton
            icon={<ShareAltOutlined />}
            onClick={() => setShare(true)}
          >
            Share
          </OutlinedButton>
        </Col>
        <ShareModal />
      </Row>
    );
  } else {
    return (
      <Row gutter={[8, 8]} justify={md ? 'end' : 'start'}>
        <Col>
          <PrimaryOutlinedButton
            icon={<CheckCircleOutlined />}
            loading={joining}
            onClick={handleJoin}
          >
            Go to this event
          </PrimaryOutlinedButton>
        </Col>
        <Col>
          <OutlinedButton
            icon={<ShareAltOutlined />}
            onClick={() => setShare(true)}
          >
            Share
          </OutlinedButton>
        </Col>
        <ShareModal />
        {showLogin && (
          <LoginToContinueModal handleClose={() => setShowLogin(false)} />
        )}
      </Row>
    );
  }
}
