import { Card, Col, Row } from 'antd';
import { ProfileUser } from '../../../graphql';
import { useReactiveVar } from '@apollo/client';
import { Text } from '../../../components';
import { additionalDetails } from './Profile';

export default function About({ bordered = false }: { bordered?: boolean }) {
  const user = useReactiveVar(ProfileUser);

  return !!user ? (
    <Card bordered={bordered} style={bordered ? {} : { boxShadow: 'none' }}>
      <Row style={{ marginTop: 12 }} gutter={[0, 24]}>
        {additionalDetails(user).map((item) =>
          item.value ? (
            <Col xs={24} sm={12} key={item.label}>
              <Row
                gutter={12}
                align={typeof item.value === 'string' ? 'middle' : 'top'}
                style={{ flexWrap: 'nowrap' }}
              >
                <Col>
                  <img
                    src={item.icon}
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                </Col>
                <Col style={{ display: 'grid' }}>
                  <Text
                    color={'black3'}
                    variant={'smMedium'}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {item.label}
                  </Text>
                  {typeof item.value === 'string' ? (
                    <Text
                      variant={'smMedium'}
                      color={!!item.onClick ? 'blue6' : 'black10'}
                      onClick={item.onClick}
                      className={!!item.onClick ? 'clickable' : ''}
                    >
                      {item.value}
                    </Text>
                  ) : (
                    item.value
                  )}
                </Col>
              </Row>
            </Col>
          ) : (
            <></>
          ),
        )}
      </Row>
    </Card>
  ) : (
    <></>
  );
}
