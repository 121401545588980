import React from 'react';
import { PrimaryButton, Text } from '../../components';
import { Col, Row } from 'antd';
import { QuestionsLayout } from './index';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/reducers';
import { useNavigate } from 'react-router-dom';

const SubmittedNotice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <QuestionsLayout center>
      <Row justify={'center'} style={{ gap: 20 }}>
        <Text fontSize={20} fontWeight={600} style={{ textAlign: 'center' }}>
          Thank you for your submission!
        </Text>
        <Text style={{ textAlign: 'center' }}>
          Your response has been recorded and will be reviewed by an admin.
          <br />
          <br />
          You will be notified via email once your account is ready to be used.
        </Text>
        <Col span={24}>
          <PrimaryButton
            onClick={() => {
              dispatch(logout());
              navigate('/login');
            }}
            block
          >
            Logout
          </PrimaryButton>
        </Col>
      </Row>
    </QuestionsLayout>
  );
};

export default SubmittedNotice;
