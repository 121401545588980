import { Col, Modal, Row } from 'antd';
import { OutlinedButton, PrimaryButton, Text } from '..';
import { useNavigate } from 'react-router-dom';

type Props = { handleClose: () => void };

export default function LoginToContinueModal({ handleClose }: Props) {
  const navigate = useNavigate();

  return (
    <Modal
      open
      centered
      closable
      footer={null}
      title={null}
      onCancel={handleClose}
      style={{ textAlign: 'center' }}
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Text variant={'heading3'} color={'black10'}>
            Sign in to continue
          </Text>
        </Col>
        <Col span={24}>
          <Text color={'black5'}>
            You need to be logged in for this feature.
          </Text>
        </Col>
        <Col span={24} style={{ marginTop: 6 }}>
          <Row gutter={16}>
            <Col span={12}>
              <OutlinedButton
                block
                onClick={() => {
                  handleClose();
                  navigate('/login');
                }}
              >
                Sign in
              </OutlinedButton>
            </Col>
            <Col span={12}>
              <PrimaryButton
                block
                onClick={() => {
                  handleClose();
                  navigate('/register');
                }}
              >
                Register
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}
