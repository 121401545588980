import { Col, Form, Input, Row } from 'antd';
import { Text } from '../../../components';
import { ambassadorQuestionFields as fields } from '../../../utils';

type FieldType = {
  school: string;
  oceanDiscovery: string;
  educationalPursuits: string;
  inspirationalFigures: string;
  youthInitiatives: string;
  environmentalChange: string;
  favOceanReads: string;
  futureLegacy: string;
  guidance: string;
};

type FieldData = {
  title: string;
  subtitle: string;
  field: keyof FieldType;
};

type Props = {
  subStep: number;
};

export default function AmbassadorQuestions({ subStep }: Props) {
  return (
    <Col span={24} style={{ marginTop: 36, textAlign: 'left' }}>
      <Col span={subStep === 1 ? 24 : 0} style={{ marginBottom: 20 }}>
        <Form.Item<FieldType>
          name={'school'}
          label={'Which school are you at?'}
          rules={[{ required: true, message: '' }]}
        >
          <Input placeholder="Enter school name" />
        </Form.Item>
      </Col>
      <Row justify={'start'}>
        <Text variant={'lgStrong'}>{fields[subStep - 1].title}</Text>
      </Row>
      <Row justify={'start'} style={{ marginTop: 6 }}>
        <Text variant={'smNormal'}>
          {fields[subStep - 1].subtitle}{' '}
          <Text color={'black4'} variant={'smNormal'}>
            (optional)
          </Text>
        </Text>
      </Row>
      <Col span={24} style={{ marginTop: 16 }}>
        <Form.Item<FieldType> name={fields[subStep - 1].field}>
          <Input.TextArea placeholder="Enter your answer" rows={5} />
        </Form.Item>
      </Col>
    </Col>
  );
}
